import { useEffect, useRef } from "preact/hooks";

export const useEventListener = (
  eventName: string,
  handler: (event?: Event | KeyboardEvent) => void,
  element: HTMLElement | Window = window
) => {
  const savedHandler = useRef<(event: Event) => void>();

  useEffect(() => {
    savedHandler.current = handler;
  }, [handler]);

  useEffect(() => {
    const isSupported = element && element.addEventListener;

    if (!isSupported) return;

    const eventListener: EventListenerOrEventListenerObject = (
      event: Event
    ) => {
      return savedHandler.current!(event);
    };

    element.addEventListener(eventName, eventListener);

    return () => {
      element.removeEventListener(eventName, eventListener);
    };
  }, [eventName, element]);
};
