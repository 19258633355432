import { FunctionalComponent, h } from "preact";
import { useState, useCallback, useMemo } from "preact/hooks";
import style from "./style.css";
import AuthService, { ResetPasswordRequest } from "../../api/auth";
import { ApiError, MessageResponse } from "../../api/provider";
import { ResetPasswordForm, initialResetPasswordForm } from "./types";
import {
  FormErrors,
  hasValidationError,
  isValidPassword,
  ValidationRules,
} from "../../util";
import Errors from "../../components/Errors/Errors";
import { parseErrors } from "../../util/error";
import InputGroup from "../../components/InputGroup/InputGroup";
import Button from "../../components/Button/Button";
import Icon from "../../components/Icon/Icon";
import { route } from "preact-router";
import { USER_TYPE_KEY } from "../../api/auth/service";
import { RoutePath } from "../../models/route";
import Input from "../../components/Input/Input";
import toastr from "../../libs/toastr";

const ResetPassword: FunctionalComponent = () => {
  const [isAuthenticating, setIsAuthenticating] = useState<boolean>(false);
  const [resetPasswordForm, setResetPasswordForm] = useState<ResetPasswordForm>(
    initialResetPasswordForm
  );
  const [isFormValid, setIsFormValid] = useState<boolean>(false);
  const [errors, setErrors] = useState<FormErrors>({});

  const validationRules: ValidationRules = useMemo(() => {
    return {
      email: { required: true },
      token: { required: true },
      newPassword: {
        required: true,
        rules: [
          {
            rule: isValidPassword,
            message:
              "Password must be at least 8 characters, contain at least 1 uppercase letter, integer, and punctuation mark",
          },
        ],
      },
      confirmPassword: {
        required: true,
        rules: [
          {
            rule: (confirmPassword: string) =>
              resetPasswordForm.newPassword === confirmPassword,
            message: "Password does not match new password",
          },
        ],
      },
    };
  }, [resetPasswordForm.newPassword]);

  const handleUpdateResetPasswordForm = useCallback(
    (field: keyof ResetPasswordForm, value: string) => {
      if (errors) setErrors({});

      const nextResetPasswordForm = {
        ...resetPasswordForm,
        [field]: value,
      };

      setResetPasswordForm(nextResetPasswordForm);

      const hasError = hasValidationError(
        nextResetPasswordForm,
        validationRules
      );

      if (hasError) return setIsFormValid(false);
      setIsFormValid(true);
    },
    [errors, resetPasswordForm, validationRules]
  );

  const onSuccess = useCallback((resp: MessageResponse) => {
    toastr().clear();
    toastr().success(resp.data.message);

    const userType = localStorage.getItem(USER_TYPE_KEY);
    const loginRoutePath =
      userType === "guest" ? RoutePath.GuestLogin : RoutePath.OwnerLogin;

    route(loginRoutePath, true);
  }, []);

  const onError = useCallback((err: ApiError) => {
    const errors = parseErrors(err);
    setErrors(errors);
  }, []);

  const onFinally = useCallback(() => {
    setIsAuthenticating(false);
  }, []);

  const handleSubmit = useCallback(
    (e: Event) => {
      e.preventDefault();

      setIsAuthenticating(true);

      const payload: ResetPasswordRequest = {
        token: resetPasswordForm.token,
        email: resetPasswordForm.email,
        password: resetPasswordForm.newPassword,
      };

      AuthService()
        .resetPassword(payload)
        .then(onSuccess)
        .catch(onError)
        .finally(onFinally);
    },
    [resetPasswordForm, onError, onFinally, onSuccess]
  );

  return (
    <div class={style.resetPasswordView}>
      <a href={RoutePath.ResetPasswordRequest} class={style.backWrapper}>
        <Icon icon="left-arrow" />
        Back
      </a>
      <div class={style.resetPasswordFormWrapper}>
        <h3>Reset Password</h3>
        <div className={style.resetPasswordInstructions}>
          Check your email inbox (including spam) to find the Reset code
        </div>
        <form onSubmit={handleSubmit} class={style.resetPasswordForm}>
          <InputGroup>
            <Input
              onInput={(e: InputEvent) =>
                handleUpdateResetPasswordForm(
                  "token",
                  (e.target as HTMLInputElement).value
                )
              }
              type="text"
              label="Reset code"
              value={resetPasswordForm.token}
              validation={validationRules.token}
              errors={errors.token}
            />
            <div class={style.sendAgainWrapper}>
              Reset code did not come?{" "}
              <a href={RoutePath.ResetPasswordRequest}>Send again</a>
            </div>
          </InputGroup>
          <InputGroup>
            <Input
              onInput={(e: InputEvent) =>
                handleUpdateResetPasswordForm(
                  "email",
                  (e.target as HTMLInputElement).value
                )
              }
              type="email"
              label="Email"
              value={resetPasswordForm.email}
              validation={validationRules.email}
              errors={errors.email}
            />
          </InputGroup>
          <InputGroup>
            <Input
              onInput={(e: InputEvent) =>
                handleUpdateResetPasswordForm(
                  "newPassword",
                  (e.target as HTMLInputElement).value
                )
              }
              type="password"
              label="New password"
              value={resetPasswordForm.newPassword}
              validation={validationRules.newPassword}
              errors={errors.password}
            />
          </InputGroup>
          <InputGroup>
            <Input
              onInput={(e: InputEvent) =>
                handleUpdateResetPasswordForm(
                  "confirmPassword",
                  (e.target as HTMLInputElement).value
                )
              }
              type="password"
              label="Confirm password"
              value={resetPasswordForm.confirmPassword}
              validation={validationRules.confirmPassword}
            />
          </InputGroup>
          <div class={style.errorWrapper}>
            <Errors errors={errors.server} />
          </div>
          <div class={style.buttonWrapper}>
            <Button
              widthStyle="100%"
              loading={isAuthenticating}
              htmlType="submit"
              buttonType={isFormValid ? "primary" : "disabled"}
            >
              Reset password
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ResetPassword;
