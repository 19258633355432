/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { FunctionComponent, h, Fragment } from "preact";
import style from "./style.css";
import Modal from "../../../../../../components/Modal/Modal";
import { ExchangeModel } from "../../../../../../models/exchange";
import Button from "../../../../../../components/Button/Button";
import Icon from "../../../../../../components/Icon/Icon";
import Avatar from "../../../../../../components/Avatar/Avatar";
import {
  formatDate,
  generateUuidv4,
  hasValidationError,
  ValidationRules,
} from "../../../../../../util";
import { useMemo, useState } from "preact/hooks";
import { initialOfferForm, OfferForm } from "../../../types";
import DatePicker from "../../../../../../components/DatePicker/DatePicker";
import { DatePickerValue } from "../../../../../../components/DatePicker/types";
import ExchangeService, {
  CreateExchangeOfferRequest,
} from "../../../../../../api/exchange";
import { onApiError } from "../../../../../../util/error";
import toastr from "../../../../../../libs/toastr";

interface CreateProposalModalProps {
  exchange: ExchangeModel;
  display: boolean;
  onClose: () => void;
  onCreateProposal: () => void;
}

const validationRules: ValidationRules = {
  requesterDateRange: { required: true },
  requesteeDateRange: { required: true },
};

const CreateProposalModal: FunctionComponent<CreateProposalModalProps> = ({
  onClose,
  display,
  exchange,
  onCreateProposal,
}) => {
  const [offerForm, setOfferForm] = useState<OfferForm>(initialOfferForm);
  const [isFormValid, setIsFormValid] = useState<boolean>(false);
  const [
    displayRequestDateRangePicker,
    setDisplayRequestDateRangePicker,
  ] = useState<boolean>(false);
  const [
    displayOfferDateRangePicker,
    setDisplayOfferDateRangePicker,
  ] = useState<boolean>(false);
  const [isCreatingOffer, setIsCreatingOffer] = useState<boolean>(false);

  const getInitials = (firstName: string, lastName: string): string => {
    return `${firstName[0]}${lastName[0]}`;
  };

  const isDisplayingDateRangePicker =
    displayOfferDateRangePicker || displayRequestDateRangePicker;

  const getModalOnClose = useMemo(() => {
    return isDisplayingDateRangePicker ? undefined : onClose;
  }, [isDisplayingDateRangePicker, onClose]);

  const updateOfferForm = (key: keyof OfferForm, value: DatePickerValue) => {
    const nextOfferForm = {
      ...offerForm,
      [key]: value,
    };

    setOfferForm(nextOfferForm);

    const hasError = hasValidationError(nextOfferForm, validationRules);

    if (hasError) return setIsFormValid(false);
    setIsFormValid(true);
  };

  const onSuccess = () => {
    toastr().success("Sent proposal successfully");
    onCreateProposal();
    onClose();
  };

  const onFinally = () => {
    setIsCreatingOffer(false);
  };

  const handleSubmitOffer = () => {
    setIsCreatingOffer(true);

    const request: CreateExchangeOfferRequest = {
      exchangeId: exchange.id,
      requesteeStartDate: offerForm.requesteeDateRange?.start!,
      requesteeEndDate: offerForm.requesteeDateRange?.end!,
      requesterStartDate: offerForm.requesterDateRange?.start!,
      requesterEndDate: offerForm.requesterDateRange?.end!,
    };

    ExchangeService()
      .createExchangeOffer(request)
      .then(onSuccess)
      .catch(onApiError)
      .finally(onFinally);
  };

  const secondModal = () => {
    switch (true) {
      case displayRequestDateRangePicker:
        return (
          <Fragment>
            <div class={style.calendarPickerDescription}>
              {`Select specific ${exchange.duration} days within initial date range`}
            </div>
            <div class={style.datePickerWrapper}>
              <DatePicker
                key={generateUuidv4()}
                hasOneTimeMinAndMax
                durationLimit={exchange.duration}
                value={offerForm.requesteeDateRange}
                minDate={exchange.requesteeStartDate}
                maxDate={exchange.requesteeEndDate}
                onChange={(value) =>
                  updateOfferForm("requesteeDateRange", value)
                }
              />
            </div>
            <div
              class={`${style.dateRangeInputWrapper} ${
                offerForm.requesteeDateRange ? style.withValue : ""
              }`}
            >
              {offerForm.requesteeDateRange
                ? `${formatDate(
                    offerForm.requesteeDateRange?.start,
                    "ddd, MMM D"
                  )} - ${formatDate(
                    offerForm.requesteeDateRange?.end,
                    "ddd, MMM D, YYYY"
                  )}`
                : `Select date range...`}
              <Button
                buttonType={offerForm.requesteeDateRange ? "green" : "disabled"}
                iconLeft="check-mark"
                buttonClass={style.confirmDateRangeBtn}
                onClick={() => setDisplayRequestDateRangePicker(false)}
              >
                Confirm
              </Button>
            </div>
          </Fragment>
        );
      case displayOfferDateRangePicker:
        return (
          <Fragment>
            <div class={style.calendarPickerDescription}>
              {`Select specific ${exchange.duration} days within initial date range`}
            </div>
            <div class={style.datePickerWrapper}>
              <DatePicker
                key={generateUuidv4()}
                hasOneTimeMinAndMax
                durationLimit={exchange.duration}
                value={offerForm.requesterDateRange}
                minDate={exchange.requesterStartDate}
                maxDate={exchange.requesterEndDate}
                onChange={(value) =>
                  updateOfferForm("requesterDateRange", value)
                }
              />
            </div>
            <div
              class={`${style.dateRangeInputWrapper} ${
                offerForm.requesterDateRange ? style.withValue : ""
              }`}
            >
              {offerForm.requesterDateRange
                ? `${formatDate(
                    offerForm.requesterDateRange?.start,
                    "ddd, MMM D"
                  )} - ${formatDate(
                    offerForm.requesterDateRange?.end,
                    "ddd, MMM D, YYYY"
                  )}`
                : `Select date range...`}
              <Button
                buttonType={offerForm.requesterDateRange ? "green" : "disabled"}
                iconLeft="check-mark"
                buttonClass={style.confirmDateRangeBtn}
                onClick={() => setDisplayOfferDateRangePicker(false)}
              >
                Confirm
              </Button>
            </div>
          </Fragment>
        );
      default:
        return undefined;
    }
  };

  return (
    <Modal
      title="Your Proposal"
      onClose={getModalOnClose}
      display={display}
      useDefaultModalFooter={false}
      modalWrapperClass={`${style.proposalModalWrapper} ${
        isDisplayingDateRangePicker ? style.withPicker : ""
      }`}
      secondModal={secondModal()}
      secondModalTitle="Propose specific date range"
      onCloseSecondModal={
        displayRequestDateRangePicker
          ? () => setDisplayRequestDateRangePicker(false)
          : () => setDisplayOfferDateRangePicker(false)
      }
    >
      <div class={style.modalContentWrapper}>
        <div
          class={`${style.columnsWrapper} ${
            isDisplayingDateRangePicker ? style.withPicker : ""
          }`}
        >
          {!displayOfferDateRangePicker && (
            <div class={style.column}>
              <div class={style.columnTitle}>Request</div>
              <div class={style.contentWrapper}>
                <div class={style.columnDetailWrapper}>
                  <div class={style.label}>Living sanctuary</div>
                  <div class={style.housePreviewCard}>
                    <img
                      src={exchange?.requesteeHome.imageUrls[0]}
                      class={style.homePreviewImage}
                    />
                    <div class={style.houseDetails}>
                      <div class={style.houseName}>
                        {exchange?.requesteeHome.name}
                      </div>
                      <div class={style.ownerNameWrapper}>
                        <Avatar
                          src={exchange?.requestee.avatarUrl!}
                          fallback={getInitials(
                            exchange?.requestee.firstName!,
                            exchange?.requestee.lastName!
                          )}
                        />
                        <div class={style.ownerName}>
                          {exchange?.requestee.firstName}{" "}
                          {exchange?.requestee.lastName}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class={style.columnDetailWrapper}>
                  <div class={style.label}>Initial date range</div>
                  <div class={style.dateRange}>
                    {formatDate(
                      exchange.requesteeStartDate,
                      "ddd, MMM DD, YYYY"
                    )}{" "}
                    -{" "}
                    {formatDate(exchange.requesteeEndDate, "ddd, MMM DD, YYYY")}
                  </div>
                </div>

                <div class={style.columnDetailWrapper}>
                  <div class={style.label}>Proposed specific date range</div>
                  {offerForm.requesteeDateRange ? (
                    `${formatDate(
                      offerForm.requesteeDateRange.start,
                      "ddd, MMM DD, YYYY"
                    )} - ${formatDate(
                      offerForm.requesteeDateRange.end,
                      "ddd, MMM DD, YYYY"
                    )}`
                  ) : displayRequestDateRangePicker ? (
                    <div class={style.continueOnPickerText}>
                      Continue on calendar picker{" "}
                      <Icon
                        icon="right-arrow"
                        iconWrapperClass={style.continueOnPickerIcon}
                      />
                    </div>
                  ) : (
                    <div class={style.noSelectedRange}>(not suggested yet)</div>
                  )}
                </div>
              </div>
              {!displayRequestDateRangePicker ? (
                offerForm.requesteeDateRange ? (
                  <Button
                    iconLeft="calendar"
                    widthStyle="100%"
                    buttonType="ghost"
                    buttonClass={style.changeSpecificDateRangeBtn}
                    onClick={() => setDisplayRequestDateRangePicker(true)}
                  >
                    Change specific date range
                  </Button>
                ) : (
                  <Button
                    iconLeft="calendar"
                    widthStyle="100%"
                    buttonClass={style.suggestSpecificDateRangeBtn}
                    onClick={() => setDisplayRequestDateRangePicker(true)}
                  >
                    Suggest specific date range
                  </Button>
                )
              ) : null}
            </div>
          )}

          {!displayRequestDateRangePicker && (
            <div class={style.column}>
              <div class={style.columnTitle}>Offer</div>
              <div class={style.contentWrapper}>
                <div class={style.columnDetailWrapper}>
                  <div class={style.label}>Living sanctuary</div>
                  <div class={style.housePreviewCard}>
                    <img
                      src={exchange?.requesterHome.imageUrls[0]}
                      class={style.homePreviewImage}
                    />
                    <div class={style.houseDetails}>
                      <div class={style.houseName}>
                        {exchange?.requesterHome.name}
                      </div>
                      <div class={style.ownerNameWrapper}>
                        <Avatar
                          src={exchange?.requester.avatarUrl!}
                          fallback={getInitials(
                            exchange?.requester.firstName!,
                            exchange?.requester.lastName!
                          )}
                        />
                        <div class={style.ownerName}>
                          {exchange?.requester.firstName}{" "}
                          {exchange?.requester.lastName}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class={style.columnDetailWrapper}>
                  <div class={style.label}>Initial date range</div>
                  <div class={style.dateRange}>
                    {formatDate(
                      exchange.requesterStartDate,
                      "ddd, MMM DD, YYYY"
                    )}{" "}
                    -{" "}
                    {formatDate(exchange.requesterEndDate, "ddd, MMM DD, YYYY")}
                  </div>
                </div>

                <div class={style.columnDetailWrapper}>
                  <div class={style.label}>Proposed specific date range</div>
                  {offerForm.requesterDateRange ? (
                    `${formatDate(
                      offerForm.requesterDateRange.start,
                      "ddd, MMM DD, YYYY"
                    )} - ${formatDate(
                      offerForm.requesterDateRange.end,
                      "ddd, MMM DD, YYYY"
                    )}`
                  ) : displayOfferDateRangePicker ? (
                    <div class={style.continueOnPickerText}>
                      Continue on calendar picker{" "}
                      <Icon
                        icon="right-arrow"
                        iconWrapperClass={style.continueOnPickerIcon}
                      />
                    </div>
                  ) : (
                    <div class={style.noSelectedRange}>(not suggested yet)</div>
                  )}
                </div>
              </div>
              {!displayOfferDateRangePicker ? (
                offerForm.requesterDateRange ? (
                  <Button
                    iconLeft="calendar"
                    widthStyle="100%"
                    buttonType="ghost"
                    buttonClass={style.changeSpecificDateRangeBtn}
                    onClick={() => setDisplayOfferDateRangePicker(true)}
                  >
                    Change specific date range
                  </Button>
                ) : (
                  <Button
                    iconLeft="calendar"
                    widthStyle="100%"
                    buttonClass={style.suggestSpecificDateRangeBtn}
                    onClick={() => setDisplayOfferDateRangePicker(true)}
                  >
                    Suggest specific date range
                  </Button>
                )
              ) : null}
            </div>
          )}
        </div>
        {isFormValid && !isDisplayingDateRangePicker && (
          <div class={style.sendProposalBtnWrapper}>
            <Button
              buttonType="primary"
              iconRight="right-arrow"
              buttonClass={style.sendProposalBtnIcon}
              onClick={handleSubmitOffer}
              loading={isCreatingOffer}
            >
              Send proposal
            </Button>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default CreateProposalModal;
