import { Fragment, FunctionalComponent, h } from "preact";
import { Router, RouteProps, Route, route } from "preact-router";
import AuthService from "../api/auth";
import { RoutePath } from "../models/route";
import { getMe } from "../util";
import { UserModel } from "../models/user";

import Header from "./Header/Header";
import GuestHome from "../routes/guest/Home/Home";
import GuestSettings from "../routes/guest/Settings/Settings";
import OwnerHome from "../routes/owner/Home/Home";
import OwnerSettings from "../routes/owner/Settings/Settings";
import GuestLogin from "../routes/guest/Login/Login";
import OwnerLogin from "../routes/owner/Login/Login";
import Register from "../routes/guest/Register/Register";
import NotFound from "../routes/NotFound/NotFound";
import ResetPasswordRequest from "../routes/ResetPasswordRequest/ResetPasswordRequest";
import ResetPassword from "../routes/ResetPassword/ResetPassword";
import OwnerCreateExchangeRequest from "../routes/owner/CreateExchangeRequest/CreateExchangeRequest";
import OwnerExchanges from "../routes/owner/Exchanges/Exchanges";
import OwnerExchangeDetails from "../routes/owner/ExchangeDetails/ExchangeDetails";
import Socket from "./Socket/Socket";

const Root: FunctionalComponent = () => {
  return (
    <div id="app">
      <Router>
        <Redirect path="/" to={RoutePath.GuestLogin} />

        <NonAuthRoute path={RoutePath.GuestLogin} component={GuestLogin} />
        <NonAuthRoute path={RoutePath.OwnerLogin} component={OwnerLogin} />
        <NonAuthRoute path={RoutePath.GuestRegister} component={Register} />
        <NonAuthRoute
          path={RoutePath.ResetPasswordRequest}
          component={ResetPasswordRequest}
        />
        <NonAuthRoute
          path={RoutePath.ResetPassword}
          component={ResetPassword}
        />

        <GuestAppRoute path={RoutePath.GuestHome} component={GuestHome} />
        <GuestAppRoute
          path={RoutePath.GuestSettings}
          component={GuestSettings}
        />

        <OwnerAppRoute path={RoutePath.OwnerHome} component={OwnerHome} />
        <OwnerAppRoute
          path={RoutePath.OwnerCreateExchangeRequest}
          component={OwnerCreateExchangeRequest}
        />
        <OwnerAppRoute
          path={RoutePath.OwnerExchanges}
          component={OwnerExchanges}
        />

        <OwnerAppRoute
          path={RoutePath.OwnerExchangeDetails}
          component={OwnerExchangeDetails}
        />
        <OwnerAppRoute
          path={RoutePath.OwnerSettings}
          component={OwnerSettings}
        />

        <NotFound default />
      </Router>
    </div>
  );
};

const GuestAppRoute = (props: RouteProps<unknown>) => {
  window.scrollTo(0, 0);
  const authenticated: boolean = AuthService().isAuthenticated();
  if (!authenticated) return <Redirect to={RoutePath.GuestLogin} />;

  const me: UserModel = getMe();
  const isOwner = me.isOwner;

  if (isOwner) return <Redirect to={RoutePath.OwnerHome} />;

  return <Route {...props} />;
};

const OwnerAppRoute = (props: RouteProps<unknown>) => {
  window.scrollTo(0, 0);
  const authenticated: boolean = AuthService().isAuthenticated();
  if (!authenticated) return <Redirect to={RoutePath.OwnerLogin} />;

  const me: UserModel = getMe();
  const isGuest = !me.isOwner;

  if (isGuest) return <Redirect to={RoutePath.GuestHome} />;

  return (
    <Socket>
      <Fragment>
        <Header />
        <div class="app-layout">
          <Route {...props} />
        </div>
      </Fragment>
    </Socket>
  );
};

const NonAuthRoute = (props: RouteProps<unknown>) => {
  window.scrollTo(0, 0);
  const authenticated: boolean = AuthService().isAuthenticated();
  if (authenticated) {
    const me: UserModel = getMe();
    const isOwner = me.isOwner;
    return (
      <Redirect to={isOwner ? RoutePath.OwnerHome : RoutePath.GuestHome} />
    );
  }
  return <Route {...props} />;
};

const Redirect: FunctionalComponent<{ to: string }> = ({ to }) => {
  route(to, true);
  return null;
};

export default Root;
