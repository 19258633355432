import { UserType } from "../../../models/user";

export const initialOwnerLoginForm: OwnerLoginForm = {
  email: "",
  password: "",
  type: UserType.Owner,
};

export interface OwnerLoginForm {
  email: string;
  password: string;
  type: UserType;
}
