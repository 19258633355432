import { Fragment, FunctionalComponent, h } from "preact";
import { route } from "preact-router";
import Button from "../../../../components/Button/Button";
import Avatar from "../../../../components/Avatar/Avatar";
import Loader from "../../../../components/Loader/Loader";
import { useGetExchange } from "../../../../hooks/exchange";
import { RoutePath } from "../../../../models/route";
import { formatDate, getMe } from "../../../../util";
import style from "./style.css";
import {
  ExchangeOfferModel,
  ExchangeStatus,
} from "../../../../models/exchange";
import Tag, { TagType } from "../../../../components/Tag/Tag";
import Icon from "../../../../components/Icon/Icon";
import CreateProposalModal from "./components/CreateProposalModal/CreateProposalModal";
import { useState } from "react";
import Messenger from "../shared/Messenger/Messenger";
import MessageChannel from "../../../../components/Socket/channels/MessageChannel/MessageChannel";
import ExchangeService from "../../../../api/exchange";
import { onApiError } from "../../../../util/error";
import { MessageResponse } from "../../../../api/provider";
import toastr from "../../../../libs/toastr";

const ExchangeDetailsDesktop: FunctionalComponent<any> = ({ id }) => {
  const [fetchingExchange, exchange, { fetchExchange }] = useGetExchange(id);
  const [
    displayCreateProposalModal,
    setDisplayCreateProposalModal,
  ] = useState<boolean>(false);
  const [updatingExchangeOffer, setUpdatingExchangeOffer] = useState<boolean>(
    false
  );
  const meId = getMe().id;

  const isRequester = meId === exchange?.requester.id;

  const getInitials = (firstName: string, lastName: string): string => {
    return `${firstName[0]}${lastName[0]}`;
  };

  const getTagStatus = (status: ExchangeStatus): TagType => {
    switch (status) {
      case "accepted":
        return "green";
      case "declined":
        return "red";
      case "confirmed":
        return "disabled";
      case "pending":
        return "white";
    }
  };

  const onSuccess = (resp: MessageResponse) => {
    fetchExchange(id, false);
    toastr().success(resp.data.message);
  };

  const handleUpdateOffer = (offerId: string, status: ExchangeStatus) => {
    setUpdatingExchangeOffer(true);

    ExchangeService()
      .updateExchangeOffer(offerId, { status })
      .then(onSuccess)
      .catch(onApiError)
      .finally(() => setUpdatingExchangeOffer(false));
  };

  const getOfferActions = (offer: ExchangeOfferModel) => {
    switch (offer.status) {
      case "accepted":
        return (
          <div>
            <Button buttonType="disabled" widthStyle="100%">
              Accepted
            </Button>
          </div>
        );
      case "declined":
        return (
          <div>
            <Button buttonType="disabled" widthStyle="100%">
              Declined
            </Button>
          </div>
        );
      case "confirmed":
        return (
          <div>
            <Button buttonType="disabled" widthStyle="100%">
              Confirmed
            </Button>
          </div>
        );
      case "pending":
        return isRequester ? (
          <div class={style.offerActionsWrapper}>
            <Button
              buttonType="ghost"
              iconLeft="close"
              widthStyle="100%"
              buttonClass={style.declineOfferBtn}
              onClick={() => handleUpdateOffer(offer.id, "declined")}
              loading={updatingExchangeOffer}
            >
              Decline
            </Button>
            <Button
              buttonType="green"
              iconLeft="check-mark"
              widthStyle="100%"
              buttonClass={style.confirmOfferBtn}
              onClick={() => handleUpdateOffer(offer.id, "accepted")}
              loading={updatingExchangeOffer}
            >
              Accept
            </Button>
          </div>
        ) : (
          <div>
            <Button buttonType="disabled" widthStyle="100%">
              Awaiting response
            </Button>
          </div>
        );
    }
  };

  const getContact = () => {
    return isRequester ? exchange?.requestee! : exchange?.requester!;
  };

  const getContactHome = () => {
    return isRequester ? exchange?.requesteeHome! : exchange?.requesterHome!;
  };

  const hasNoOffers = exchange?.offers.length === 0;

  return fetchingExchange || !exchange ? (
    <Loader fullScreen />
  ) : (
    <MessageChannel exchangeId={id}>
      <Fragment>
        <div class={style.viewWrapper}>
          <div class={style.columnLeft}>
            <div class={style.columnHeaderWrapper}>
              <div class={style.backBtnWrapper}>
                <div
                  class={style.backBtn}
                  onClick={() => route(RoutePath.OwnerExchanges, true)}
                >
                  <Icon
                    icon="left-arrow"
                    iconWrapperClass={style.backBtnArrowIcon}
                  />
                  Back
                </div>
              </div>
            </div>
            <div class={style.requestDateWrapper}>
              <div class={`${style.label} ${style.noMargin}`}>Request date</div>
              <div class={style.requestDetailsWrapper}>
                <h4 class={style.requestDate}>
                  {formatDate(exchange.createdAt, "MMM DD, YY")}
                </h4>
                <div class={style.tagWrapper}>
                  <Tag tagType={getTagStatus(exchange.status)}>
                    {exchange.status}
                  </Tag>
                </div>
                <Tag tagType="white">
                  {isRequester ? (
                    <div class={style.tagContentWrapper}>
                      <Icon
                        icon="top-left-arrow"
                        iconWrapperClass={style.tagIcon}
                      />
                      Outgoing
                    </div>
                  ) : (
                    <div class={style.tagContentWrapper}>
                      <Icon
                        icon="bottom-right-arrow"
                        iconWrapperClass={style.tagIcon}
                      />
                      Incoming
                    </div>
                  )}
                </Tag>
              </div>
            </div>
            <div class={style.exchangeDetailsWrapper}>
              <div class={style.detailsSection}>
                <div class={style.detailsSectionTitle}>Request</div>
                <div class={style.sectionContentWrapper}>
                  <div class={style.detailsContentWrapper}>
                    <div class={style.label}>Living sanctuary</div>
                    <div class={style.housePreviewCard}>
                      <img
                        src={exchange.requesteeHome.imageUrls[0]}
                        class={style.homePreviewImage}
                      />
                      <div class={style.houseDetails}>
                        <div>{exchange.requesteeHome.name}</div>
                        <div class={style.ownerNameWrapper}>
                          <Avatar
                            src={exchange.requestee.avatarUrl}
                            fallback={getInitials(
                              exchange.requestee.firstName,
                              exchange.requestee.lastName
                            )}
                          />
                          <div class={style.ownerName}>
                            {exchange.requestee.firstName}{" "}
                            {exchange.requestee.lastName}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class={style.detailsContentWrapper}>
                    <div class={style.label}>Initial date range</div>
                    <div class={style.dateRange}>
                      {formatDate(
                        exchange.requesteeStartDate,
                        "ddd, MMM DD, YYYY"
                      )}{" "}
                      -{" "}
                      {formatDate(
                        exchange.requesteeEndDate,
                        "ddd, MMM DD, YYYY"
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div class={style.detailsSection}>
                <div class={style.detailsSectionTitle}>Offer</div>
                <div class={style.sectionContentWrapper}>
                  <div class={style.detailsContentWrapper}>
                    <div class={style.label}>Living sanctuary</div>
                    <div class={style.housePreviewCard}>
                      <img
                        src={exchange.requesterHome.imageUrls[0]}
                        class={style.homePreviewImage}
                      />
                      <div class={style.houseDetails}>
                        <div>{exchange.requesterHome.name}</div>
                        <div class={style.ownerNameWrapper}>
                          <Avatar
                            src={exchange.requester.avatarUrl}
                            fallback={getInitials(
                              exchange.requester.firstName,
                              exchange.requester.lastName
                            )}
                          />
                          <div class={style.ownerName}>
                            {exchange.requester.firstName}{" "}
                            {exchange.requester.lastName}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class={style.detailsContentWrapper}>
                    <div class={style.label}>Initial date range</div>
                    <div class={style.dateRange}>
                      {formatDate(
                        exchange.requesterStartDate,
                        "ddd, MMM DD, YYYY"
                      )}{" "}
                      -{" "}
                      {formatDate(
                        exchange.requesterEndDate,
                        "ddd, MMM DD, YYYY"
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div class={style.detailsSection}>
                <div class={style.detailsSectionTitle}>Duration</div>
                <div class={style.sectionContentWrapper}>
                  {exchange.duration} days
                </div>
              </div>
            </div>
            {hasNoOffers && isRequester ? null : (
              <div class={style.proposalsWrapper}>
                <div class={style.proposalsTitle}>{`${
                  isRequester ? "Proposals" : "Your Proposals"
                }`}</div>
                {exchange.offers.map((offer) => {
                  return (
                    <div class={style.offerWrapper}>
                      <div class={style.offerDate}>
                        Created{" "}
                        {formatDate(offer.createdAt, "MMMM DD, YYYY hh:mm A")}
                      </div>
                      <div class={style.proposalDateRangeWrapper}>
                        <div class={style.proposalDateRangeLabel}>
                          Requested date range
                        </div>
                        <div class={style.proposalDateRange}>{`${formatDate(
                          offer.requesteeStartDate,
                          "ddd, MMM DD"
                        )} - ${formatDate(
                          offer.requesteeEndDate,
                          "ddd, MMM DD, YYYY"
                        )}`}</div>
                      </div>
                      <div class={style.proposalDateRangeWrapper}>
                        <div class={style.proposalDateRangeLabel}>
                          Offered date range
                        </div>
                        <div class={style.proposalDateRange}>{`${formatDate(
                          offer.requesterStartDate,
                          "ddd, MMM DD"
                        )} - ${formatDate(
                          offer.requesterEndDate,
                          "ddd, MMM DD, YYYY"
                        )}`}</div>
                      </div>
                      {getOfferActions(offer)}
                    </div>
                  );
                })}
                {!isRequester && exchange.status === "pending" && (
                  <div class={style.proposalActionsWrapper}>
                    <Button
                      buttonType="ghost"
                      iconLeft="close"
                      buttonClass={style.rejectBtn}
                      widthStyle="100%"
                    >
                      Reject
                    </Button>
                    <Button
                      buttonType="primary"
                      iconRight="right-arrow"
                      buttonClass={style.sendProposalBtnIcon}
                      widthStyle="100%"
                      onClick={() => setDisplayCreateProposalModal(true)}
                    >
                      Send proposal
                    </Button>
                  </div>
                )}
              </div>
            )}
          </div>

          <div class={style.columnRight}>
            <div class={style.chatWindowWrapper}>
              <div class={style.chatWindowHeader}>
                <Avatar
                  size="lg"
                  src={getContact().avatarUrl}
                  fallback={getInitials(
                    getContact().firstName,
                    getContact().lastName
                  )}
                />
                <div class={style.receiverDetailsWrapper}>
                  <div class={style.contactName}>{`${getContact().firstName} ${
                    getContact().lastName
                  }`}</div>
                  <div class={style.contactHouseName}>
                    {getContactHome().name}
                  </div>
                </div>
              </div>
              <div
                class={style.chatWindowBody}
                style={{ height: `${window.innerHeight - 250}px` }}
              >
                <Messenger exchangeId={id} />
              </div>
            </div>
          </div>
        </div>

        <CreateProposalModal
          display={displayCreateProposalModal}
          onClose={() => setDisplayCreateProposalModal(false)}
          exchange={exchange}
          onCreateProposal={() => fetchExchange(id, false)}
        />
      </Fragment>
    </MessageChannel>
  );
};

export default ExchangeDetailsDesktop;
