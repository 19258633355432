import { FunctionalComponent, h } from "preact";
import style from "./style.css";
import Icon, { IconType } from "../Icon/Icon";
import { useEffect, useRef } from "preact/hooks";
import Button from "../Button/Button";

export interface ModalProps {
  title: string;
  children: JSX.Element;
  onClose?: Function;
  display: boolean;
  okBtnText?: string;
  okBtnIcon?: IconType;
  cancelBtnText?: string;
  onCancel?: Function;
  onOk?: Function;
  loading?: boolean;
  useDefaultModalFooter?: boolean;
  modalWrapperClass?: string;
  secondModal?: JSX.Element;
  secondModalTitle?: string;
  onCloseSecondModal?: Function;
}

const Modal: FunctionalComponent<ModalProps> = ({
  title,
  children,
  onClose,
  display,
  okBtnText,
  cancelBtnText,
  okBtnIcon,
  onCancel,
  onOk,
  loading,
  modalWrapperClass,
  useDefaultModalFooter = true,
  secondModal,
  secondModalTitle,
  onCloseSecondModal,
}: ModalProps) => {
  const modalOverlayEl = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!modalOverlayEl.current || !onClose) return;

    modalOverlayEl.current.addEventListener("click", (e) => {
      if (e.target === modalOverlayEl.current) onClose();
    });
  }, [display, onClose]);

  return display ? (
    <div class={style.modalOverlay} ref={modalOverlayEl}>
      <div class={`${style.modalWrapper} ${modalWrapperClass}`}>
        <div class={`${style.modal} ${style.enterModalEffect}`}>
          <div class={style.modalHeader}>
            <div style={style.modalTitle}>{title}</div>
            {onClose && (
              <Icon
                icon="close"
                iconWrapperClass={style.closeIconWrapper}
                onClick={() => onClose()}
              />
            )}
          </div>
          <div class={style.modalBody}>{children}</div>
          {useDefaultModalFooter && (
            <div class={style.modalFooter}>
              <Button buttonType="ghost" onClick={onCancel}>
                {cancelBtnText}
              </Button>
              <div style={{ width: "16px" }} />
              <Button
                buttonType="primary"
                iconRight={okBtnIcon}
                onClick={onOk}
                loading={loading}
              >
                {okBtnText}
              </Button>
            </div>
          )}
        </div>
        {secondModal && (
          <div class={`${style.modal} ${style.slideModalEffect}`}>
            <div class={style.modalHeader}>
              <div style={style.modalTitle}>{secondModalTitle}</div>
              {onCloseSecondModal && (
                <Icon
                  icon="close"
                  iconWrapperClass={style.closeIconWrapper}
                  onClick={() => onCloseSecondModal()}
                />
              )}
            </div>
            <div class={style.modalBody}>{secondModal}</div>
          </div>
        )}
      </div>
    </div>
  ) : null;
};

export default Modal;
