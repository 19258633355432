import { FunctionalComponent, h } from "preact";
import { Link } from "preact-router/match";
import { useState } from "preact/hooks";
import { useEventListener } from "../../hooks/event-listener";
import { RoutePath } from "../../models/route";
import Icon from "../Icon/Icon";
import UserProfileMenu from "./components/UserProfileMenu/UserProfileMenu";
import style from "./style.css";

const Header: FunctionalComponent = () => {
  const [isScrolled, setIsScrolled] = useState<boolean>(false);

  const handleScroll = () => {
    if (window.scrollY > 0) return setIsScrolled(true);
    setIsScrolled(false);
  };

  useEventListener("scroll", handleScroll);

  return (
    <header class={`${style.header} ${isScrolled ? style.scrolled : ""}`}>
      <div class={style.sectionLeft}>
        <Icon icon="logo" />
      </div>

      <nav class={style.sectionMiddle}>
        <Link activeClassName={style.active} href={RoutePath.OwnerHome}>
          Home
        </Link>
        <Link activeClassName={style.active} href={RoutePath.OwnerExchanges}>
          Exchanges
        </Link>
        <Link activeClassName={style.active} href="/owner/bookings">
          Bookings
        </Link>
        <Link activeClassName={style.active} href={RoutePath.OwnerSettings}>
          Settings
        </Link>
      </nav>

      <div class={style.sectionRight}>
        <UserProfileMenu />
      </div>
    </header>
  );
};

export default Header;
