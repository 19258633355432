import { FunctionalComponent, h } from "preact";
import { isMobileView } from "../../../util/viewport";
import ExchangesDesktop from "./desktop/ExchangesDesktop";
import ExchangesMobile from "./mobile/ExchangesMobile";

const Exchanges: FunctionalComponent = () => {
  return isMobileView() ? <ExchangesMobile /> : <ExchangesDesktop />;
};

export default Exchanges;
