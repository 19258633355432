import { FunctionalComponent, h } from "preact";
import { useState, useCallback } from "preact/hooks";
import style from "./style.css";
import GuestService, { CreateGuestRequest } from "../../../api/guest";
import { route } from "preact-router";
import { ApiError } from "../../../api/provider";
import { GuestRegisterForm, initialGuestRegisterForm } from "./types";
import { RoutePath } from "../../../models/route";
import {
  FormErrors,
  hasValidationError,
  isValidEmail,
  isValidPassword,
  isFullName,
  ValidationRules,
} from "../../../util";
import Errors from "../../../components/Errors/Errors";
import { parseErrors } from "../../../util/error";
import Input from "../../../components/Input/Input";
import InputGroup from "../../../components/InputGroup/InputGroup";
import Button from "../../../components/Button/Button";
import Icon from "../../../components/Icon/Icon";
import toastr from "../../../libs/toastr";

const validationRules: ValidationRules = {
  email: {
    required: true,
    rules: [
      {
        rule: isValidEmail,
        message: "Please enter a valid email address",
      },
    ],
  },
  fullName: {
    required: true,
    rules: [
      {
        rule: isFullName,
        message: "Please enter both your first and last name",
      },
    ],
  },
  password: {
    required: true,
    rules: [
      {
        rule: isValidPassword,
        message:
          "Password must be at least 8 characters, contain at least 1 uppercase letter, integer, and punctuation mark",
      },
    ],
  },
};

const GuestRegister: FunctionalComponent = () => {
  const [isRegistering, setIsRegistering] = useState<boolean>(false);
  const [guestRegisterForm, setGuestRegisterForm] = useState<GuestRegisterForm>(
    initialGuestRegisterForm
  );
  const [isFormValid, setIsFormValid] = useState<boolean>(false);
  const [errors, setErrors] = useState<FormErrors>({});

  const handleUpdateGuestRegisterForm = useCallback(
    (field: keyof GuestRegisterForm, value: string) => {
      if (errors) setErrors({});

      const nextGuestRegisterForm = {
        ...guestRegisterForm,
        [field]: value,
      };

      setGuestRegisterForm(nextGuestRegisterForm);

      const hasError = hasValidationError(
        nextGuestRegisterForm,
        validationRules
      );

      if (hasError) return setIsFormValid(false);
      setIsFormValid(true);
    },
    [guestRegisterForm, errors]
  );

  const onSuccess = useCallback(() => {
    toastr().success("Registered successfully");

    route(RoutePath.GuestLogin, true);
  }, []);

  const onError = useCallback((err: ApiError) => {
    const errors = parseErrors(err);
    setErrors(errors);
  }, []);

  const onFinally = useCallback(() => {
    setIsRegistering(false);
  }, []);

  const handleSubmit = useCallback(
    (e: Event) => {
      e.preventDefault();

      setIsRegistering(true);

      const { fullName } = guestRegisterForm;
      const firstName = fullName.split(" ")[0];
      const lastName = fullName.split(" ")[1];

      const payload: CreateGuestRequest = {
        ...guestRegisterForm,
        firstName,
        lastName,
      };

      GuestService()
        .create(payload)
        .then(onSuccess)
        .catch(onError)
        .finally(onFinally);
    },
    [guestRegisterForm, onError, onFinally, onSuccess]
  );

  return (
    <div class={style.registerView}>
      <div
        class={style.registerNatureImage}
        style={{
          backgroundImage: `url(${"../../../assets/images/register-nature-img.png"})`,
        }}
      />
      <div class={style.logoWrapper}>
        <Icon icon="logo-white" />
      </div>
      <div class={style.registerSection}>
        <div class={style.registerFormWrapper}>
          <h1>
            Create
            <br />
            Account
          </h1>
          <div class={style.signInWrapper}>
            Already have an account? <a href={RoutePath.GuestLogin}>Sign in</a>
          </div>
          <form onSubmit={handleSubmit} class={style.registerForm}>
            <InputGroup>
              <Input
                onInput={(e: InputEvent) =>
                  handleUpdateGuestRegisterForm(
                    "fullName",
                    (e.target as HTMLInputElement).value
                  )
                }
                type="text"
                label="Full name"
                value={guestRegisterForm.fullName}
                validation={validationRules.fullName}
                errors={errors.firstName || errors.lastName}
              />
            </InputGroup>
            <InputGroup>
              <Input
                onInput={(e: InputEvent) =>
                  handleUpdateGuestRegisterForm(
                    "email",
                    (e.target as HTMLInputElement).value
                  )
                }
                type="email"
                label="Email"
                value={guestRegisterForm.email}
                validation={validationRules.email}
                errors={errors.email}
              />
            </InputGroup>
            <InputGroup>
              <Input
                onInput={(e: InputEvent) =>
                  handleUpdateGuestRegisterForm(
                    "password",
                    (e.target as HTMLInputElement).value
                  )
                }
                type="password"
                label="Password"
                value={guestRegisterForm.password}
                validation={validationRules.password}
                errors={errors.password}
              />
            </InputGroup>
            <div class={style.errorWrapper}>
              <Errors errors={errors.server} />
            </div>
            <div class={style.buttonWrapper}>
              <Button
                widthStyle="100%"
                loading={isRegistering}
                htmlType="submit"
                buttonType={isFormValid ? "primary" : "disabled"}
              >
                Register
              </Button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default GuestRegister;
