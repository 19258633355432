/* eslint-disable @typescript-eslint/no-explicit-any */
import { capitalize } from ".";

export type ValidationRules = {
  [field: string]: ValidationRule;
};

export interface ValidationRule {
  required: boolean;
  message?: string;
  rules?: CustomValidationRule[];
}

export type CustomValidationRule = {
  rule: (value: any) => boolean;
  message?: string;
};

export type FormErrors = { [field: string]: string[] };

export function hasValidationError(
  form: { [field: string]: any },
  rules: ValidationRules
): boolean {
  const rulesArr = Object.keys(rules);
  let hasError = false;

  for (let i = 0; i < rulesArr.length; i++) {
    const key = rulesArr[i];
    const rule = rules[key];
    const value = form[key];

    if (rule.required && !value) hasError = true;
    if (!hasError && rule.rules)
      hasError = rule.rules.some((r) => !r.rule(value));
  }

  return hasError;
}

export function validate(
  rule: ValidationRule,
  label: string,
  value: any
): string[] {
  let errors: string[] = [];

  if (rule.required && !value) {
    const requiredErrorMsg = rule.message || `${capitalize(label)} is required`;
    errors = [...errors, requiredErrorMsg];
  }

  if (rule.rules) {
    rule.rules.forEach((r) => {
      if (r.rule(value)) return;
      const requiredErrorMsg = r.message || `${capitalize(label)} is not valid`;

      errors = [...errors, requiredErrorMsg];
    });
  }

  return errors;
}

export function isValidEmail(email: string): boolean {
  const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regex.test(email.toLowerCase());
}

export function isValidPassword(password: string): boolean {
  const regex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/;
  return regex.test(password);
}

export function isFullName(name: string): boolean {
  const firstName = name.split(" ")[0];
  const lastName = name.split(" ")[1];
  return !!firstName && !!lastName;
}
