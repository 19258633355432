import {
  GuestResponse,
  CreateGuestRequest,
  UpdateGuestRequest,
} from "./reqres";
import { BASE_API_URL } from "../../config";
import { post, put } from "../provider";

export default function GuestService() {
  return Object.freeze({
    create(payload: CreateGuestRequest): Promise<GuestResponse> {
      return post(`${BASE_API_URL()}/guests`, payload);
    },
    update(payload: UpdateGuestRequest): Promise<GuestResponse> {
      return put(`${BASE_API_URL()}/guests`, payload);
    },
  });
}
