import { FunctionalComponent, h } from "preact";
import { Link } from "preact-router/match";
import Button from "../../components/Button/Button";
import style from "./style.css";

const NotFound: FunctionalComponent = () => {
  return (
    <div class={style.notfound}>
      <h1>Error 404</h1>
      <p>This page doesn&apos;t exist.</p>
      <Link href="/">
        <Button buttonType="green">Back to Home</Button>
      </Link>
    </div>
  );
};

export default NotFound;
