import { Fragment, FunctionComponent, h } from "preact";
import { useContext, useEffect, useRef, useState } from "preact/hooks";
import { ExchangeMessageResponse } from "../../../../../api/exchange";
import Icon from "../../../../../components/Icon/Icon";
import Loader from "../../../../../components/Loader/Loader";
import { MessageChannelContext } from "../../../../../components/Socket/channels/MessageChannel/MessageChannel";
import { useEventListener } from "../../../../../hooks/event-listener";
import { useListExchangeMessages } from "../../../../../hooks/exchange/list-exchange-messages";
import { ExchangeMessageModel } from "../../../../../models/exchange";
import { formatDate, getMe } from "../../../../../util";
import { onApiError } from "../../../../../util/error";
import style from "./style.css";

interface MessengerProps {
  exchangeId: string;
}

const Messenger: FunctionComponent<MessengerProps> = ({ exchangeId }) => {
  const [
    fetchingExchangeMessages,
    exchangeMessages,
    { addMessage },
  ] = useListExchangeMessages(exchangeId);
  const [message, setMessage] = useState<string>("");
  const { sendMessage, onNewMessage } = useContext(MessageChannelContext);
  const messageInputEl = useRef<HTMLInputElement>(null);
  const messagesWrapperEl = useRef<HTMLDivElement>(null);
  const meId = getMe().id;

  useEffect(() => {
    const teardown = onNewMessage((data: ExchangeMessageResponse) => {
      addMessage(data.data);
    });

    return teardown;
  }, [addMessage, onNewMessage]);

  const onSuccess = (resp: ExchangeMessageResponse) => {
    addMessage(resp.data);
    setMessage("");
  };

  const handleSendMessage = () => {
    if (!message) return;

    sendMessage(message).then(onSuccess).catch(onApiError);
  };

  useEventListener(
    "keyup",
    (e) => {
      (e as KeyboardEvent).preventDefault();

      if ((e as KeyboardEvent).key !== "Enter" || !message) return;

      handleSendMessage();
    },
    messageInputEl.current
  );

  const isLastMessage = (index: number) => {
    return exchangeMessages.length - 1 === index;
  };

  const isEndOfMessageChain = (
    message: ExchangeMessageModel,
    index: number
  ) => {
    if (isLastMessage(index)) return true;

    const nextMessage = exchangeMessages[index + 1];
    if (nextMessage.sender.id === message.sender.id) return false;
    return true;
  };

  return (
    <div class={style.messengerWrapper}>
      <div class={style.messagesWrapper}>
        <div class={style.messages} ref={messagesWrapperEl}>
          {fetchingExchangeMessages ? (
            <Loader />
          ) : (
            exchangeMessages.map((msg, index) => {
              const isSender = msg.sender.id === meId;
              return (
                <Fragment>
                  <div class={style.messageWrapper}>
                    <div
                      class={`${style.message} ${isSender ? style.sender : ""}`}
                    >
                      {msg.content}
                    </div>
                  </div>
                  {isEndOfMessageChain(msg, index) && (
                    <div
                      class={`${style.timeStamp} ${
                        isSender ? style.sender : ""
                      }`}
                    >
                      {`${formatDate(
                        msg.insertedAt,
                        "MMM D, YYYY"
                      )} · ${formatDate(msg.insertedAt, "h:mm A")}`}
                    </div>
                  )}
                </Fragment>
              );
            })
          )}
        </div>
      </div>
      <div class={style.sendMessageInputWrapper}>
        <input
          ref={messageInputEl}
          class={style.messageInput}
          value={message}
          onInput={(e) => setMessage((e.target as HTMLInputElement).value)}
          placeholder="Write something..."
        />
        <Icon
          icon="send"
          iconWrapperClass={style.sendMessageIcon}
          onClick={handleSendMessage}
        />
      </div>
    </div>
  );
};

export default Messenger;
