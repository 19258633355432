import { FunctionalComponent, h } from "preact";
import { useState, useCallback } from "preact/hooks";
import style from "./style.css";
import AuthService, { ResetPasswordRequestRequest } from "../../api/auth";
import { ApiError } from "../../api/provider";
import {
  ResetPasswordRequestForm,
  initialResetPasswordRequestForm,
} from "./types";
import { FormErrors, hasValidationError, ValidationRules } from "../../util";
import Errors from "../../components/Errors/Errors";
import { parseErrors } from "../../util/error";
import Input from "../../components/Input/Input";
import InputGroup from "../../components/InputGroup/InputGroup";
import Button from "../../components/Button/Button";
import Icon from "../../components/Icon/Icon";
import { route } from "preact-router";
import { RoutePath } from "../../models/route";
import { USER_TYPE_KEY } from "../../api/auth/service";
import toastr, { NotificationDuration } from "../../libs/toastr";

const validationRules: ValidationRules = {
  email: { required: true },
};

const ResetPasswordRequest: FunctionalComponent = () => {
  const [isRequesting, setIsRequesting] = useState<boolean>(false);
  const [
    resetPasswordRequestForm,
    setResetPasswordRequestForm,
  ] = useState<ResetPasswordRequestForm>(initialResetPasswordRequestForm);
  const [isFormValid, setIsFormValid] = useState<boolean>(false);
  const [errors, setErrors] = useState<FormErrors>({});
  const userType = localStorage.getItem(USER_TYPE_KEY);

  const handleUpdateResetPasswordRequestForm = useCallback(
    (value: string) => {
      if (errors) setErrors({});
      setResetPasswordRequestForm({
        email: value,
      });

      const hasError = hasValidationError(
        resetPasswordRequestForm,
        validationRules
      );

      if (hasError) return setIsFormValid(false);
      setIsFormValid(true);
    },
    [errors, resetPasswordRequestForm]
  );

  const onSuccess = useCallback(() => {
    toastr().info(
      `Reset code has been sent to ${resetPasswordRequestForm.email}`,
      NotificationDuration.NEVER
    );
    route(RoutePath.ResetPassword, true);
  }, [resetPasswordRequestForm.email]);

  const onError = useCallback((err: ApiError) => {
    const errors = parseErrors(err);
    setErrors(errors);
  }, []);

  const onFinally = useCallback(() => {
    setIsRequesting(false);
  }, []);

  const handleSubmit = useCallback(
    (e: Event) => {
      e.preventDefault();

      setIsRequesting(true);

      const payload: ResetPasswordRequestRequest = {
        ...resetPasswordRequestForm,
      };

      AuthService()
        .resetPasswordRequest(payload)
        .then(onSuccess)
        .catch(onError)
        .finally(onFinally);
    },
    [resetPasswordRequestForm, onError, onFinally, onSuccess]
  );

  return (
    <div class={style.resetPasswordRequestView}>
      <a
        href={
          userType === "guest" ? RoutePath.GuestLogin : RoutePath.OwnerLogin
        }
        class={style.backWrapper}
      >
        <Icon icon="left-arrow" />
        Back
      </a>
      <div class={style.resetPasswordRequestFormWrapper}>
        <h3>Forgot Password</h3>
        <div className={style.resetPasswordRequestInstructions}>
          Enter the email address you used when you joined and we’ll send you a
          code to reset your password.
        </div>
        <form onSubmit={handleSubmit} class={style.resetPasswordRequestForm}>
          <InputGroup>
            <Input
              onInput={(e: InputEvent) =>
                handleUpdateResetPasswordRequestForm(
                  (e.target as HTMLInputElement).value
                )
              }
              type="email"
              label="Email"
              value={resetPasswordRequestForm.email}
              validation={validationRules.email}
              errors={errors.email}
            />
          </InputGroup>
          <div class={style.errorWrapper}>
            <Errors errors={errors.server} />
          </div>
          <div class={style.buttonWrapper}>
            <Button
              widthStyle="100%"
              loading={isRequesting}
              htmlType="submit"
              buttonType={isFormValid ? "primary" : "disabled"}
            >
              Sign In
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ResetPasswordRequest;
