import { AUTH_TOKEN_KEY, ME_KEY } from "../api/auth/service";
import { UserModel } from "../models/user";

export function getAuthToken(): string | null {
  return typeof window !== "undefined"
    ? localStorage.getItem(AUTH_TOKEN_KEY)
    : null;
}

export function getMe(): UserModel {
  return typeof window !== "undefined"
    ? JSON.parse(localStorage.getItem(ME_KEY)!)
    : null;
}
