import { FunctionalComponent, h } from "preact";
import Icon, { IconType } from "../Icon/Icon";
import style from "./style.css";

export type ButtonType =
  | "primary"
  | "ghost"
  | "danger"
  | "disabled"
  | "green"
  | "clear";

type ButtonHtmlType = "submit" | "reset" | "button";

export interface ButtonProps {
  children?: string;
  buttonType?: ButtonType;
  onClick?: Function;
  htmlType?: ButtonHtmlType;
  loading?: boolean;
  iconLeft?: IconType;
  iconRight?: IconType;
  widthStyle?: string;
  buttonClass?: string;
}

const buttonCssClass = (buttonType: ButtonType) => {
  switch (buttonType) {
    case "primary":
      return style.primaryButton;
    case "ghost":
      return style.ghostButton;
    case "green":
      return style.greenButton;
    case "danger":
      return style.dangerButton;
    case "disabled":
      return style.disabledButton;
    case "clear":
      return style.clearButton;
  }
};

const Button: FunctionalComponent<ButtonProps> = ({
  children,
  buttonType = "primary",
  onClick,
  htmlType = "button",
  loading = false,
  iconLeft,
  widthStyle,
  buttonClass,
  iconRight,
}: ButtonProps) => {
  if (loading) {
    return (
      <button
        type={htmlType}
        onClick={(e) => (onClick ? onClick(e) : null)}
        class={`${style.button} ${buttonCssClass(buttonType)} ${buttonClass}`}
        disabled={buttonType == "disabled"}
        style={widthStyle ? { width: `${widthStyle}` } : {}}
      >
        <svg
          class="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
        >
          <circle
            class="opacity-25"
            cx="12"
            cy="12"
            r="10"
            stroke="currentColor"
            stroke-width="4"
          />
          <path
            class="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
          />
        </svg>
      </button>
    );
  }

  return (
    <button
      type={htmlType}
      onClick={(e) => (onClick ? onClick(e) : null)}
      class={`${style.button} ${buttonCssClass(buttonType)} ${buttonClass}`}
      disabled={buttonType == "disabled"}
      style={widthStyle ? { width: `${widthStyle}` } : {}}
    >
      {!!iconLeft && (
        <Icon icon={iconLeft} iconWrapperClass={style.buttonLeftIcon} />
      )}
      <div class={style.buttonText}>{children}</div>
      {!!iconRight && (
        <Icon icon={iconRight} iconWrapperClass={style.buttonRightIcon} />
      )}
    </button>
  );
};

export default Button;
