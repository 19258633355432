export const initialResetPasswordForm: ResetPasswordForm = {
  email: "",
  token: "",
  newPassword: "",
  confirmPassword: "",
};

export interface ResetPasswordForm {
  email: string;
  token: string;
  newPassword: string;
  confirmPassword: string;
}
