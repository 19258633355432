import { FunctionalComponent, h } from "preact";
import { useEffect, useMemo, useRef, useState } from "preact/hooks";
import style from "./style.css";
import Icon from "../Icon/Icon";
import { useEventListener } from "../../hooks/event-listener";

interface DrawerProps {
  placement: "top" | "bottom" | "left" | "right";
  display: boolean;
  children: JSX.Element | JSX.Element[];
  heightStyle?: string;
  widthStyle?: string;
  onClose?: (e?: MouseEvent) => void;
  title: string | JSX.Element;
  description?: string;
  noHeaderBorder?: boolean;
}

const Drawer: FunctionalComponent<DrawerProps> = ({
  placement,
  display,
  children,
  heightStyle = "256px",
  widthStyle = "256px",
  title,
  description,
  onClose,
  noHeaderBorder,
}: DrawerProps) => {
  const [hasScrollOffset, setHasScrollOffset] = useState<boolean>(false);

  const drawerMaskEl = useRef<HTMLDivElement>(null);
  const drawerBodyEl = useRef<HTMLDivElement>(null);

  const handleOnClose = () => {
    if (!onClose) return;
    onClose();
  };

  useEventListener("click", () => handleOnClose(), drawerMaskEl.current);
  useEventListener(
    "scroll",
    () => {
      if (!noHeaderBorder) return;

      if (drawerBodyEl.current.scrollTop !== 0) {
        setHasScrollOffset(true);
        return;
      }
      setHasScrollOffset(false);
    },
    drawerBodyEl.current
  );

  useEffect(() => {
    if (display) {
      document.body.classList.add(style.scrollLock);
      return;
    }
    document.body.classList.remove(style.scrollLock);
  }, [display]);

  const drawerContentWrapperStyle: h.JSX.CSSProperties = useMemo(() => {
    switch (placement) {
      case "top":
        return display
          ? { height: heightStyle }
          : { height: heightStyle, transform: "translate(100%)" };
      case "bottom":
        return display
          ? { height: heightStyle }
          : { height: heightStyle, transform: "translate(100%)" };
      case "left":
        return { width: widthStyle };
      case "right":
        return { width: widthStyle };
    }
  }, [display, heightStyle, placement, widthStyle]);

  const placementClass = useMemo(() => {
    switch (placement) {
      case "top":
        return style.top;
      case "bottom":
        return style.bottom;
      case "left":
        return style.left;
      case "right":
        return style.right;
    }
  }, [placement]);

  return (
    <div
      class={`
        ${style.drawer}
        ${placementClass}
        ${display ? style.drawerOpen : ""}
      `}
    >
      <div class={style.drawerMask} ref={drawerMaskEl} />
      <div class={style.drawerContentWrapper} style={drawerContentWrapperStyle}>
        <div class={style.drawerContent}>
          <div class={style.drawerWrapperBody}>
            <div
              class={`
              ${style.drawerHeader}
              ${noHeaderBorder ? style.noHeaderBorder : ""}
              ${hasScrollOffset ? style.scroll : ""}
            `}
            >
              <div class={style.drawerTitleWrapper}>
                <div class={style.drawerTitle}>{title}</div>
                {onClose && <Icon icon="close" onClick={onClose} />}
              </div>
              {description ? (
                <div class={style.description}>{description}</div>
              ) : null}
            </div>
            <div class={style.drawerBody} ref={drawerBodyEl}>
              {children}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Drawer;
