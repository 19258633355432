import { FunctionalComponent, h } from "preact";
import AuthService from "../../../api/auth";

const GuestHome: FunctionalComponent = () => {
  return (
    <div>
      <div>Guest App</div>
      <button
        type="button"
        class="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        onClick={() => AuthService().logout()}
      >
        Logout
      </button>
    </div>
  );
};

export default GuestHome;
