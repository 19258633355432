/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { FunctionalComponent, h, Fragment } from "preact";
import style from "./style.css";
import { useEffect, useMemo, useState } from "preact/hooks";
import DatePicker from "../../../../components/DatePicker/DatePicker";
import { DatePickerValue } from "../../../../components/DatePicker/types";
import Message from "../../../../components/Message/Message";
import InputGroup from "../../../../components/InputGroup/InputGroup";
import Input from "../../../../components/Input/Input";
import dayjs from "dayjs";
import {
  durationPeriodOptions,
  ExchangeRequestForm,
  initialExchangeRequestForm,
  validationRules,
} from "../types";
import { useListAvailableHomes } from "../../../../hooks/home";
import Loader from "../../../../components/Loader/Loader";
import { HomeModel } from "../../../../models/home";
import Icon from "../../../../components/Icon/Icon";
import Button, { ButtonType } from "../../../../components/Button/Button";
import Card from "../../../../components/Card/Card";
import Select from "../../../../components/Select/Select";
import {
  formatDate,
  generateUuidv4,
  getMe,
  hasValidationError,
} from "../../../../util";
import ConfirmRequestModal from "./components/ConfirmRequestModal/ConfirmRequestModal";
import Avatar from "../../../../components/Avatar/Avatar";
import HomeCard from "./components/HomeCard/HomeCard";
import { route } from "preact-router";
import { RoutePath } from "../../../../models/route";

const CreateExchangeRequestDesktop: FunctionalComponent = () => {
  const me = getMe();
  const ownerHomes = me.homes.map((home) => ({ ...home, owner: me }));

  const [
    { fetchingAvailableHomes, availableHomes },
    { fetchAvailableHomes },
  ] = useListAvailableHomes();
  const [
    exchangeRequestForm,
    setExchangeRequestForm,
  ] = useState<ExchangeRequestForm>(initialExchangeRequestForm);
  const [focusedInput, setFocusedInput] = useState<keyof ExchangeRequestForm>(
    "requesterHome"
  );
  const [isFormValid, setIsFormValid] = useState<boolean>(false);
  const [
    displayConfirmRequestModal,
    setDisplayConfirmRequestModal,
  ] = useState<boolean>(false);
  const hasCompletedForm = useMemo(() => {
    const {
      duration,
      requesteeDateRange,
      requesterDateRange,
      requesteeHomes,
    } = exchangeRequestForm;
    return (
      !!duration &&
      !!requesteeDateRange &&
      !!requesterDateRange &&
      !!requesteeHomes
    );
  }, [exchangeRequestForm]);

  const getDurationInDays = useMemo(() => {
    const period = {
      day: 1,
      week: 7,
      month: 30,
    }[exchangeRequestForm.durationPeriod!];

    const totalDays = exchangeRequestForm.duration! * period;

    return totalDays;
  }, [exchangeRequestForm.duration, exchangeRequestForm.durationPeriod]);

  useEffect(() => {
    if (!hasCompletedForm) return;

    fetchAvailableHomes({
      start_date: formatDate(exchangeRequestForm.requesteeDateRange?.start),
      end_date: formatDate(exchangeRequestForm.requesteeDateRange?.end),
      duration: `${getDurationInDays}`,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasCompletedForm]);

  const validateForm = (form: ExchangeRequestForm) => {
    const hasError = hasValidationError(form, validationRules);
    if (hasError) return setIsFormValid(false);
    setIsFormValid(true);
  };

  const handleUpdateExchangeRequestForm = (
    field: keyof ExchangeRequestForm,
    value: DatePickerValue | number | boolean | string
  ) => {
    const nextExchangeRequestForm = getNextExchangeRequestForm(field, value);
    setExchangeRequestForm(nextExchangeRequestForm);

    validateForm(nextExchangeRequestForm);

    goToNextStep(field);
  };

  const getNextExchangeRequestForm = (
    field: keyof ExchangeRequestForm,
    value: DatePickerValue | number | boolean | string
  ) => {
    if (field === "requesterHome") {
      return {
        ...exchangeRequestForm,
        requesterHome: value as string,
        requesterDateRange: null,
        requesteeDateRange: null,
        duration: 1,
        requesteeHomes: [],
      };
    }
    if (field === "requesterDateRange") {
      return {
        ...exchangeRequestForm,
        requesterDateRange: value as DatePickerValue,
        requesteeDateRange: null,
        duration: 1,
        requesteeHomes: [],
      };
    }
    if (field === "requesteeDateRange") {
      return {
        ...exchangeRequestForm,
        requesteeDateRange: value as DatePickerValue,
        duration: 1,
        requesteeHomes: [],
      };
    }
    return {
      ...exchangeRequestForm,
      [field]: value,
    };
  };

  const goToNextStep = (currentStep: keyof ExchangeRequestForm) => {
    if (currentStep === "requesterHome") {
      return setFocusedInput("requesterDateRange");
    }
    if (currentStep === "requesterDateRange") {
      return setFocusedInput("requesteeDateRange");
    }
    if (currentStep === "requesteeDateRange") {
      return setFocusedInput("duration");
    }
  };

  const handleUpdateHomeSelected = (home: HomeModel, isSelected: boolean) => {
    const { requesteeHomes } = exchangeRequestForm;
    const nextRequesteeHomesValue = isSelected
      ? [...requesteeHomes, home]
      : requesteeHomes.filter(({ id }) => id !== home.id);

    const nextExchangeRequestForm = {
      ...exchangeRequestForm,
      requesteeHomes: nextRequesteeHomesValue,
    };

    setExchangeRequestForm(nextExchangeRequestForm);

    validateForm(nextExchangeRequestForm);
  };

  const formatDateRange = (
    value?: DatePickerValue | null,
    format = "MMM D, YYYY"
  ): string => {
    if (!value) return "";

    const hasOnlySelectedStart = !!value && !!value.start && !value.end;

    if (hasOnlySelectedStart) {
      return `${dayjs(value.start).format(format)} -`;
    }

    return `${dayjs(value.start).format(format)} - ${dayjs(value.end).format(
      format
    )}`;
  };

  const isHomeSelected = (homeId: string) => {
    return exchangeRequestForm.requesteeHomes.some(
      (home) => home.id === homeId
    );
  };

  const getInitials = (firstName: string, lastName: string) => {
    return `${firstName[0]}${lastName[0]}`;
  };

  const selectedOfferedHome = () => {
    return ownerHomes.find(
      (home) => home.id === exchangeRequestForm.requesterHome
    );
  };

  const getPanelInput = () => {
    switch (focusedInput) {
      case "requesterHome":
        return (
          <div class={style.availableHomesWrapper}>
            {ownerHomes.map((home: HomeModel, index) => {
              return (
                <div
                  key={home.id}
                  style={{
                    marginBottom:
                      index + 1 === ownerHomes.length ? "40px" : "0",
                  }}
                >
                  <HomeCard
                    home={home}
                    isSelected={home.id === exchangeRequestForm.requesterHome}
                    onChange={() =>
                      handleUpdateExchangeRequestForm("requesterHome", home.id)
                    }
                  />
                </div>
              );
            })}
          </div>
        );
      case "requesterDateRange":
        return (
          <DatePicker
            key={generateUuidv4()}
            value={exchangeRequestForm.requesterDateRange}
            minDate={dayjs(selectedOfferedHome()?.startDate!).toDate()}
            maxDate={dayjs(selectedOfferedHome()?.endDate!).toDate()}
            onChange={(value) =>
              handleUpdateExchangeRequestForm("requesterDateRange", value)
            }
          />
        );
      case "requesteeDateRange":
        return (
          <DatePicker
            key={generateUuidv4()}
            value={exchangeRequestForm.requesteeDateRange}
            minDate={new Date()}
            onChange={(value) =>
              handleUpdateExchangeRequestForm("requesteeDateRange", value)
            }
          />
        );
      case "duration":
        return (
          <div class={style.durationInputWrapper}>
            <input
              type="number"
              onInput={(e) =>
                handleUpdateExchangeRequestForm(
                  "duration",
                  (e.target as HTMLInputElement).value
                )
              }
              value={exchangeRequestForm.duration!}
              class={style.durationInput}
            />
            <Select
              options={durationPeriodOptions}
              label="Select period"
              value={exchangeRequestForm.durationPeriod}
              pluralizeOptionNames={exchangeRequestForm.duration! > 1}
              onChange={(value) =>
                handleUpdateExchangeRequestForm("durationPeriod", value)
              }
            />
            <Button
              widthStyle="100%"
              buttonType={
                `${
                  !!exchangeRequestForm.duration &&
                  !!exchangeRequestForm.durationPeriod
                    ? "green"
                    : "disabled"
                }` as ButtonType
              }
              iconLeft="check-mark"
              buttonClass={style.confirmDateRangeButton}
              onClick={() => setFocusedInput("requesteeHomes")}
            >
              Confirm
            </Button>
          </div>
        );
      case "requesteeHomes":
        return fetchingAvailableHomes ? (
          <Loader />
        ) : (
          <div class={style.availableHomesWrapper}>
            {!availableHomes.length ? (
              <div class={style.noAvailableHomesWrapper}>
                <div>No living sanctuaries available in requested range</div>
                <Button
                  buttonType="green"
                  onClick={() => setFocusedInput("requesteeDateRange")}
                >
                  Edit range
                </Button>
              </div>
            ) : (
              availableHomes.map((home: HomeModel, index) => {
                return (
                  <div
                    key={home.id}
                    style={{
                      marginBottom:
                        index + 1 === availableHomes.length ? "40px" : "0",
                    }}
                  >
                    <HomeCard
                      home={home}
                      isSelected={isHomeSelected(home.id)}
                      onChange={(value) =>
                        handleUpdateHomeSelected(home, value)
                      }
                    />
                  </div>
                );
              })
            )}
          </div>
        );
      default:
        return null;
    }
  };

  const getDurationValue = (): string => {
    const { duration, durationPeriod } = exchangeRequestForm;
    if (!duration || !durationPeriod) return "";
    return `${duration} ${
      duration > 1 ? `${durationPeriod}s` : durationPeriod
    }`;
  };

  const getOfferedHome = (): HomeModel | null => {
    return ownerHomes.find(
      (h) => h.id === exchangeRequestForm.requesterHome
    ) as HomeModel | null;
  };

  const getPanelHeader = () => {
    switch (focusedInput) {
      case "requesterHome":
        return (
          <Fragment>
            <div class={style.headerTitle}>Offered living sanctuary</div>
            <div class={style.headerDescription}>
              Select your offered living sanctuary
            </div>
          </Fragment>
        );
      case "requesteeDateRange":
        return (
          <Fragment>
            <div class={style.headerTitle}>Requested date range</div>
            <div class={style.headerDescription}>
              Select a date range within you’d like to book your stay.
            </div>
          </Fragment>
        );
      case "requesterDateRange":
        return (
          <Fragment>
            <div class={style.headerTitle}>Offered date range</div>
            <div class={style.headerDescription}>
              Select a date range which you want to offer an exchange
            </div>
          </Fragment>
        );
      case "duration":
        return (
          <Fragment>
            <div class={style.headerTitle}>Requested exchange duration</div>
            <div class={style.headerDescription}>
              Propose an exchange duration within a requested date range. If the
              requestee accepts the duration, you shall suggest a specific date
              of your ownership with the same duration.
            </div>
          </Fragment>
        );
      case "requesteeHomes":
        return (
          <Fragment>
            <div class={style.headerTitle}>Available living sanctuaries</div>
            <div class={style.headerDescription}>
              Select a living sanctuary to rent.
            </div>
          </Fragment>
        );
    }
  };

  return (
    <div class={style.viewWrapper}>
      <div class={style.viewSectionLeft}>
        <div
          class={style.backBtn}
          onClick={() => route(RoutePath.OwnerExchanges, true)}
        >
          <Icon icon="left-arrow" iconWrapperClass={style.backBtnArrowIcon} />
          Back
        </div>
        <h2>New Exchange</h2>
        <Message
          messageKey="exchange-instructions-msg"
          message="Start by selecting your offered living sanctuary and offered date range. Then, select your requested date range and duration to filter available living sanctuaries."
          type="info"
        />
        <div class={style.formWrapper}>
          <InputGroup multiple>
            <Input
              type="text"
              readOnly
              label="Select offered home"
              value={getOfferedHome()?.name!}
              onFocus={() => setFocusedInput("requesterHome")}
            />
            <Input
              type="text"
              readOnly
              label="Select offered date range"
              value={formatDateRange(exchangeRequestForm.requesterDateRange)}
              onFocus={() => setFocusedInput("requesterDateRange")}
              disabled={!exchangeRequestForm.requesterHome}
            />
          </InputGroup>
          <InputGroup multiple>
            <Input
              type="text"
              readOnly
              label="Select requested date range"
              value={formatDateRange(exchangeRequestForm.requesteeDateRange)}
              onFocus={() => setFocusedInput("requesteeDateRange")}
            />
            <Input
              type="text"
              readOnly
              label="Duration"
              value={getDurationValue()}
              onFocus={() => setFocusedInput("duration")}
              disabled={!exchangeRequestForm.requesteeDateRange}
            />
          </InputGroup>
          {!!exchangeRequestForm.requesteeHomes.length && (
            <InputGroup>
              <Card>
                <div class={style.selectedHomesWrapper}>
                  <div class={style.selectedHomesTitle}>Summary</div>
                  {exchangeRequestForm.requesteeHomes.map((home) => {
                    return (
                      <div key={home.id} class={style.selectedHome}>
                        <div class={style.selectedHomeSectionLeft}>
                          <img
                            class={style.selectedHomeImage}
                            src={home.imageUrls[0]}
                          />
                          <div class={style.selectedHomeDetails}>
                            {home.name}
                            <Avatar
                              src={home.owner.avatarUrl}
                              fallback={getInitials(
                                home.owner.firstName,
                                home.owner.lastName
                              )}
                            />
                          </div>
                        </div>
                        <div class={style.selectedHomeSectionRight}>
                          <Icon
                            icon="close"
                            iconWrapperClass={style.selectedHomeCloseIcon}
                            onClick={() =>
                              handleUpdateHomeSelected(home, false)
                            }
                          />
                        </div>
                      </div>
                    );
                  })}
                  <Button
                    widthStyle="100%"
                    onClick={() => setDisplayConfirmRequestModal(true)}
                    buttonType={isFormValid ? "primary" : "disabled"}
                    iconRight="right-arrow"
                    buttonClass={`${style.toRequestSummaryButton} ${
                      isFormValid ? "" : style.disabled
                    }`}
                  >
                    Request summary
                  </Button>
                  {!isFormValid && (
                    <div class={style.instructionGuideWrapper}>
                      <Icon icon="info" />
                      <div class={style.instructionMessage}>
                        Select requested & offered date ranges and duration to
                        filter living sanctuaries availability & owners and
                        continue.
                      </div>
                    </div>
                  )}
                </div>
              </Card>
            </InputGroup>
          )}
        </div>
      </div>

      <div class={style.viewSectionRight}>
        {(focusedInput || isFormValid) && (
          <div class={style.panelWrapper}>
            <div class={style.panelHeaderWrapper}>{getPanelHeader()}</div>
            <div class={style.panelInputWrapper}>{getPanelInput()}</div>
          </div>
        )}
      </div>

      <ConfirmRequestModal
        offeredHome={getOfferedHome()}
        display={displayConfirmRequestModal}
        form={exchangeRequestForm}
        onClose={() => setDisplayConfirmRequestModal(false)}
        onUpdateMessage={(value) =>
          handleUpdateExchangeRequestForm("message", value)
        }
      />
    </div>
  );
};

export default CreateExchangeRequestDesktop;
