import dayjs from "dayjs";
import { DatePickerValue } from "../components/DatePicker/types";

export function capitalize(str: string): string {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function camelCaseToSentenceCase(str: string): string {
  const string = str.replace(/([A-Z])/g, " $1");
  return capitalize(string.toLowerCase());
}

export function formatDateRange(
  value?: DatePickerValue | null,
  format = "MMM D, YYYY"
): string {
  if (!value) return "";

  const hasOnlySelectedStart = !!value && !!value.start && !value.end;

  if (hasOnlySelectedStart) {
    return `${dayjs(value.start).format(format)} -`;
  }

  return `${dayjs(value.start).format(format)} - ${dayjs(value.end).format(
    format
  )}`;
}

export function formatDate(value?: Date, format = "YYYY-MM-DD") {
  return dayjs(value).format(format);
}
