import { UserType } from "../../../models/user";

export const initialGuestLoginForm: GuestLoginForm = {
  email: "",
  password: "",
  type: UserType.Guest,
};

export interface GuestLoginForm {
  email: string;
  password: string;
  type: UserType;
}
