/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { FunctionalComponent, h } from "preact";
import style from "./style.css";
import { useState } from "preact/hooks";
import TextArea from "../../../../../../components/TextArea/TextArea";
import { ExchangeRequestForm } from "../../../types";
import Modal from "../../../../../../components/Modal/Modal";
import Avatar from "../../../../../../components/Avatar/Avatar";
import ExchangeService, {
  CreateExchangeRequest,
} from "../../../../../../api/exchange";
import { onApiError } from "../../../../../../util/error";
import { formatDate, formatDateRange } from "../../../../../../util";
import { HomeModel } from "../../../../../../models/home";
import toastr from "../../../../../../libs/toastr";
import { MessageResponse } from "../../../../../../api/provider";
import { route } from "preact-router";
import { RoutePath } from "../../../../../../models/route";

interface ConfirmRequestModalProps {
  form: ExchangeRequestForm;
  display: boolean;
  onClose: Function;
  onUpdateMessage: (value: string) => void;
  offeredHome: HomeModel | null;
}

const ConfirmRequestModal: FunctionalComponent<ConfirmRequestModalProps> = ({
  offeredHome,
  form,
  display,
  onClose,
  onUpdateMessage,
}: ConfirmRequestModalProps) => {
  const [creatingRequest, setCreatingRequest] = useState<boolean>(false);

  const getDurationValue = (): string => {
    const { duration, durationPeriod } = form;
    if (!duration || !durationPeriod) return "";
    return `${duration} ${
      duration > 1 ? `${durationPeriod}s` : durationPeriod
    }`;
  };

  const getDurationInDays = () => {
    const { duration, durationPeriod } = form;

    const period = {
      day: 1,
      week: 7,
      month: 30,
    }[durationPeriod!];

    const totalDays = duration! * period;

    return totalDays;
  };

  const onSuccess = (resp: MessageResponse) => {
    route(RoutePath.OwnerExchanges, true);
    toastr().success(resp.data.message);
    onClose();
  };

  const getRequestees = () => {
    const { requesteeHomes } = form;
    return requesteeHomes.map((home) => home.groupId);
  };

  const createExchangeRequest = () => {
    const { requesterDateRange, requesteeDateRange } = form;
    setCreatingRequest(true);

    const payload: CreateExchangeRequest = {
      requesterHomeId: form.requesterHome!,
      requesterStartDate: formatDate(requesterDateRange?.start!),
      requesterEndDate: formatDate(requesterDateRange?.end!),
      requesteeStartDate: formatDate(requesteeDateRange?.start!),
      requesteeEndDate: formatDate(requesteeDateRange?.end!),
      duration: getDurationInDays(),
      homeGroupIds: getRequestees(),
    };

    ExchangeService()
      .create(payload)
      .then(onSuccess)
      .catch(onApiError)
      .finally(() => setCreatingRequest(false));
  };

  const getInitials = (firstName: string, lastName: string) => {
    return `${firstName[0]}${lastName[0]}`;
  };

  return (
    <Modal
      loading={creatingRequest}
      title="Create a request"
      display={display}
      onClose={onClose}
      onOk={createExchangeRequest}
      onCancel={onClose}
      okBtnText="Send request"
      okBtnIcon="send"
      cancelBtnText="Back to edit"
    >
      <div class={style.confirmRequestModalWrapper}>
        <div class={style.modalSectionLeft}>
          <div class={style.modalSectionTitle}>
            Selected living{" "}
            {`${form.requesteeHomes.length > 1 ? "sanctuaries" : "sanctuary"}`}
          </div>
          {form.requesteeHomes.map((home) => {
            return (
              <div key={home.id} class={style.selectedHome}>
                <img class={style.selectedHomeImage} src={home.imageUrls[0]} />
                <div class={style.selectedHomeDetails}>
                  <div class={style.homeName}>{home.name}</div>
                  <div class={style.ownerNameWrapper}>
                    <Avatar
                      src={home.owner.avatarUrl}
                      fallback={getInitials(
                        home?.owner?.firstName!,
                        home?.owner?.lastName!
                      )}
                    />
                    <div class={style.ownerName}>
                      {home?.owner.firstName} {home?.owner.lastName}
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        <div class={style.modalSectionRight}>
          <div class={style.modalSectionTitle}>Date ranges & duration</div>
          <div class={style.confirmRequestDetailsWrapper}>
            <div class={style.requestDetailWrapper}>
              <div class={style.requestDetailLabel}>Requested date range</div>
              <div class={style.requestDetail}>
                {formatDateRange(form.requesteeDateRange, "ddd, MMM DD, YYYY")}
              </div>
            </div>
            <div class={style.requestDetailWrapper}>
              <div class={style.requestDetailLabel}>Duration</div>
              <div class={style.requestDetail}>{getDurationValue()}</div>
            </div>
            <div class={style.requestDetailWrapper}>
              <div class={style.requestDetailLabel}>Offered date range</div>
              <div class={style.requestDetail}>
                {formatDateRange(form.requesterDateRange, "ddd, MMM DD, YYYY")}
              </div>
            </div>
            <div class={style.requestDetailWrapper}>
              <div class={style.requestDetailLabel}>
                Offered living sanctuary
              </div>
              <div class={style.requestDetail}>{offeredHome?.name}</div>
            </div>
          </div>
          <TextArea
            label="Message"
            value={form.message}
            onInput={(e: InputEvent) =>
              onUpdateMessage((e.target as HTMLInputElement).value)
            }
          />
        </div>
      </div>
    </Modal>
  );
};

export default ConfirmRequestModal;
