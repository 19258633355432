/* eslint-disable @typescript-eslint/no-use-before-define */

import {
  AuthenticateResponse,
  AuthenticateRequest,
  MeResponse,
  ResetPasswordRequestRequest,
  ResetPasswordRequest,
} from "./reqres";
import { BASE_API_URL } from "../../config";
import { MessageResponse, get, post } from "../provider";
import jwtDecode, { JwtPayload } from "jwt-decode";
import { getAuthToken } from "../../util";
import { route } from "preact-router";
import { RoutePath } from "../../models/route";
import { UserModel } from "../../models/user";

export const AUTH_TOKEN_KEY = "TOKEN";
export const ME_KEY = "ME";
export const USER_TYPE_KEY = "USER_TYPE_KEY";

const onAuthSuccess = (resp: AuthenticateResponse) => {
  localStorage.setItem(AUTH_TOKEN_KEY, resp.data.token);
  return AuthService().getMe();
};

const onMeSuccess = (resp: MeResponse): Promise<MeResponse> => {
  AuthService().setMe(resp.data);
  return Promise.resolve(resp);
};

export default function AuthService() {
  return Object.freeze({
    authenticate(payload: AuthenticateRequest): Promise<MeResponse> {
      return post(`${BASE_API_URL()}/authenticate`, payload)
        .then(onAuthSuccess)
        .then(onMeSuccess);
    },
    getMe(): Promise<MeResponse> {
      return get(`${BASE_API_URL()}/me`);
    },
    setMe(me: UserModel) {
      localStorage.setItem(ME_KEY, JSON.stringify(me));
    },
    isAuthenticated(): boolean {
      const token = getAuthToken();
      if (!token) return false;

      const decoded = jwtDecode<JwtPayload>(token);
      if (!decoded) return false;
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      if (Date.now() >= decoded.exp! * 1000) return false;

      const me = localStorage.getItem(ME_KEY);
      if (!me) return false;

      return true;
    },
    resetPasswordRequest(
      payload: ResetPasswordRequestRequest
    ): Promise<MessageResponse> {
      return post(`${BASE_API_URL()}//reset_password_request`, payload);
    },
    resetPassword(payload: ResetPasswordRequest): Promise<MessageResponse> {
      return post(`${BASE_API_URL()}//reset_password`, payload);
    },
    logout() {
      const me = localStorage.getItem(ME_KEY);

      localStorage.clear();

      if (!me) return route(RoutePath.GuestLogin);

      const user: UserModel = JSON.parse(me);

      if (user.isOwner) {
        return route(RoutePath.OwnerLogin);
      }

      route(RoutePath.GuestLogin);
    },
  });
}
