import { HomeModel } from "../home";

export enum UserType {
  Guest = "guest",
  Owner = "owner",
}

export interface UserModel {
  email: string;
  firstName: string;
  id: string;
  isOwner: boolean;
  lastName: string;
  avatarUrl: string;
  homes: HomeModel[];
}
