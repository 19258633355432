import { useEffect, useState, useCallback } from "preact/hooks";
import HomeService, { HomesResponse } from "../../api/home";
import { convertFiltersToQueryStr } from "../../util/converter";
import { HomeModel } from "../../models/home";
import toastr from "../../libs/toastr";
import { ApiError, DEFAULT_ERR_MSG } from "../../api/provider";

interface ListHomesState {
  fetchingHomes: boolean;
  homes: HomeModel[];
}
export interface ListHomesFilters {
  me?: boolean;
}

export const useListHomes = (filters?: ListHomesFilters): ListHomesState => {
  const [homes, setHomes] = useState<HomeModel[]>([]);
  const [fetchingHomes, setFetchingHomes] = useState<boolean>(true);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    fetchHomes(filters);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSuccess = useCallback((resp: HomesResponse): void => {
    setHomes(resp.data);
  }, []);

  const onError = useCallback((err: ApiError): void => {
    toastr().danger(err?.message || DEFAULT_ERR_MSG);
  }, []);

  const onFinally = useCallback((): void => {
    setFetchingHomes(false);
  }, []);

  const fetchHomes = useCallback(
    (filters?: ListHomesFilters): void => {
      if (!fetchingHomes) setFetchingHomes(true);

      const queryString = convertFiltersToQueryStr(filters);

      HomeService()
        .listHomes(queryString)
        .then(onSuccess)
        .catch(onError)
        .finally(onFinally);
    },
    [fetchingHomes, onError, onFinally, onSuccess]
  );

  return { fetchingHomes, homes };
};
