const LOCAL_HOSTNAME = "localhost";

const LOCAL_URL = "http://localhost:4000/v1";

const STAGING_URL = "https://staging-api.liveinoneness.com/v1";

const STAGING_HOST_HOSTNAME = "staging";

export const BASE_API_URL = () => {
  const { hostname } = window.location;

  if (hostname.includes(LOCAL_HOSTNAME)) {
    return LOCAL_URL;
  }

  if (hostname.includes(STAGING_HOST_HOSTNAME)) {
    return STAGING_URL;
  }
};

export const BASE_WEB_SOCKET_API_URL = () => {
  const { hostname } = window.location;

  if (hostname.includes(LOCAL_HOSTNAME)) {
    return `ws://localhost:4000`;
  }

  if (hostname.includes(STAGING_HOST_HOSTNAME)) {
    return `wss://staging-api.liveinoneness.com`;
  }
};
