import { FunctionComponent, h } from "preact";
import style from "./style.css";
import { HomeModel } from "../../models/home";
import Avatar from "../Avatar/Avatar";

interface HomePreviewCardProps {
  home: HomeModel;
  size?: "sm" | "md" | "lg";
}

const HomePreviewCard: FunctionComponent<HomePreviewCardProps> = ({ home }) => {
  const getInitials = (firstName: string, lastName: string): string => {
    return `${firstName[0]}${lastName[0]}`;
  };

  return (
    <div class={style.cardWrapper}>
      <img src={home.imageUrls[0]} class={style.homeImg} />
      <div class={style.homeDetailsWrapper}>
        <div class={style.homeName}>{home.name}</div>
        <div class={style.homeOwnerWrapper}>
          <Avatar
            src={home.owner.avatarUrl}
            fallback={getInitials(home.owner.firstName, home.owner.lastName)}
          />
          <div class={style.homeOwnerName}>
            {`${home.owner.firstName} ${home.owner.lastName}`}
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomePreviewCard;
