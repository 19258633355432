import { useEffect, useState, useCallback } from "preact/hooks";
import ExchangeService, { ExchangeMessagesResponse } from "../../api/exchange";
import toastr from "../../libs/toastr";
import { ApiError, DEFAULT_ERR_MSG } from "../../api/provider";
import { ExchangeMessageModel } from "../../models/exchange";
import dayjs from "dayjs";

interface ListExchangeMessagesActions {
  addMessage: (msg: ExchangeMessageModel) => void;
}
interface ListExchangeMessagesState {
  fetchingExchangeMessages: boolean;
  exchangeMessages: ExchangeMessageModel[];
}

export const useListExchangeMessages = (
  id: string
): [boolean, ExchangeMessageModel[], ListExchangeMessagesActions] => {
  const [exchangeMessages, setExchangeMessages] = useState<
    ExchangeMessageModel[]
  >([]);
  const [fetchingExchangeMessages, setFetchingExchange] = useState<boolean>(
    true
  );

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    fetchExchangeMessages(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSuccess = useCallback((resp: ExchangeMessagesResponse): void => {
    const sortedMessages = [...resp.data].sort((a, b) => {
      if (dayjs(a.insertedAt).isBefore(dayjs(b.insertedAt))) return -1;
      if (dayjs(a.insertedAt).isAfter(dayjs(b.insertedAt))) return 1;
      return 0;
    });

    setExchangeMessages(sortedMessages);
  }, []);

  const onError = useCallback((err: ApiError): void => {
    toastr().danger(err?.message || DEFAULT_ERR_MSG);
  }, []);

  const onFinally = useCallback((): void => {
    setFetchingExchange(false);
  }, []);

  const fetchExchangeMessages = useCallback(
    (id: string): void => {
      if (!fetchingExchangeMessages) setFetchingExchange(true);

      ExchangeService()
        .listMessages(id)
        .then(onSuccess)
        .catch(onError)
        .finally(onFinally);
    },
    [fetchingExchangeMessages, onError, onFinally, onSuccess]
  );

  const addMessage = (msg: ExchangeMessageModel) => {
    const nextExchangeMessages = [...exchangeMessages, msg];

    setExchangeMessages(nextExchangeMessages);
  };

  return [fetchingExchangeMessages, exchangeMessages, { addMessage }];
};
