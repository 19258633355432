import { useEffect, useState, useCallback } from "preact/hooks";
import ExchangeService, { ExchangesResponse } from "../../api/exchange";
import { ListExchangeModel, ExchangeStatus } from "../../models/exchange";
import toastr from "../../libs/toastr";
import { ApiError, DEFAULT_ERR_MSG } from "../../api/provider";
import dayjs from "dayjs";
import { SortOrderType } from "../../components/DataTable/types";

interface ListExchangesActions {
  sortByDate: (order: SortOrderType) => void;
  filterByStatus: (status: ExchangeStatus[]) => void;
}
interface ListExchangesState {
  fetchingExchanges: boolean;
  exchanges: ListExchangeModel[];
  actions: ListExchangesActions;
}

export interface ListExchangesFilters {
  date?: SortOrderType;
  status?: ExchangeStatus[];
}

export const useListExchanges = (function getExchanges() {
  let allExchanges: ListExchangeModel[] = [];
  return function (filters?: ListExchangesFilters): ListExchangesState {
    const [exchanges, setExchanges] = useState<ListExchangeModel[]>([]);
    const [fetchingExchanges, setFetchingExchanges] = useState<boolean>(true);

    useEffect(() => {
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      fetchExchanges();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const sortByDate = (sort: SortOrderType) => {
      let nextExchanges = allExchanges;

      nextExchanges = [...nextExchanges].sort((a, b) => {
        if (dayjs(a.createdAt).isBefore(dayjs(b.createdAt))) {
          return sort === "asc" ? -1 : 1;
        }
        if (dayjs(a.createdAt).isAfter(dayjs(b.createdAt))) {
          return sort === "asc" ? 1 : -1;
        }
        return 0;
      });

      setExchanges(nextExchanges);
    };

    const filterByStatus = (status: ExchangeStatus[]) => {
      let nextExchanges = allExchanges;

      nextExchanges = nextExchanges.filter((exchange) =>
        status.includes(exchange.status)
      );

      setExchanges(nextExchanges);
    };

    const filterExchanges = (): void => {
      const { date, status } = filters!;

      if (date) sortByDate(date);
      if (status) filterByStatus(status);
    };

    const onSuccess = useCallback((resp: ExchangesResponse): void => {
      setExchanges(resp.data);
      allExchanges = resp.data;

      if (filters) filterExchanges();
    }, []);

    const onError = useCallback((err: ApiError): void => {
      toastr().danger(err?.message || DEFAULT_ERR_MSG);
    }, []);

    const onFinally = useCallback((): void => {
      setFetchingExchanges(false);
    }, []);

    const fetchExchanges = useCallback((): void => {
      if (!fetchingExchanges) setFetchingExchanges(true);

      ExchangeService()
        .list()
        .then(onSuccess)
        .catch(onError)
        .finally(onFinally);
    }, [fetchingExchanges, onError, onFinally, onSuccess]);

    return {
      fetchingExchanges,
      exchanges,
      actions: { sortByDate, filterByStatus },
    };
  };
})();
