/* eslint-disable react/no-danger */
import { FunctionComponent, h } from "preact";
import { closeIcon } from "./icons/close-icon";
import { hideTextIcon } from "./icons/hide-text-icon";
import { leftArrowIcon } from "./icons/left-arrow-icon";
import { checkMarkIcon } from "./icons/check-mark-icon";
import { logo } from "./icons/logo";
import { logoWhite } from "./icons/logo-white";
import { showTextIcon } from "./icons/show-text-icon";
import style from "./style.css";
import { rightArrowIcon } from "./icons/right-arrow-icon";
import { infoIcon } from "./icons/info-icon";
import { carrotDownIcon } from "./icons/carrot-down-icon";
import { sendIcon } from "./icons/send-icon";
import { plusIcon } from "./icons/plus-icon";
import { topLeftArrowIcon } from "./icons/top-left-arrow-icon";
import { bottomRightArrowIcon } from "./icons/bottom-right-arrow-icon";
import { moreIcon } from "./icons/more-icon";
import { calendarIcon } from "./icons/calendar-icon";

export type IconType =
  | "calendar"
  | "more"
  | "top-left-arrow"
  | "bottom-right-arrow"
  | "plus"
  | "send"
  | "info"
  | "show-text"
  | "hide-text"
  | "left-arrow"
  | "right-arrow"
  | "close"
  | "check-mark"
  | "carrot-down"
  | "logo"
  | "logo-white";

interface IconProps {
  icon: IconType;
  onClick?: (e?: MouseEvent) => void;
  iconWrapperClass?: string;
}

const Icon: FunctionComponent<IconProps> = ({
  icon,
  onClick,
  iconWrapperClass,
}) => {
  const getIcon = (): string => {
    switch (icon) {
      case "hide-text":
        return hideTextIcon;
      case "show-text":
        return showTextIcon;
      case "logo":
        return logo;
      case "left-arrow":
        return leftArrowIcon;
      case "right-arrow":
        return rightArrowIcon;
      case "logo-white":
        return logoWhite;
      case "close":
        return closeIcon;
      case "check-mark":
        return checkMarkIcon;
      case "info":
        return infoIcon;
      case "carrot-down":
        return carrotDownIcon;
      case "send":
        return sendIcon;
      case "plus":
        return plusIcon;
      case "top-left-arrow":
        return topLeftArrowIcon;
      case "bottom-right-arrow":
        return bottomRightArrowIcon;
      case "more":
        return moreIcon;
      case "calendar":
        return calendarIcon;
    }
  };

  return (
    <i
      class={`${style.iconWrapper} ${
        onClick ? style.clickable : ""
      } ${iconWrapperClass}`}
      onClick={onClick}
    >
      <div dangerouslySetInnerHTML={{ __html: getIcon() }} />
    </i>
  );
};

export default Icon;
