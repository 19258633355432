import { FunctionalComponent, h, Fragment } from "preact";
import Loader from "../../../../components/Loader/Loader";
import { useGetExchange } from "../../../../hooks/exchange";
import style from "./style.css";
import { route } from "preact-router";
import { RoutePath } from "../../../../models/route";
import Icon from "../../../../components/Icon/Icon";
import { formatDate, getMe } from "../../../../util";
import { ExchangeStatus } from "../../../../models/exchange";
import Tag, { TagType } from "../../../../components/Tag/Tag";
import Avatar from "../../../../components/Avatar/Avatar";
import HomePreviewCard from "../../../../components/HomePreviewCard/HomePreviewCard";
import Button from "../../../../components/Button/Button";
import MessageChannel from "../../../../components/Socket/channels/MessageChannel/MessageChannel";
import ChatWindowDrawer from "./components/ChatWindowDrawer/ChatWindowDrawer";
import CreateProposalDrawer from "./components/CreateProposalDrawer/CreateProposalDrawer";
import { useState } from "preact/hooks";

// TODO: Style offers

const ExchangeDetailsMobile: FunctionalComponent<any> = ({ id }) => {
  const [fetchingExchange, exchange] = useGetExchange(id);
  const [displayChatWindow, setDisplayChatWindow] = useState<boolean>(false);
  const [
    displayCreateProposalDrawer,
    setDisplayCreateProposalDrawer,
  ] = useState<boolean>(false);
  const meId = getMe().id;

  const isRequester = meId === exchange?.requester.id;

  const getTagStatus = (status: ExchangeStatus): TagType => {
    switch (status) {
      case "accepted":
        return "green";
      case "declined":
        return "red";
      case "confirmed":
        return "disabled";
      case "pending":
        return "white";
    }
  };

  const getContactHome = () => {
    const contactHome = isRequester
      ? exchange?.requesterHome!
      : exchange?.requesteeHome!;
    const contact = isRequester ? exchange?.requester! : exchange?.requestee!;
    return { ...contactHome, owner: contact };
  };

  return fetchingExchange || !exchange ? (
    <Loader fullScreen />
  ) : (
    <MessageChannel exchangeId={id}>
      <Fragment>
        <div class={style.viewWrapper}>
          <div class={style.viewHeaderWrapper}>
            <div class={style.backBtnWrapper}>
              <div
                class={style.backBtn}
                onClick={() => route(RoutePath.OwnerExchanges, true)}
              >
                <Icon
                  icon="left-arrow"
                  iconWrapperClass={style.backBtnArrowIcon}
                />
                Back
              </div>
            </div>

            <div
              class={style.openChatBtnWrapper}
              onClick={() => setDisplayChatWindow(true)}
            >
              <Avatar
                src={exchange.requestee.avatarUrl}
                fallback={`${exchange.requestee.firstName[0]}${exchange.requestee.lastName[0]}`}
              />
              <div class={style.openChatBtnContent}>
                <div
                  class={style.contactName}
                >{`${exchange.requestee.firstName} ${exchange.requestee.lastName}`}</div>
                <div class={style.openChat}>Open chat</div>
              </div>
            </div>
          </div>

          <div class={style.viewBody}>
            <div class={style.bodyHeaderWrapper}>
              <div class={style.bodyHeaderTitle}>Request date</div>
              <div class={style.bodyHeader}>
                <h4 class={style.requestDate}>
                  {formatDate(exchange.createdAt, "MMM DD, YY")}
                </h4>
                <div class={style.bodyHeaderTagWrapper}>
                  <Tag tagType={getTagStatus(exchange.status)}>
                    {exchange.status}
                  </Tag>
                </div>
                <Tag tagType="white">
                  {isRequester ? (
                    <div class={style.tagContentWrapper}>
                      <Icon
                        icon="top-left-arrow"
                        iconWrapperClass={style.tagIcon}
                      />
                      Outgoing
                    </div>
                  ) : (
                    <div class={style.tagContentWrapper}>
                      <Icon
                        icon="bottom-right-arrow"
                        iconWrapperClass={style.tagIcon}
                      />
                      Incoming
                    </div>
                  )}
                </Tag>
              </div>
            </div>

            <div class={style.exchangeDetailsWrapper}>
              <div class={style.detailsSection}>
                <div class={style.detailsSectionTitle}>Request</div>

                <div class={style.detailsContentSection}>
                  <div class={style.label}>Living sanctuary</div>
                  <HomePreviewCard
                    home={{
                      ...exchange.requesteeHome,
                      owner: exchange.requestee,
                    }}
                  />
                </div>

                <div class={style.detailsContentSection}>
                  <div class={style.label}>Initial date range</div>
                  <div class={style.dateRange}>
                    {formatDate(
                      exchange.requesteeStartDate,
                      "ddd, MMM DD, YYYY"
                    )}{" "}
                    -{" "}
                    {formatDate(exchange.requesteeEndDate, "ddd, MMM DD, YYYY")}
                  </div>
                </div>
              </div>

              <div class={style.detailsSection}>
                <div class={style.detailsSectionTitle}>Offer</div>

                <div class={style.detailsContentSection}>
                  <div class={style.label}>Living sanctuary</div>
                  <HomePreviewCard
                    home={{
                      ...exchange.requesterHome,
                      owner: exchange.requester,
                    }}
                  />
                </div>

                <div class={style.detailsContentSection}>
                  <div class={style.label}>Initial date range</div>
                  <div class={style.dateRange}>
                    {formatDate(
                      exchange.requesterStartDate,
                      "ddd, MMM DD, YYYY"
                    )}{" "}
                    -{" "}
                    {formatDate(exchange.requesterEndDate, "ddd, MMM DD, YYYY")}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class={style.viewFooterWrapper}>
            <Button
              buttonType="ghost"
              iconLeft="close"
              buttonClass={style.rejectBtn}
              widthStyle="100%"
            >
              Reject
            </Button>
            <Button
              buttonType="primary"
              iconRight="right-arrow"
              buttonClass={style.sendProposalBtn}
              widthStyle="100%"
              onClick={() => setDisplayCreateProposalDrawer(true)}
            >
              Send proposal
            </Button>
          </div>
        </div>

        <ChatWindowDrawer
          contactHome={getContactHome()}
          exchangeId={exchange.id}
          display={displayChatWindow}
          onClose={() => setDisplayChatWindow(false)}
        />

        <CreateProposalDrawer
          exchange={exchange}
          display={displayCreateProposalDrawer}
          onClose={() => setDisplayCreateProposalDrawer(false)}
        />
      </Fragment>
    </MessageChannel>
  );
};

export default ExchangeDetailsMobile;
