import { FunctionalComponent, h } from "preact";

const OwnerHome: FunctionalComponent = () => {
  return (
    <div>
      <div>Coming soon...</div>
    </div>
  );
};

export default OwnerHome;
