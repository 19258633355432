import { FunctionComponent, h } from "preact";
import { getMe } from "../../../../util";
import style from "./style.css";
import Avatar from "../../../Avatar/Avatar";
import Dropdown from "../../../Dropdown/Dropdown";
import Button from "../../../Button/Button";
import AuthService from "../../../../api/auth";

const UserProfileMenu: FunctionComponent = () => {
  const me = getMe();

  const menu = (
    <div>
      <Button
        buttonType="ghost"
        widthStyle="100%"
        onClick={() => AuthService().logout()}
      >
        Logout
      </Button>
    </div>
  );

  return (
    <div class={style.userProfileMenuWrapper}>
      <Dropdown overlay={menu}>
        <Avatar
          size="md"
          src={me.avatarUrl}
          fallback={`${me.firstName[0]}${me.lastName[0]}`}
        />
      </Dropdown>
    </div>
  );
};

export default UserProfileMenu;
