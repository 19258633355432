import { HomesResponse } from "./reqres";
import { BASE_API_URL } from "../../config";
import { get } from "../provider";

export default function HomeService() {
  return Object.freeze({
    listHomes(params?: string): Promise<HomesResponse> {
      return get(`${BASE_API_URL()}/homes${params}`);
    },
    listAvailableHomes(params?: string): Promise<HomesResponse> {
      return get(`${BASE_API_URL()}/homesAvailability${params}`);
    },
  });
}
