import { FunctionalComponent, h } from "preact";
import { isMobileView } from "../../../util/viewport";
import CreateExchangeRequestDesktop from "./desktop/CreateExchangeRequestDesktop";
import CreateExchangeRequestMobile from "./mobile/CreateExchangeRequestMobile";

const CreateExchangeRequest: FunctionalComponent = () => {
  return isMobileView() ? (
    <CreateExchangeRequestMobile />
  ) : (
    <CreateExchangeRequestDesktop />
  );
};

export default CreateExchangeRequest;
