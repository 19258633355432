/* eslint-disable @typescript-eslint/no-explicit-any */
import { FunctionalComponent, h } from "preact";
import style from "./style.css";
import Icon from "../Icon/Icon";
import { useRef, useState } from "preact/hooks";
import { useEventListener } from "../../hooks/event-listener";

export interface OptionProps {
  value: any;
  children: string;
}

export interface SelectOption {
  value: any;
  name: string;
}

export type SelectType = "default" | "styled";

export interface SelectProps {
  selectType?: SelectType;
  options: SelectOption[];
  value?: string | number;
  onChange: (value: any) => void;
  label?: string;
  pluralizeOptionNames?: boolean;
}

const Select: FunctionalComponent<SelectProps> = ({
  onChange,
  value,
  label,
  options,
  pluralizeOptionNames,
  selectType = "default",
}: SelectProps) => {
  const [displaySelectMenu, setDisplaySelectMenu] = useState<boolean>(false);

  const handleClick = () => {
    if (!displaySelectMenu) return;
    setDisplaySelectMenu(false);
  };

  useEventListener("click", handleClick);

  const selectWrapperEl = useRef<HTMLDivElement>();
  const selectEl = useRef<HTMLDivElement>();

  const getValueName = () => {
    const optionName = options.find((opt) => opt.value === value)?.name;
    return optionName
      ? pluralizeOptionNames
        ? `${optionName}s`
        : optionName
      : label;
  };

  const handleSelectOption = (value: any) => {
    onChange(value);
    setDisplaySelectMenu(false);
  };

  const isSelectedOption = (opt: SelectOption) => {
    return opt.value === value;
  };

  const getSelectElWidth = () => {
    return selectEl.current.getBoundingClientRect().width;
  };

  return selectType === "styled" ? (
    <div class={style.selectWrapper} ref={selectWrapperEl}>
      <div
        class={`
          ${style.selectInputStyled}
          ${displaySelectMenu ? style.selected : ""}
          ${value ? style.primaryFontColor : ""}
          `}
        onClick={() => setDisplaySelectMenu(!displaySelectMenu)}
        ref={selectEl}
      >
        {getValueName()}
        <Icon
          icon="carrot-down"
          iconWrapperClass={`${style.selectCarrotIcon} ${
            displaySelectMenu ? style.rotate : ""
          }`}
        />
      </div>
      {displaySelectMenu && (
        <div
          class={style.selectMenuWrapper}
          style={{ width: `${getSelectElWidth()}px` }}
        >
          {options.map((opt) => {
            return (
              <span
                key={opt.value}
                class={`${style.selectOption} ${
                  isSelectedOption(opt) ? style.selected : ""
                }`}
                onClick={() => handleSelectOption(opt.value)}
              >
                {pluralizeOptionNames ? `${opt.name}s` : opt.name}
                {isSelectedOption(opt) && (
                  <Icon
                    icon="check-mark"
                    iconWrapperClass={style.selectedOptionCheckIcon}
                  />
                )}
              </span>
            );
          })}
        </div>
      )}
    </div>
  ) : (
    <div class={style.selectWrapper} ref={selectWrapperEl}>
      <div
        class={`${style.selectInput} ${
          displaySelectMenu ? style.selected : ""
        }`}
        onClick={() => setDisplaySelectMenu(!displaySelectMenu)}
        ref={selectEl}
      >
        {getValueName()}
        <Icon
          icon="carrot-down"
          iconWrapperClass={`${style.selectCarrotIcon} ${
            displaySelectMenu ? style.rotate : ""
          }`}
        />
      </div>
      {displaySelectMenu && (
        <div
          class={style.selectMenuWrapper}
          style={{ width: `${getSelectElWidth()}px` }}
        >
          {options.map((opt) => {
            return (
              <span
                key={opt.value}
                class={`${style.selectOption} ${
                  isSelectedOption(opt) ? style.selected : ""
                }`}
                onClick={() => handleSelectOption(opt.value)}
              >
                {pluralizeOptionNames ? `${opt.name}s` : opt.name}
                {isSelectedOption(opt) && (
                  <Icon
                    icon="check-mark"
                    iconWrapperClass={style.selectedOptionCheckIcon}
                  />
                )}
              </span>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default Select;
