import { ApiError, ApiValidationError, DEFAULT_ERR_MSG } from "../api/provider";
import toastr from "../libs/toastr";
import { camelCaseToSentenceCase, capitalize } from "./format";

export type ValidationErrorOrServerError<T extends ApiError> = T extends {
  message: ApiValidationError.ValidationErrorMessage;
}
  ? { [field: string]: string[] }
  : { server: string[] };

export function parseErrors<T extends ApiError>(
  err: T
): { [field: string]: string[] } {
  if (err.message === "validation error") {
    let validationErrors = {};
    Object.keys(err.errors).forEach((key) => {
      let errors: string[] = [];
      err.errors[key].forEach((err) => {
        errors = [...errors, `${camelCaseToSentenceCase(key)} ${err}`];
      });
      validationErrors = { ...validationErrors, [key]: errors };
    });
    return validationErrors as ValidationErrorOrServerError<T>;
  }

  return { server: [capitalize(err.message)] };
}

export function onApiError(err: ApiError) {
  toastr().danger(err.message || DEFAULT_ERR_MSG);
}
