import { FunctionalComponent, h } from "preact";
import style from "./style.css";
import { useState, useEffect } from "preact/hooks";
import { HomeModel } from "../../../../../../models/home";
import Avatar from "../../../../../../components/Avatar/Avatar";
import Button from "../../../../../../components/Button/Button";
import Loader from "../../../../../../components/Loader/Loader";

interface HomeCardProps {
  home: HomeModel;
  isSelected: boolean;
  onChange: (selected: boolean) => void;
}

const HomeCard: FunctionalComponent<HomeCardProps> = ({
  home,
  isSelected,
  onChange,
}: HomeCardProps) => {
  const [hasImageLoaded, setHasImageLoaded] = useState<boolean>(false);

  const homeImageUrl = home.imageUrls[0];

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    onImageLoad();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onImageLoad = () => {
    if (!homeImageUrl) return setHasImageLoaded(true);

    const img = new Image();
    img.src = homeImageUrl;
    img.onload = () => setHasImageLoaded(true);
  };

  const getInitials = (firstName: string, lastName: string) => {
    return `${firstName[0]}${lastName[0]}`;
  };

  return (
    <div key={home.id} class={style.homeCardWrapper}>
      {hasImageLoaded ? (
        <img src={homeImageUrl} class={style.homeCardImage} />
      ) : (
        <div class={style.imageLoadingWrapper}>
          <Loader />
        </div>
      )}
      <div class={style.cardSectionBottom}>
        <div class={style.sectionBottomLeft}>
          <div class={style.homeName}>{home.name}</div>
          <Avatar
            src={home.owner.avatarUrl}
            fallback={getInitials(home.owner.firstName, home.owner.lastName)}
          />
        </div>
        <div class={style.sectionBottomRight}>
          <Button buttonType="ghost">Show details</Button>
          {isSelected ? (
            <Button
              buttonType="danger"
              iconLeft="close"
              buttonClass={style.selectButton}
              onClick={() => onChange(false)}
            >
              Deselect
            </Button>
          ) : (
            <Button
              buttonType="green"
              iconLeft="check-mark"
              buttonClass={style.selectButton}
              onClick={() => onChange(true)}
            >
              Select
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default HomeCard;
