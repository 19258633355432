import { FunctionComponent, h } from "preact";
import style from "./style.css";
import Drawer from "../../../../../../components/Drawer/Drawer";
import Avatar from "../../../../../../components/Avatar/Avatar";
import Messenger from "../../../shared/Messenger/Messenger";
import { HomeModel } from "../../../../../../models/home";

interface ChatWindowDrawerProps {
  display: boolean;
  onClose: (e?: MouseEvent) => void;
  exchangeId: string;
  contactHome: HomeModel;
}

const ChatWindowDrawer: FunctionComponent<ChatWindowDrawerProps> = ({
  display,
  onClose,
  exchangeId,
  contactHome,
}) => {
  const chatWindowHeader = (
    <div class={style.chatWindowHeaderWrapper}>
      <Avatar
        size="lg"
        src={contactHome.owner.avatarUrl}
        fallback={`${contactHome.owner.firstName[0]}${contactHome.owner.lastName[0]}`}
      />
      <div class={style.contactDetails}>
        <div
          class={style.contactName}
        >{`${contactHome.owner.firstName} ${contactHome.owner.lastName}`}</div>
        <div class={style.contactHomeName}>{contactHome.name}</div>
      </div>
    </div>
  );

  return (
    <Drawer
      title={chatWindowHeader}
      onClose={onClose}
      placement="bottom"
      display={display}
      heightStyle="90vh"
      noHeaderBorder
    >
      <div class={style.messengerWrapper}>
        <Messenger exchangeId={exchangeId} />
      </div>
    </Drawer>
  );
};

export default ChatWindowDrawer;
