import { getAuthToken } from "../util";
import AuthService from "./auth";

/* eslint-disable @typescript-eslint/no-explicit-any */
export interface ApiResponse {
  data: any;
}

export interface MessageResponse {
  data: { message: string };
}

export enum ApiValidationError {
  ValidationErrorMessage = "validation error",
  InvalidCredentialErrorMessage = "invalid credentials",
}
export interface ApiError {
  errors: { [field: string]: string[] };
  message: ApiValidationError;
  requestId: string;
  httpCode: number;
}

export const DEFAULT_ERR_MSG =
  "Oops! Something went wrong. Please refresh and try again.";

const hasInvalidToken = (payload: ApiError) => {
  if (payload.httpCode === 401 && payload.message.includes("invalid_token"))
    return true;
  return false;
};

export const post = (url: string, data: any): Promise<ApiResponse> => {
  return new Promise((resolve, reject) => {
    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `bearer ${getAuthToken()}`,
      },
      body: JSON.stringify(data),
    })
      .then((resp) => resp.json())
      .then((payload) => {
        if (payload.errors) {
          if (hasInvalidToken(payload)) return AuthService().logout();
          return reject(payload);
        }
        resolve(payload);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const put = (url: string, data: any): Promise<ApiResponse> => {
  return new Promise((resolve, reject) => {
    fetch(url, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `bearer ${getAuthToken()}`,
      },
      body: JSON.stringify(data),
    })
      .then((resp) => resp.json())
      .then((payload) => {
        if (payload.errors) {
          if (hasInvalidToken(payload)) return AuthService().logout();
          return reject(payload);
        }
        resolve(payload);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const patch = (url: string, data: any): Promise<ApiResponse> => {
  return new Promise((resolve, reject) => {
    fetch(url, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: `bearer ${getAuthToken()}`,
      },
      body: JSON.stringify(data),
    })
      .then((resp) => resp.json())
      .then((payload) => {
        if (payload.errors) {
          if (hasInvalidToken(payload)) return AuthService().logout();
          return reject(payload);
        }
        resolve(payload);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const get = (url: string): Promise<ApiResponse> => {
  return new Promise((resolve, reject) => {
    fetch(url, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `bearer ${getAuthToken()}`,
      },
    })
      .then((resp) => resp.json())
      .then((payload) => {
        if (payload.errors) {
          if (hasInvalidToken(payload)) return AuthService().logout();
          return reject(payload);
        }
        resolve(payload);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const del = (url: string): Promise<ApiResponse> => {
  return new Promise((resolve, reject) => {
    fetch(url, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `bearer ${getAuthToken()}`,
      },
    })
      .then((resp) => resp.json())
      .then((payload) => {
        if (payload.errors) {
          if (hasInvalidToken(payload)) return AuthService().logout();
          return reject(payload);
        }
        resolve(payload);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
