import { FunctionalComponent, h } from "preact";
import style from "./style.css";

interface CardProps {
  children: JSX.Element | JSX.Element[];
}

const Card: FunctionalComponent<CardProps> = ({ children }: CardProps) => {
  return <div class={style.cardWrapper}>{children}</div>;
};

export default Card;
