import { FunctionalComponent, h } from "preact";
import style from "./style.css";

export interface InputGroupProps {
  children?: JSX.Element | JSX.Element[];
  multiple?: boolean;
}

const InputGroup: FunctionalComponent<InputGroupProps> = ({
  children,
  multiple,
}: InputGroupProps) => {
  return (
    <div class={`${style.inputGroup} ${multiple ? style.multiple : ""}`}>
      {children}
    </div>
  );
};

export default InputGroup;
