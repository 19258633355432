import { OwnerResponse, UpdateOwnerRequest } from "./reqres";
import { BASE_API_URL } from "../../config";
import { put } from "../provider";

export default function OwnerService() {
  return Object.freeze({
    update(payload: UpdateOwnerRequest): Promise<OwnerResponse> {
      return put(`${BASE_API_URL()}/owners`, payload);
    },
  });
}
