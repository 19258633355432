import { useEffect, useState, useCallback } from "preact/hooks";
import HomeService, { HomesResponse } from "../../api/home";
import { convertFiltersToQueryStr } from "../../util/converter";
import { HomeModel } from "../../models/home";
import toastr from "../../libs/toastr";
import { ApiError, DEFAULT_ERR_MSG } from "../../api/provider";

interface ListAvailableHomesState {
  fetchingAvailableHomes: boolean;
  availableHomes: HomeModel[];
}

interface ListAvailableHomesActions {
  fetchAvailableHomes: (filters?: ListAvailableHomesFilters) => void;
}

export interface ListAvailableHomesFilters {
  start_date: string;
  end_date: string;
  duration: string;
}

export const useListAvailableHomes = (): [
  ListAvailableHomesState,
  ListAvailableHomesActions
] => {
  const [availableHomes, setAvailableHomes] = useState<HomeModel[]>([]);
  const [fetchingAvailableHomes, setFetchingAvailableHomes] = useState<boolean>(
    true
  );

  const onSuccess = useCallback((resp: HomesResponse): void => {
    setAvailableHomes(resp.data);
  }, []);

  const onError = useCallback((err: ApiError): void => {
    toastr().danger(err?.message || DEFAULT_ERR_MSG);
  }, []);

  const onFinally = useCallback((): void => {
    setFetchingAvailableHomes(false);
  }, []);

  const fetchAvailableHomes = useCallback(
    (filters?: ListAvailableHomesFilters): void => {
      if (!fetchingAvailableHomes) setFetchingAvailableHomes(true);

      const queryString = convertFiltersToQueryStr(filters);

      HomeService()
        .listAvailableHomes(queryString)
        .then(onSuccess)
        .catch(onError)
        .finally(onFinally);
    },
    [fetchingAvailableHomes, onError, onFinally, onSuccess]
  );

  return [{ fetchingAvailableHomes, availableHomes }, { fetchAvailableHomes }];
};
