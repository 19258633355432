import { FunctionComponent, h } from "preact";
import { useState } from "preact/hooks";
import style from "./style.css";
import Icon from "../../../Icon/Icon";
import { SortOrderType } from "../../types";
export interface SortableColumnHeaderProps {
  title: string;
  onSort: (order: SortOrderType) => void;
  defaultSortOrder: SortOrderType;
}

const SortableColumnHeader: FunctionComponent<SortableColumnHeaderProps> = ({
  defaultSortOrder,
  title,
  onSort,
}) => {
  const [isAscending, setIsAscending] = useState<boolean>(
    defaultSortOrder === "asc"
  );

  const handleSortClick = () => {
    const nextIsAscedingValue = !isAscending;
    setIsAscending(nextIsAscedingValue);
    onSort(nextIsAscedingValue === true ? "asc" : "desc");
  };

  return (
    <th onClick={handleSortClick}>
      <div class={style.sortableHeaderCell}>
        {title}
        <Icon
          icon="carrot-down"
          iconWrapperClass={`${style.carrotIconWrapper} ${
            isAscending ? style.up : ""
          }`}
        />
      </div>
    </th>
  );
};

export default SortableColumnHeader;
