import { FunctionalComponent, h, Fragment } from "preact";
import { useState } from "preact/hooks";
import { TableColumn } from "../../types";
import style from "./style.css";
import Icon from "../../../Icon/Icon";

interface ExpandableRowProps {
  columns: TableColumn[];
  data: any;
  index: number;
}

const ExpandableRow: FunctionalComponent<ExpandableRowProps> = ({
  columns,
  data,
  index,
}: ExpandableRowProps) => {
  const [expandRow, setExpandRow] = useState<boolean>(false);
  return (
    <Fragment>
      <tr
        onClick={() => setExpandRow(!expandRow)}
        class={`${style.expandableRow} ${expandRow ? style.expanded : ""}`}
      >
        <td>
          <div class={style.tableCell}>
            <div
              class={`${style.expandIconWrapper} ${
                expandRow ? style.expanded : ""
              }`}
            >
              <Icon icon="carrot-down" />
            </div>
          </div>
        </td>
        {columns.map((col) => {
          if (col.dataIndex) {
            return (
              <td>
                <div class={style.tableCell}>
                  {col.render
                    ? col.render(data[col.dataIndex as string], index)
                    : data[col.dataIndex as string]}
                </div>
              </td>
            );
          }
          if (col.render) {
            return (
              <td>
                <div class={style.tableCell}>{col.render(data, index)}</div>
              </td>
            );
          }
          return (
            <td>
              <div class={style.tableCell} />
            </td>
          );
        })}
      </tr>
      {expandRow
        ? data.children.map((child: any, index: number) => {
            const isLastChild = index === data.children.length - 1;
            return (
              <tr
                class={`${style.expandedRow} ${isLastChild ? style.last : ""}`}
              >
                <td>
                  <div class={style.tableCell} />
                </td>
                {columns.map((col) => {
                  if (col.dataIndex) {
                    return (
                      <td>
                        <div class={style.tableCell}>
                          {col.render
                            ? col.render(child[col.dataIndex as string], index)
                            : child[col.dataIndex as string]}
                        </div>
                      </td>
                    );
                  }
                  if (col.render) {
                    return (
                      <td>
                        <div class={style.tableCell}>
                          {col.render(child, index)}
                        </div>
                      </td>
                    );
                  }
                  return (
                    <td>
                      <div class={style.tableCell} />
                    </td>
                  );
                })}
              </tr>
            );
          })
        : null}
    </Fragment>
  );
};

export default ExpandableRow;
