import { Channel } from "phoenix";
import { FunctionComponent, h, createContext } from "preact";
import { Ref, useContext, useEffect, useRef, useState } from "preact/hooks";
import { ExchangeMessageResponse } from "../../../../api/exchange";
import { ApiError } from "../../../../api/provider";
import { SocketContext } from "../../Socket";

type MessageChannelContextType = {
  onNewMessage: Function;
  sendMessage: (msg: string) => Promise<ExchangeMessageResponse>;
};

export const MessageChannelContext = createContext<MessageChannelContextType>(
  {} as MessageChannelContextType
);

interface MessageChannelProps {
  exchangeId: string;
  children: JSX.Element;
}

function onNewMessageSubscription(messageSubscriptions: Ref<any[]>) {
  return (fn: any) => {
    messageSubscriptions.current = [fn, ...messageSubscriptions.current];

    return () => {
      const newSubs = messageSubscriptions.current.filter(
        (messageFn: any) => fn !== messageFn
      );
      messageSubscriptions.current = newSubs;
    };
  };
}

function sendMessage(messageChannel: Channel | null) {
  return (msg: string) => {
    if (!messageChannel) return;

    return new Promise((resolve, reject) => {
      messageChannel
        .push("new_exchange_message", { content: msg })
        .receive("ok", (resp: ExchangeMessageResponse) => resolve(resp))
        .receive("error", (err: ApiError) => reject(err))
        .receive("timeout", () => console.error("Message timeout"));
    });
  };
}

const MessageChannel: FunctionComponent<MessageChannelProps> = ({
  exchangeId,
  children,
}) => {
  const socket = useContext(SocketContext);
  const [messageChannel, setMessageChannel] = useState<Channel | null>(null);
  const messageSubscriptions = useRef<any[]>([]);

  const topic = `exchange_conversation:${exchangeId}`;

  useEffect(() => {
    if (socket && !messageChannel) {
      const channel = socket.channel(topic);

      channel.on("new_exchange_message", (data: ExchangeMessageResponse) => {
        messageSubscriptions.current.forEach((fn) => {
          fn(data);
        });
      });

      console.log("Joining channel", topic);

      channel.join().receive("error", () => {
        console.error("MessageChannel join failed", topic);
      });

      setMessageChannel(channel);
    }

    return () => {
      if (messageChannel) {
        console.debug("Leaving channel", topic);
        messageChannel.leave();
        setMessageChannel(null);
      }
    };
  }, [socket, messageChannel, topic]);

  return (
    <MessageChannelContext.Provider
      value={{
        onNewMessage: onNewMessageSubscription(messageSubscriptions),
        sendMessage: sendMessage(messageChannel) as (
          msg: string
        ) => Promise<ExchangeMessageResponse>,
      }}
    >
      {children}
    </MessageChannelContext.Provider>
  );
};

export default MessageChannel;
