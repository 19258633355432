import { DatePickerValue } from "../../../components/DatePicker/types";

export interface OfferForm {
  requesterDateRange: DatePickerValue | null;
  requesteeDateRange: DatePickerValue | null;
}

export const initialOfferForm: OfferForm = {
  requesterDateRange: null,
  requesteeDateRange: null,
};
