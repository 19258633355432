import {
  CreateExchangeRequest,
  ExchangesResponse,
  ExchangeResponse,
  ExchangeMessagesResponse,
  CreateExchangeOfferRequest,
  CreateExchangeOfferResponse,
  UpdateExchangeOfferRequest,
} from "./reqres";
import { BASE_API_URL } from "../../config";
import { MessageResponse, post, get, patch } from "../provider";

export default function ExchangeService() {
  return Object.freeze({
    create(payload: CreateExchangeRequest): Promise<MessageResponse> {
      return post(`${BASE_API_URL()}/exchanges`, payload);
    },
    list(): Promise<ExchangesResponse> {
      return get(`${BASE_API_URL()}/exchanges`);
    },
    get(exchangeId: string): Promise<ExchangeResponse> {
      return get(`${BASE_API_URL()}/exchanges/${exchangeId}`);
    },
    listMessages(exchangeId: string): Promise<ExchangeMessagesResponse> {
      return get(`${BASE_API_URL()}/exchanges/${exchangeId}/messages`);
    },
    createExchangeOffer(
      payload: CreateExchangeOfferRequest
    ): Promise<CreateExchangeOfferResponse> {
      return post(`${BASE_API_URL()}/exchange-offers`, payload);
    },
    updateExchangeOffer(
      offerId: string,
      payload: UpdateExchangeOfferRequest
    ): Promise<MessageResponse> {
      return patch(`${BASE_API_URL()}/exchange-offers/${offerId}`, payload);
    },
  });
}
