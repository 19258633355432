import { FunctionalComponent, h } from "preact";
import style from "./style.css";

interface AvatarProps {
  src: string;
  fallback?: string;
  size?: "sm" | "md" | "lg";
}

const Avatar: FunctionalComponent<AvatarProps> = ({
  src,
  fallback,
  size = "sm",
}: AvatarProps) => {
  return src ? (
    <div
      style={{ backgroundImage: `url(${src})` }}
      class={`${style.avatar} ${size === "md" ? style.md : ""} ${
        size === "lg" ? style.lg : ""
      }`}
    />
  ) : (
    <div
      class={`${style.avatar} ${style.fallback}  ${
        size === "md" ? style.md : ""
      } ${size === "lg" ? style.lg : ""}`}
    >
      {fallback}
    </div>
  );
};

export default Avatar;
