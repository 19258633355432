/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Fragment, FunctionComponent, h } from "preact";
import style from "./style.css";
import Drawer from "../../../../../../components/Drawer/Drawer";
import Button from "../../../../../../components/Button/Button";
import { ExchangeModel } from "../../../../../../models/exchange";
import HomePreviewCard from "../../../../../../components/HomePreviewCard/HomePreviewCard";
import {
  formatDate,
  formatDateRange,
  generateUuidv4,
  hasValidationError,
  ValidationRules,
} from "../../../../../../util";
import { initialOfferForm, OfferForm } from "../../../types";
import { useState } from "preact/hooks";
import DatePicker from "../../../../../../components/DatePicker/DatePicker";
import { DatePickerValue } from "../../../../../../components/DatePicker/types";
import Icon from "../../../../../../components/Icon/Icon";
import toastr from "../../../../../../libs/toastr";
import ExchangeService, {
  CreateExchangeOfferRequest,
} from "../../../../../../api/exchange";
import { onApiError } from "../../../../../../util/error";
interface CreateProposalDrawerProps {
  display: boolean;
  onClose: (e?: MouseEvent) => void;
  exchange: ExchangeModel;
}

const validationRules: ValidationRules = {
  requesterDateRange: { required: true },
  requesteeDateRange: { required: true },
};

const CreateProposalDrawer: FunctionComponent<CreateProposalDrawerProps> = ({
  display,
  onClose,
  exchange,
}) => {
  const [offerForm, setOfferForm] = useState<OfferForm>(initialOfferForm);
  const [isFormValid, setIsFormValid] = useState<boolean>(false);
  const [
    displayRequestDateRangePicker,
    setDisplayRequestDateRangePicker,
  ] = useState<boolean>(false);
  const [
    displayOfferDateRangePicker,
    setDisplayOfferDateRangePicker,
  ] = useState<boolean>(false);
  const [isCreatingOffer, setIsCreatingOffer] = useState<boolean>(false);

  const updateOfferForm = (
    key: keyof OfferForm,
    value: DatePickerValue | null
  ) => {
    const nextOfferForm = {
      ...offerForm,
      [key]: value,
    };

    setOfferForm(nextOfferForm);

    const hasError = hasValidationError(nextOfferForm, validationRules);

    if (hasError) return setIsFormValid(false);
    setIsFormValid(true);
  };

  const onSuccess = () => {
    toastr().success("Sent proposal successfully");
    onClose();
  };

  const onFinally = () => {
    setIsCreatingOffer(false);
  };

  const handleSubmitOffer = () => {
    setIsCreatingOffer(true);

    const request: CreateExchangeOfferRequest = {
      exchangeId: exchange.id,
      requesteeStartDate: offerForm.requesteeDateRange?.start!,
      requesteeEndDate: offerForm.requesteeDateRange?.end!,
      requesterStartDate: offerForm.requesterDateRange?.start!,
      requesterEndDate: offerForm.requesterDateRange?.end!,
    };

    ExchangeService()
      .createExchangeOffer(request)
      .then(onSuccess)
      .catch(onApiError)
      .finally(onFinally);
  };

  return (
    <Drawer
      placement="bottom"
      title="Your proposal"
      display={display}
      onClose={onClose}
      heightStyle="90vh"
      noHeaderBorder
    >
      <Fragment>
        <div class={style.card}>
          <div class={style.cardTitle}>Request</div>
          {!offerForm.requesteeDateRange && (
            <Fragment>
              <div class={style.cardSection}>
                <div class={style.cardSectionLabel}>Living sanctuary</div>
                <div class={style.cardSectionContent}>
                  <HomePreviewCard
                    home={{
                      ...exchange.requesteeHome,
                      owner: exchange.requestee,
                    }}
                  />
                </div>
              </div>
              <div class={style.cardSection}>
                <div class={style.cardSectionLabel}>Initial date range</div>
                <div class={style.cardSectionContent}>
                  {formatDate(exchange.requesteeStartDate, "ddd, MMM DD, YYYY")}{" "}
                  - {formatDate(exchange.requesteeEndDate, "ddd, MMM DD, YYYY")}
                </div>
              </div>
            </Fragment>
          )}
          <div class={style.cardSection}>
            <div class={style.cardSectionLabel}>
              Proposed specific date range
            </div>
            <div class={style.cardSectionContent}>
              {offerForm.requesteeDateRange ? (
                `${formatDate(
                  offerForm.requesteeDateRange.start,
                  "ddd, MMM DD, YYYY"
                )} - ${formatDate(
                  offerForm.requesteeDateRange.end,
                  "ddd, MMM DD, YYYY"
                )}`
              ) : (
                <div class={style.noSelectedRange}>(not suggested yet)</div>
              )}
            </div>
          </div>
          <div class={style.cardSection}>
            {offerForm.requesteeDateRange ? (
              <Button
                iconLeft="calendar"
                widthStyle="100%"
                buttonType="ghost"
                buttonClass={style.changeSpecificDateRangeBtn}
                onClick={() => setDisplayRequestDateRangePicker(true)}
              >
                Change specific date range
              </Button>
            ) : (
              <Button
                onClick={() => setDisplayRequestDateRangePicker(true)}
                buttonType="primary"
                buttonClass={style.suggestDateRangeBtn}
                widthStyle="100%"
              >
                Suggest specific date range
              </Button>
            )}
          </div>
        </div>

        <div class={style.card}>
          <div class={style.cardTitle}>Offer</div>
          {!offerForm.requesterDateRange && (
            <Fragment>
              <div class={style.cardSection}>
                <div class={style.cardSectionLabel}>Living sanctuary</div>
                <div class={style.cardSectionContent}>
                  <HomePreviewCard
                    home={{
                      ...exchange.requesterHome,
                      owner: exchange.requester,
                    }}
                  />
                </div>
              </div>
              <div class={style.cardSection}>
                <div class={style.cardSectionLabel}>Initial date range</div>
                <div class={style.cardSectionContent}>
                  {formatDate(exchange.requesterStartDate, "ddd, MMM DD, YYYY")}{" "}
                  - {formatDate(exchange.requesterEndDate, "ddd, MMM DD, YYYY")}
                </div>
              </div>
            </Fragment>
          )}
          <div class={style.cardSection}>
            <div class={style.cardSectionLabel}>
              Proposed specific date range
            </div>
            <div class={style.cardSectionContent}>
              {offerForm.requesterDateRange ? (
                `${formatDate(
                  offerForm.requesterDateRange.start,
                  "ddd, MMM DD, YYYY"
                )} - ${formatDate(
                  offerForm.requesterDateRange.end,
                  "ddd, MMM DD, YYYY"
                )}`
              ) : (
                <div class={style.noSelectedRange}>(not suggested yet)</div>
              )}
            </div>
          </div>
          <div class={style.cardSection}>
            {offerForm.requesterDateRange ? (
              <Button
                iconLeft="calendar"
                widthStyle="100%"
                buttonType="ghost"
                buttonClass={style.changeSpecificDateRangeBtn}
                onClick={() => setDisplayOfferDateRangePicker(true)}
              >
                Change specific date range
              </Button>
            ) : (
              <Button
                onClick={() => setDisplayOfferDateRangePicker(true)}
                buttonType="primary"
                buttonClass={style.suggestDateRangeBtn}
                widthStyle="100%"
              >
                Suggest specific date range
              </Button>
            )}
          </div>
        </div>

        {isFormValid && (
          <Button
            buttonClass={style.sendProposalBtn}
            buttonType="primary"
            iconRight="right-arrow"
            widthStyle="100%"
            loading={isCreatingOffer}
            onClick={handleSubmitOffer}
          >
            Send proposal
          </Button>
        )}

        <Drawer
          placement="bottom"
          title="Request - specific date range"
          description={`Select specific ${exchange.duration} days within initial date range.`}
          display={displayRequestDateRangePicker}
          onClose={() => setDisplayRequestDateRangePicker(false)}
          heightStyle="90vh"
        >
          <Fragment>
            <DatePicker
              key={generateUuidv4()}
              hasOneTimeMinAndMax
              durationLimit={exchange.duration}
              value={offerForm.requesteeDateRange}
              minDate={exchange.requesteeStartDate}
              maxDate={exchange.requesteeEndDate}
              onChange={(value) => updateOfferForm("requesteeDateRange", value)}
            />
            {offerForm.requesteeDateRange && (
              <div class={style.confirmationWrapper}>
                <div class={style.confirmDateRangeWrapper}>
                  <div class={style.selectedDateRangeWrapper}>
                    <div class={style.selectedDateRange}>
                      {formatDateRange(
                        offerForm.requesteeDateRange,
                        "MMMM D, YYYY"
                      )}
                    </div>
                    <Icon
                      icon="close"
                      iconWrapperClass={style.clearRangeIcon}
                      onClick={() =>
                        updateOfferForm("requesteeDateRange", null)
                      }
                    />
                  </div>
                  <Button
                    buttonType="green"
                    widthStyle="100%"
                    iconLeft="check-mark"
                    onClick={() => setDisplayRequestDateRangePicker(false)}
                    buttonClass={style.selectButton}
                  >
                    Select this date range
                  </Button>
                </div>
              </div>
            )}
          </Fragment>
        </Drawer>

        <Drawer
          placement="bottom"
          title="Offer - specific date range"
          description={`Select specific ${exchange.duration} days within initial date range.`}
          display={displayOfferDateRangePicker}
          onClose={() => setDisplayOfferDateRangePicker(false)}
          heightStyle="90vh"
        >
          <Fragment>
            <DatePicker
              key={generateUuidv4()}
              hasOneTimeMinAndMax
              durationLimit={exchange.duration}
              value={offerForm.requesterDateRange}
              minDate={exchange.requesterStartDate}
              maxDate={exchange.requesterEndDate}
              onChange={(value) => updateOfferForm("requesterDateRange", value)}
            />

            {offerForm.requesterDateRange && (
              <div class={style.confirmationWrapper}>
                <div class={style.confirmDateRangeWrapper}>
                  <div class={style.selectedDateRangeWrapper}>
                    <div class={style.selectedDateRange}>
                      {formatDateRange(
                        offerForm.requesterDateRange,
                        "MMMM D, YYYY"
                      )}
                    </div>
                    <Icon
                      icon="close"
                      iconWrapperClass={style.clearRangeIcon}
                      onClick={() =>
                        updateOfferForm("requesterDateRange", null)
                      }
                    />
                  </div>
                  <Button
                    buttonType="green"
                    widthStyle="100%"
                    iconLeft="check-mark"
                    onClick={() => setDisplayOfferDateRangePicker(false)}
                    buttonClass={style.selectButton}
                  >
                    Select this date range
                  </Button>
                </div>
              </div>
            )}
          </Fragment>
        </Drawer>
      </Fragment>
    </Drawer>
  );
};

export default CreateProposalDrawer;
