import { FunctionalComponent, h } from "preact";
import { useState, useCallback, useEffect } from "preact/hooks";
import style from "./style.css";
import AuthService, { AuthenticateRequest } from "../../../api/auth";
import { route } from "preact-router";
import { ApiError } from "../../../api/provider";
import { GuestLoginForm, initialGuestLoginForm } from "./types";
import { RoutePath } from "../../../models/route";
import { FormErrors, hasValidationError, ValidationRules } from "../../../util";
import Errors from "../../../components/Errors/Errors";
import { parseErrors } from "../../../util/error";
import Input from "../../../components/Input/Input";
import InputGroup from "../../../components/InputGroup/InputGroup";
import Button from "../../../components/Button/Button";
import Icon from "../../../components/Icon/Icon";
import { USER_TYPE_KEY } from "../../../api/auth/service";
import toastr from "../../../libs/toastr";

const validationRules: ValidationRules = {
  email: { required: true },
  password: { required: true },
};

const GuestLogin: FunctionalComponent = () => {
  const [isAuthenticating, setIsAuthenticating] = useState<boolean>(false);
  const [guestLoginForm, setGuestLoginForm] = useState<GuestLoginForm>(
    initialGuestLoginForm
  );
  const [isFormValid, setIsFormValid] = useState<boolean>(false);
  const [errors, setErrors] = useState<FormErrors>({});

  useEffect(() => {
    // Set user type to know relative login view to route to from reset password view
    localStorage.setItem(USER_TYPE_KEY, "guest");
  }, []);

  const handleUpdateGuestLoginForm = useCallback(
    (field: keyof GuestLoginForm, value: string) => {
      if (errors) setErrors({});

      const nextGuestLoginForm = {
        ...guestLoginForm,
        [field]: value,
      };

      setGuestLoginForm(nextGuestLoginForm);

      const hasError = hasValidationError(nextGuestLoginForm, validationRules);

      if (hasError) return setIsFormValid(false);
      setIsFormValid(true);
    },
    [guestLoginForm, errors]
  );

  const onSuccess = useCallback(() => {
    toastr().success("Logged in successfully");

    route(RoutePath.OwnerHome, true);
  }, []);

  const onError = useCallback((err: ApiError) => {
    const errors = parseErrors(err);
    setErrors(errors);
  }, []);

  const onFinally = useCallback(() => {
    setIsAuthenticating(false);
  }, []);

  const handleSubmit = useCallback(
    (e: Event) => {
      e.preventDefault();

      setIsAuthenticating(true);

      const payload: AuthenticateRequest = { ...guestLoginForm };

      AuthService()
        .authenticate(payload)
        .then(onSuccess)
        .catch(onError)
        .finally(onFinally);
    },
    [guestLoginForm, onError, onFinally, onSuccess]
  );

  return (
    <div class={style.loginView}>
      <div class={style.logoWrapper}>
        <Icon icon="logo" />
      </div>
      <div class={style.loginFormWrapper}>
        <h1>Sign In</h1>
        <div class={style.signUpWrapper}>
          Don't have an account? <a href={RoutePath.GuestRegister}>Sign up</a>
        </div>
        <form onSubmit={handleSubmit} class={style.loginForm}>
          <InputGroup>
            <Input
              onInput={(e: InputEvent) =>
                handleUpdateGuestLoginForm(
                  "email",
                  (e.target as HTMLInputElement).value
                )
              }
              type="email"
              label="Email"
              value={guestLoginForm.email}
              validation={validationRules.email}
              errors={errors.email}
            />
          </InputGroup>
          <InputGroup>
            <Input
              onInput={(e: InputEvent) =>
                handleUpdateGuestLoginForm(
                  "password",
                  (e.target as HTMLInputElement).value
                )
              }
              type="password"
              label="Password"
              value={guestLoginForm.password}
              validation={validationRules.password}
              errors={errors.password}
            />
          </InputGroup>
          <div class={style.errorWrapper}>
            <Errors errors={errors.server} />
          </div>
          <div class={style.buttonWrapper}>
            <Button
              widthStyle="100%"
              loading={isAuthenticating}
              htmlType="submit"
              buttonType={isFormValid ? "primary" : "disabled"}
            >
              Sign In
            </Button>
          </div>
        </form>
        <a
          href={RoutePath.ResetPasswordRequest}
          class={style.forgotPasswordWrapper}
        >
          Forgot password?
        </a>
      </div>
    </div>
  );
};

export default GuestLogin;
