/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { FunctionalComponent, h, Fragment } from "preact";
import { useEffect, useState, useMemo } from "preact/hooks";
import style from "./style.css";
import Message from "../../../../components/Message/Message";
import InputGroup from "../../../../components/InputGroup/InputGroup";
import Input from "../../../../components/Input/Input";
import DatePicker from "../../../../components/DatePicker/DatePicker";
import {
  durationPeriodOptions,
  ExchangeRequestForm,
  initialExchangeRequestForm,
} from "../types";
import { DatePickerValue } from "../../../../components/DatePicker/types";
import {
  formatDate,
  formatDateRange,
  generateUuidv4,
  getMe,
} from "../../../../util";
import { HomeModel } from "../../../../models/home";
import { useListAvailableHomes } from "../../../../hooks/home";
import Loader from "../../../../components/Loader/Loader";
import Avatar from "../../../../components/Avatar/Avatar";
import Drawer from "../../../../components/Drawer/Drawer";
import Button from "../../../../components/Button/Button";
import dayjs from "dayjs";
import Icon from "../../../../components/Icon/Icon";
import HomeCard from "./components/HomeCard/HomeCard";
import ConfirmRequestDrawer from "./components/ConfirmRequestDrawer/ConfirmRequestDrawer";

const CreateExchangeRequestMobile: FunctionalComponent = () => {
  const me = getMe();
  const ownerHomes = me.homes.map((home) => ({ ...home, owner: me }));

  const [
    { fetchingAvailableHomes, availableHomes },
    { fetchAvailableHomes },
  ] = useListAvailableHomes();
  const [
    exchangeRequestForm,
    setExchangeRequestForm,
  ] = useState<ExchangeRequestForm>(initialExchangeRequestForm);
  const [currentStep, setCurrentStep] = useState<
    keyof typeof createExchangeStepsMap
  >(1);
  const [
    displayRequestSummaryDrawer,
    setDisplayRequestSummaryDrawer,
  ] = useState<boolean>(false);
  const [
    requesterDateRangeValue,
    setRequesterDateRangeValue,
  ] = useState<DatePickerValue | null>(null);
  const [
    requesteeDateRangeValue,
    setRequesteeDateRangeValue,
  ] = useState<DatePickerValue | null>(null);
  const [durationValue, setDurationValue] = useState<number>(1);
  const [
    displayConfirmRequestDrawer,
    setDisplayConfirmRequestDrawer,
  ] = useState<boolean>(false);

  const {
    requesteeDateRange,
    requesterDateRange,
    requesteeHomes,
    requesterHome,
    duration,
    durationPeriod,
  } = exchangeRequestForm;

  const getDurationInDays = useMemo(() => {
    const period = {
      day: 1,
      week: 7,
      month: 30,
    }[exchangeRequestForm.durationPeriod!];

    const totalDays = exchangeRequestForm.duration! * period;

    return totalDays;
  }, [exchangeRequestForm.duration, exchangeRequestForm.durationPeriod]);

  useEffect(() => {
    if (!duration) return;

    fetchAvailableHomes({
      start_date: formatDate(exchangeRequestForm.requesteeDateRange?.start),
      end_date: formatDate(exchangeRequestForm.requesteeDateRange?.end),
      duration: `${getDurationInDays}`,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [duration]);

  const getOfferedHome = (): HomeModel | null => {
    return ownerHomes.find(
      (h) => h.id === exchangeRequestForm.requesterHome
    ) as HomeModel | null;
  };

  const durationInput = (
    <Drawer
      display
      title="Requested exchange duration"
      description="Propose an exchange duration within a requested date range. If the requestee accepts the duration, you shall suggest a specific date of your ownership with the same duration."
      heightStyle="80vh"
      placement="bottom"
      noHeaderBorder
    >
      <div>
        <div class={style.durationInputWrapper}>
          <input
            type="number"
            onInput={(e) =>
              setDurationValue(+(e.target as HTMLInputElement).value)
            }
            value={durationValue}
            class={style.durationInput}
          />
          {durationPeriodOptions.map((opt) => {
            return (
              <div
                class={`${style.durationPeriodButton} ${
                  durationPeriod === opt.value ? style.selected : ""
                }`}
                onClick={() =>
                  handleUpdateExchangeRequestForm("durationPeriod", opt.value)
                }
              >
                {`${opt.name}${duration! > 1 ? "s" : ""}`}
              </div>
            );
          })}
        </div>
        {durationValue > 0 && (
          <Button
            buttonType="green"
            widthStyle="100%"
            iconLeft="check-mark"
            onClick={() =>
              handleUpdateExchangeRequestForm("duration", durationValue)
            }
            buttonClass={style.selectButton}
          >
            {`Confirm ${durationValue} ${durationPeriod}${
              durationValue > 1 ? "s" : ""
            }`}
          </Button>
        )}
      </div>
    </Drawer>
  );

  const requesterDateRangeInput = (
    <Drawer
      display
      title="Offered date range"
      description="Select a date range you would like to offer."
      heightStyle="80vh"
      placement="bottom"
      noHeaderBorder
    >
      <Fragment>
        <DatePicker
          key={generateUuidv4()}
          value={requesterDateRangeValue}
          minDate={dayjs(getOfferedHome()?.startDate!).toDate()}
          maxDate={dayjs(getOfferedHome()?.endDate!).toDate()}
          onChange={(value) => setRequesterDateRangeValue(value)}
        />
        {requesterDateRangeValue && (
          <div class={style.confirmationWrapper}>
            <div class={style.confirmDateRangeWrapper}>
              <div class={style.selectedDateRangeWrapper}>
                <div class={style.selectedDateRange}>
                  {formatDateRange(requesterDateRangeValue, "MMMM D, YYYY")}
                </div>
                <Icon
                  icon="close"
                  iconWrapperClass={style.clearRangeIcon}
                  onClick={() => setRequesterDateRangeValue(null)}
                />
              </div>
              <Button
                buttonType="green"
                widthStyle="100%"
                iconLeft="check-mark"
                onClick={() =>
                  handleUpdateExchangeRequestForm(
                    "requesterDateRange",
                    requesterDateRangeValue!
                  )
                }
                buttonClass={style.selectButton}
              >
                Select this date range
              </Button>
            </div>
          </div>
        )}
      </Fragment>
    </Drawer>
  );

  const requesteeDateRangeInput = (
    <Drawer
      display
      title="Requested date range"
      description="Select a date range within which you’d like to book your stay."
      heightStyle="80vh"
      placement="bottom"
      noHeaderBorder
    >
      <Fragment>
        <DatePicker
          key={generateUuidv4()}
          value={requesteeDateRangeValue}
          minDate={new Date()}
          onChange={(value) => setRequesteeDateRangeValue(value)}
        />
        {requesteeDateRangeValue && (
          <div class={style.confirmationWrapper}>
            <div class={style.confirmDateRangeWrapper}>
              <div class={style.selectedDateRangeWrapper}>
                <div class={style.selectedDateRange}>
                  {formatDateRange(requesteeDateRangeValue, "MMMM DD, YYYY")}
                </div>
                <Icon
                  icon="close"
                  iconWrapperClass={style.clearRangeIcon}
                  onClick={() => setRequesteeDateRangeValue(null)}
                />
              </div>
              <Button
                buttonType="green"
                widthStyle="100%"
                iconLeft="check-mark"
                onClick={() =>
                  handleUpdateExchangeRequestForm(
                    "requesteeDateRange",
                    requesteeDateRangeValue!
                  )
                }
                buttonClass={style.selectButton}
              >
                Select this date range
              </Button>
            </div>
          </div>
        )}
      </Fragment>
    </Drawer>
  );

  const requesterHomeInput = (
    <div class={style.availableHomesListWrapper}>
      <div class={style.availableHomesListHeader}>
        <div class={style.availableHomesTitle}>
          Select your offered living sanctuary
        </div>
      </div>
      {ownerHomes.map((home: HomeModel, index) => {
        return (
          <div
            style={
              index + 1 === ownerHomes.length
                ? { marginBottom: "0" }
                : { marginBottom: "56px" }
            }
          >
            <HomeCard
              home={home}
              isSelected={home.id === requesterHome}
              onChange={() =>
                handleUpdateExchangeRequestForm("requesterHome", home.id)
              }
            />
          </div>
        );
      })}
    </div>
  );

  const isHomeSelected = (homeId: string) => {
    return requesteeHomes.some((home) => home.id === homeId);
  };

  const requesteeHomesInput = (
    <div class={style.availableHomesListWrapper}>
      <div class={style.availableHomesListHeader}>
        <div class={style.availableHomesTitle}>
          Available Living Sanctuaries
        </div>
      </div>
      {fetchingAvailableHomes ? (
        <Loader />
      ) : !availableHomes.length ? (
        "No living sanctuaries available in requested range"
      ) : (
        availableHomes.map((home: HomeModel, index) => {
          return (
            <div
              style={
                index + 1 === availableHomes.length
                  ? { marginBottom: "0" }
                  : { marginBottom: "56px" }
              }
            >
              <HomeCard
                home={home}
                isSelected={isHomeSelected(home.id)}
                onChange={(value) => handleUpdateHomeSelected(home, value)}
              />
            </div>
          );
        })
      )}
      {!!requesteeHomes.length && !displayRequestSummaryDrawer && (
        <div class={style.confirmationWrapper}>
          <div class={style.toSummaryWrapper}>
            <Button
              buttonType={requesteeHomes.length ? "primary" : "disabled"}
              widthStyle="100%"
              onClick={() => setDisplayRequestSummaryDrawer(true)}
              iconRight="carrot-down"
              buttonClass={style.toSummaryButton}
            >
              {`Summary (${requesteeHomes.length} homes)`}
            </Button>
          </div>
        </div>
      )}
    </div>
  );

  const createExchangeStepsMap: { [step: number]: h.JSX.Element } = {
    1: requesterHomeInput,
    2: requesterDateRangeInput,
    3: requesteeDateRangeInput,
    4: durationInput,
    5: requesteeHomesInput,
  };

  const setDefaultInputValues = (step: number) => {
    if (step >= 4) return;

    if (step === 1) {
      setRequesteeDateRangeValue(null);
      setRequesterDateRangeValue(null);
      setDurationValue(1);
    }
    if (step === 2) {
      setRequesteeDateRangeValue(null);
      setDurationValue(1);
    }
    if (step === 3) {
      setDurationValue(1);
    }
  };

  const handleSetCurrentStep = (step: number) => {
    setDefaultInputValues(step);
    setCurrentStep(step);
  };

  const handleUpdateExchangeRequestForm = (
    field: keyof ExchangeRequestForm,
    value: DatePickerValue | number | boolean | string | undefined
  ) => {
    const nextExchangeRequestForm = getNextExchangeRequestForm(field, value);
    setExchangeRequestForm(nextExchangeRequestForm);

    handleSetCurrentStep(currentStep + 1);
  };

  const getNextExchangeRequestForm = (
    field: keyof ExchangeRequestForm,
    value: DatePickerValue | number | boolean | string | undefined
  ) => {
    if (field === "requesterHome") {
      return {
        ...exchangeRequestForm,
        requesterHome: value as string,
        requesterDateRange: null,
        requesteeDateRange: null,
        duration: 1,
        requesteeHomes: [],
      };
    }
    if (field === "requesterDateRange") {
      return {
        ...exchangeRequestForm,
        requesterDateRange: value as DatePickerValue,
        requesteeDateRange: null,
        duration: 1,
        requesteeHomes: [],
      };
    }
    if (field === "requesteeDateRange") {
      return {
        ...exchangeRequestForm,
        requesteeDateRange: value as DatePickerValue,
        duration: 1,
        requesteeHomes: [],
      };
    }

    return {
      ...exchangeRequestForm,
      [field]: value,
    };
  };

  const getDurationValue = (): string => {
    const { duration, durationPeriod } = exchangeRequestForm;
    if (!duration || !durationPeriod) return "";
    return `${duration} ${
      duration > 1 ? `${durationPeriod}s` : durationPeriod
    }`;
  };

  const getInitials = (firstName: string, lastName: string) => {
    return `${firstName[0]}${lastName[0]}`;
  };

  const handleUpdateHomeSelected = (home: HomeModel, isSelected: boolean) => {
    const { requesteeHomes } = exchangeRequestForm;

    const nextRequesteeHomesValue = isSelected
      ? [...requesteeHomes, home]
      : requesteeHomes.filter(({ id }) => id !== home.id);

    const nextExchangeRequestForm = {
      ...exchangeRequestForm,
      requesteeHomes: nextRequesteeHomesValue,
    };

    setExchangeRequestForm(nextExchangeRequestForm);
  };

  return (
    <div class={style.viewWrapper}>
      <h4 class={style.viewTitle}>New Exchange</h4>
      <Message
        messageKey="exchange-instructions-msg"
        message="Start by selecting date ranges and duration to filter available living sanctuaries."
        type="info"
      />

      <InputGroup multiple>
        <Input
          type="text"
          readOnly
          label="Offered living sanctuary"
          value={getOfferedHome()?.name!}
          onFocus={() => handleSetCurrentStep(1)}
        />
        <Input
          type="text"
          readOnly
          disabled={!requesterHome}
          label="Offered date range"
          value={formatDateRange(requesterDateRange)}
          onFocus={() => handleSetCurrentStep(2)}
        />
      </InputGroup>

      <InputGroup multiple>
        <Input
          type="text"
          readOnly
          label="Requested date range"
          value={formatDateRange(requesteeDateRange)}
          onFocus={() => handleSetCurrentStep(3)}
        />
        <Input
          type="text"
          readOnly
          label="Duration"
          value={getDurationValue()}
          onFocus={() => handleSetCurrentStep(4)}
        />
      </InputGroup>

      {createExchangeStepsMap[currentStep]}

      <Drawer
        display={displayRequestSummaryDrawer}
        heightStyle="auto"
        placement="bottom"
        title="Summary"
        onClose={() => setDisplayRequestSummaryDrawer(false)}
      >
        <div class={style.requestSummaryWrapper}>
          <div class={style.selectedHomesWrapper}>
            {exchangeRequestForm.requesteeHomes.map((home) => {
              return (
                <div key={home.id} class={style.selectedHome}>
                  <div class={style.selectedHomeSectionLeft}>
                    <img
                      class={style.selectedHomeImage}
                      src={home.imageUrls[0]}
                    />
                    <div class={style.selectedHomeDetails}>
                      <div class={style.homeName}>{home.name}</div>
                      <div class={style.ownerNameWrapper}>
                        <Avatar
                          src={home.owner.avatarUrl}
                          fallback={getInitials(
                            home.owner.firstName,
                            home.owner.lastName
                          )}
                        />
                        <div class={style.ownerName}>
                          {home.owner.firstName} {home.owner.lastName}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class={style.selectedHomeSectionRight}>
                    <Icon
                      icon="close"
                      iconWrapperClass={style.selectedHomeCloseIcon}
                      onClick={() => handleUpdateHomeSelected(home, false)}
                    />
                  </div>
                </div>
              );
            })}
          </div>
          <Button
            buttonClass={style.createRequestButton}
            buttonType="primary"
            iconRight="right-arrow"
            onClick={() => setDisplayConfirmRequestDrawer(true)}
            widthStyle="100%"
          >
            Create a request
          </Button>
        </div>
      </Drawer>

      <ConfirmRequestDrawer
        form={exchangeRequestForm}
        display={displayConfirmRequestDrawer}
        onClose={() => setDisplayConfirmRequestDrawer(false)}
        offeredHome={getOfferedHome()}
        onUpdateMessage={(value) =>
          handleUpdateExchangeRequestForm("message", value)
        }
      />
    </div>
  );
};

export default CreateExchangeRequestMobile;
