import { FunctionalComponent, h } from "preact";
import ExpandableRow from "./components/ExpandableRow/ExpandableRow";
import style from "./style.css";
import SortableColumnHeader from "./components/SortableColumnHeader/SortableColumnHeader";
import FilterableColumnHeader from "./components/FilterableColumnHeader/FilterableColumnHeader";
import { TableColumn, TablePagination } from "./types";
import Button from "../Button/Button";
import { useState } from "preact/hooks";
interface DataTableProps {
  columns: TableColumn[];
  dataSource: any[];
  pagination?: TablePagination;
}

const DataTable: FunctionalComponent<DataTableProps> = ({
  columns,
  dataSource,
  pagination,
}: DataTableProps) => {
  const [currentPage, setCurrentPage] = useState<number>(1);

  const totalPages = () => {
    return Math.ceil(dataSource.length / pagination?.totalEntriesPerPage!);
  };

  const hasExpandableRows = (): boolean => {
    return dataSource.some((data) => data.children.length > 1);
  };

  const hasMultiplePages = (): boolean => {
    if (!pagination) return false;
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return dataSource.length > pagination?.totalEntriesPerPage!;
  };

  const hasNoData = dataSource.length === 0;

  const isInPageRange = (dataIndex: number) => {
    const rangeStart =
      currentPage === 1
        ? 1
        : (currentPage - 1) * pagination?.totalEntriesPerPage! + 1;
    const rangeEnd = currentPage * pagination?.totalEntriesPerPage!;
    return dataIndex + 1 >= rangeStart && dataIndex + 1 <= rangeEnd;
  };

  const getTableRow = (data: any, index: number) => {
    if (data.children.length > 1) {
      return <ExpandableRow data={data} index={index} columns={columns} />;
    }

    return (
      <tr>
        {hasExpandableRows() && (
          <td>
            <div class={style.tableCell} />
          </td>
        )}
        {columns.map((col) => {
          if (col.dataIndex) {
            return (
              <td>
                <div class={style.tableCell}>
                  {col.render
                    ? col.render(data[col.dataIndex as string], index)
                    : data[col.dataIndex as string]}
                </div>
              </td>
            );
          }
          if (col.render) {
            return (
              <td>
                <div class={style.tableCell}>{col.render(data, index)}</div>
              </td>
            );
          }
          return (
            <td>
              <div class={style.tableCell} />
            </td>
          );
        })}
      </tr>
    );
  };

  return (
    <div class={style.dataTableWrapper}>
      <table>
        <thead>
          <tr>
            {hasExpandableRows() && <th class={style.tableCell} />}
            {columns.map((col, index) => {
              if (hasNoData && index === 0) {
                return (
                  <th>
                    <div class={`${style.tableCell} ${style.paddingLeft}`}>
                      {col.title}
                    </div>
                  </th>
                );
              }
              if (col.onSort) {
                return (
                  <SortableColumnHeader
                    title={col.title!}
                    onSort={col.onSort}
                    defaultSortOrder={col.defaultSortOrder!}
                  />
                );
              }
              if (col.filter) {
                return (
                  <FilterableColumnHeader
                    title={col.title!}
                    filter={col.filter}
                  />
                );
              }
              return (
                <th>
                  <div class={style.tableCell}>{col.title}</div>
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {hasNoData && (
            <tr>
              <td colSpan={columns.length}>
                <div class={style.noDataWrapper}>No data</div>
              </td>
            </tr>
          )}
          {dataSource.reduce((acc, data, index) => {
            if (!pagination || (pagination && isInPageRange(index))) {
              return [...acc, getTableRow(data, index)];
            }
            return acc;
          }, [])}
        </tbody>
      </table>
      {pagination && hasMultiplePages() && (
        <div class={style.tableFooterWrapper}>
          {currentPage > 1 && (
            <Button
              buttonClass={style.previousButton}
              buttonType="clear"
              onClick={() => setCurrentPage(currentPage - 1)}
              iconLeft="left-arrow"
            >
              Previous
            </Button>
          )}
          <div class={style.pageCountWrapper}>
            Page {currentPage} of {totalPages()}
          </div>
          {currentPage < totalPages() && (
            <Button
              buttonClass={style.nextButton}
              buttonType="clear"
              onClick={() => setCurrentPage(currentPage + 1)}
              iconRight="right-arrow"
            >
              Next
            </Button>
          )}
        </div>
      )}
    </div>
  );
};

export default DataTable;
