import { FunctionalComponent, h } from "preact";
import style from "./style.css";

interface CheckBoxProps {
  checked: boolean;
  label?: string | JSX.Element;
  onChange: (value: boolean) => void;
}

const CheckBox: FunctionalComponent<CheckBoxProps> = ({
  checked,
  label,
  onChange,
}: CheckBoxProps) => {
  return (
    <label class={style.container}>
      {label}
      <input
        type="checkbox"
        checked={checked}
        onClick={(e) => onChange(!checked)}
      />
      <span class={style.checkmark} />
    </label>
  );
};

export default CheckBox;
