export enum RoutePath {
  GuestLogin = "/guest/login",
  OwnerLogin = "/owner/login",
  GuestRegister = "/guest/register",
  ResetPasswordRequest = "/reset-password-request",
  ResetPassword = "/reset-password",
  GuestHome = "/guest/home",
  GuestSettings = "/guest/settings",
  OwnerHome = "/owner/home",
  OwnerCreateExchangeRequest = "/owner/exchanges/create",
  OwnerExchanges = "/owner/exchanges",
  OwnerExchangeDetails = "/owner/exchanges/:id",
  OwnerSettings = "owner/settings",
}
