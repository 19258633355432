import { FunctionalComponent, h } from "preact";
import { useMemo } from "preact/hooks";
import { isMobileView } from "../../../util/viewport";
import ExchangeDetailsMobile from "./mobile/ExchangeDetailsMobile";
import ExchangeDetailsDesktop from "./desktop/ExchangeDetailsDesktop";
import { RoutableProps } from "preact-router";

const ExchangeDetails: FunctionalComponent<RoutableProps> = (props) => {
  const isMobile = useMemo(() => isMobileView(), []);

  return isMobile ? (
    <ExchangeDetailsMobile {...props} />
  ) : (
    <ExchangeDetailsDesktop {...props} />
  );
};

export default ExchangeDetails;
