export const initialGuestRegisterForm: GuestRegisterForm = {
  fullName: "",
  email: "",
  password: "",
};

export interface GuestRegisterForm {
  fullName: string;
  email: string;
  password: string;
}
