import { FunctionalComponent, h } from "preact";
import style from "./style.css";
import dayjs from "dayjs";
import isToday from "dayjs/plugin/isToday";
import isBetween from "dayjs/plugin/isBetween";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import { useRef, useState } from "preact/hooks";
import {
  CalendarMonthDay,
  CalendarMonthRow,
  CalendarMonthRows,
  DatePickerValue,
} from "./types";
import Button from "../Button/Button";
import { formatDate } from "../../util";
import toastr from "../../libs/toastr";

dayjs.extend(isToday);
dayjs.extend(isBetween);
dayjs.extend(isSameOrBefore);
export interface DatePickerProps {
  minDate: Date;
  maxDate?: Date;
  hasOneTimeMinAndMax?: boolean;
  value?: DatePickerValue | null;
  onChange: (selectedRange: DatePickerValue) => void;
  durationLimit?: number;
}

const getDatePickerRange = (startDate: Date, totalMonthRange: number) => {
  const today = new Date();
  const rangeStartYear = dayjs(startDate).isBefore(dayjs(today))
    ? today.getFullYear() + 1
    : dayjs(startDate).toDate().getFullYear();

  return new Array(totalMonthRange)
    .fill(dayjs(startDate).toDate().getMonth() + 1)
    .map((month, index) => {
      if (month + index > 12) {
        return {
          month: month + index - 12,
          year: rangeStartYear + 1,
        };
      }
      return {
        month: month + index,
        year: rangeStartYear,
      };
    });
};

const DatePicker: FunctionalComponent<DatePickerProps> = ({
  value,
  minDate,
  maxDate,
  onChange,
  durationLimit,
  hasOneTimeMinAndMax,
}: DatePickerProps) => {
  const [hoveredDate, setHoveredDate] = useState<CalendarMonthDay>(undefined);
  const [selectedRange, setSelectedRange] = useState<
    DatePickerValue | undefined | null
  >(value);
  const [datePickerMonthRange, setDatePickerMonthRange] = useState<
    { month: number; year: number }[]
  >(
    getDatePickerRange(
      minDate,
      hasOneTimeMinAndMax
        ? dayjs(maxDate).month() - dayjs(minDate).month() + 1
        : 15
    )
  );

  const monthsWrapperEl = useRef<HTMLDivElement>(null);

  const hasOnlySelectedStart =
    !!selectedRange && !!selectedRange.start && !selectedRange.end;
  const hasSelectedStartAndEnd =
    !!selectedRange && !!selectedRange.start && !!selectedRange.end;

  const isBeforeMin = (date: Date): boolean => {
    if (hasOneTimeMinAndMax) {
      return dayjs(date).isBefore(dayjs(minDate), "day");
    }

    const dateWithoutYear = formatDate(date, "MMM DD");
    const minDateWithoutYear = formatDate(minDate, "MMM DD");
    return dayjs(dateWithoutYear).isBefore(dayjs(minDateWithoutYear), "day");
  };

  const isAfterMax = (date: Date): boolean => {
    if (!maxDate) return false;

    if (hasOneTimeMinAndMax) {
      return dayjs(date).isAfter(dayjs(maxDate), "day");
    }

    const dateWithoutYear = formatDate(date, "MMM DD");
    const maxDateWithoutYear = formatDate(maxDate, "MMM DD");
    return dayjs(dateWithoutYear).isAfter(dayjs(maxDateWithoutYear));
  };

  const isSelectedStart = (date: Date) => {
    return (
      (hasOnlySelectedStart &&
        dayjs(date).isSame(dayjs(selectedRange?.start), "day")) ||
      (hasSelectedStartAndEnd &&
        dayjs(date).isSame(dayjs(selectedRange?.start), "day"))
    );
  };

  const isSelectedEnd = (date: Date) => {
    return (
      hasSelectedStartAndEnd &&
      dayjs(date).isSame(dayjs(selectedRange?.end), "day")
    );
  };

  const isToday = (date: Date) => {
    return dayjs(date).isToday();
  };

  const isFirstDayAfterSelectedStart = (date: Date) => {
    return (
      (hasSelectedStartAndEnd &&
        !dayjs(selectedRange?.start).isSame(dayjs(selectedRange?.end), "day") &&
        dayjs(date).isSame(dayjs(selectedRange?.start).add(1, "day"), "day")) ||
      (hasOnlySelectedStart &&
        !!hoveredDate &&
        dayjs(date).isSame(dayjs(selectedRange?.start).add(1, "day"), "day"))
    );
  };

  const isDayBeforeSelectedEndOrHoverEnd = (date: Date) => {
    return (
      (hasSelectedStartAndEnd &&
        !dayjs(selectedRange?.start).isSame(dayjs(selectedRange?.end), "day") &&
        dayjs(date).isSame(
          dayjs(selectedRange?.end).subtract(1, "day"),
          "day"
        )) ||
      (hasOnlySelectedStart &&
        !!hoveredDate &&
        dayjs(date).isSame(dayjs(hoveredDate).subtract(1, "day"), "day"))
    );
  };

  const isOnlyDayBetweenRange = (date: Date) => {
    return (
      isFirstDayAfterSelectedStart(date) &&
      isDayBeforeSelectedEndOrHoverEnd(date)
    );
  };

  const isBetweenRange = (date: Date) => {
    return (
      (hasSelectedStartAndEnd &&
        dayjs(date).isBetween(
          dayjs(selectedRange?.start),
          dayjs(selectedRange?.end),
          "day"
        )) ||
      (hasOnlySelectedStart &&
        !!hoveredDate &&
        dayjs(date).isBetween(
          dayjs(selectedRange?.start),
          dayjs(hoveredDate),
          "day"
        ))
    );
  };

  const isEndRangeHover = (date: Date) => {
    return (
      hasOnlySelectedStart &&
      !!hoveredDate &&
      dayjs(date).isSame(dayjs(hoveredDate), "day") &&
      dayjs(date).isAfter(dayjs(selectedRange?.start), "day")
    );
  };

  const isHoveringOverFutureDateOrHasSelectedEnd = (): boolean => {
    return (
      (hoveredDate &&
        dayjs(hoveredDate).isAfter(dayjs(selectedRange?.start))) ||
      !!selectedRange?.end
    );
  };

  const dayStateStyle = (date: Date): string => {
    switch (true) {
      case isBeforeMin(date):
        return style.beforeMinDate;
      case isAfterMax(date):
        return style.afterMaxDate;
      case isSelectedStart(date):
        return `${style.selectedStart} ${
          isHoveringOverFutureDateOrHasSelectedEnd() ? style.withRange : ""
        }`;
      case isSelectedEnd(date):
        return style.selectedEnd;
      case isToday(date):
        return style.today;
      case isEndRangeHover(date):
        return style.selectingRangeEndHover;
      case isOnlyDayBetweenRange(date):
        return style.onlyDateBetweenRange;
      case isFirstDayAfterSelectedStart(date):
        return style.firstDayAfterSelectedStart;
      case isDayBeforeSelectedEndOrHoverEnd(date):
        return style.dayBeforeSelectedEndOrHoverEnd;
      case isBetweenRange(date):
        return style.inBetweenRange;
      default:
        return "";
    }
  };

  const handleOnDateHover = (date: Date) => {
    if (
      hasSelectedStartAndEnd ||
      dayjs(date).isSameOrBefore(dayjs(selectedRange?.start), "day") ||
      (durationLimit &&
        dayjs(date)
          .add(durationLimit - 1, "days")
          .isAfter(dayjs(maxDate)))
    ) {
      return;
    }
    setHoveredDate(date);
  };

  const handleOnMouseLeave = () => {
    if (hasSelectedStartAndEnd || !hoveredDate) return;
    setHoveredDate(undefined);
  };

  const handleOnDateClick = (date: Date) => {
    setHoveredDate(undefined);

    if (
      durationLimit &&
      dayjs(date)
        .add(durationLimit - 1, "days")
        .isAfter(dayjs(maxDate))
    ) {
      return toastr().danger("Selected range is not within duration");
    }

    if (durationLimit) {
      onChange({
        start: date,
        end: dayjs(date)
          .add(durationLimit - 1, "days")
          .toDate(),
      });
      setSelectedRange({
        start: date,
        end: dayjs(date)
          .add(durationLimit - 1, "days")
          .toDate(),
      });
      return;
    }

    if (
      !selectedRange ||
      hasSelectedStartAndEnd ||
      dayjs(date).isBefore(dayjs(selectedRange?.start), "day")
    ) {
      setSelectedRange({ start: date, end: undefined });
      return;
    }
    onChange({ ...selectedRange, end: date });
    setSelectedRange({ ...selectedRange, end: date });
  };

  const getDayEl = (date?: Date) => {
    if (!date) return <div class={style.daySpacer} />;

    const dayOfMonth = date?.getDate();
    const isStartOfMonth = dayOfMonth === 1;
    const isEndOfMonth =
      dayjs(date).endOf("month").toDate().getDate() === dayOfMonth;

    return (
      <div
        children-data={dayOfMonth}
        onClick={() => handleOnDateClick(date)}
        onMouseEnter={() => handleOnDateHover(date)}
        onMouseLeave={() => handleOnMouseLeave()}
        class={`
        ${style.dayWrapper}
        ${dayStateStyle(date)}
        ${isStartOfMonth ? style.startOfMonth : ""}
        ${isEndOfMonth ? style.endOfMonth : ""} `}
      >
        {dayOfMonth}
      </div>
    );
  };

  const getRowEl = (row: CalendarMonthRow) => {
    return <div class={style.rowWrapper}>{row.map((d) => getDayEl(d))}</div>;
  };

  const handleShowMoreDates = () => {
    const rangeEnd = datePickerMonthRange[datePickerMonthRange.length - 1];
    const nextRangeStart = dayjs(
      `${rangeEnd.year}-${rangeEnd.month + 1}-01`
    ).toDate();
    const nextRange = getDatePickerRange(nextRangeStart, 15);

    setDatePickerMonthRange([...datePickerMonthRange, ...nextRange]);
  };

  const getMonthDays = (m: {
    month: number;
    year: number;
  }): CalendarMonthRows => {
    const { month, year } = m;

    const daysInMonth = dayjs(`${year}-${month}-01`).daysInMonth();
    const startDay = dayjs(`${year}-${month}-01`).startOf("month");

    // List of all days in month
    const monthDays = new Array(daysInMonth)
      .fill(dayjs(`${year}-${month}-01`))
      .map((d, index) => d.add(index, "day").toDate());

    const totalRows = Math.ceil(daysInMonth / 7);
    let monthDaysIndexCount = -1;

    const rows: CalendarMonthRows = new Array(totalRows)
      .fill(new Array(7).fill(undefined))
      .map((row: CalendarMonthRow, rowIndex: number) => {
        return row.map((day: CalendarMonthDay, dayIndex: number) => {
          if (rowIndex === 0 && dayIndex < startDay.day()) {
            return undefined;
          }
          monthDaysIndexCount += 1;
          return monthDays[monthDaysIndexCount];
        });
      });

    return rows;
  };

  const getMonthEl = (m: { month: number; year: number }) => {
    const { month, year } = m;

    const monthName = dayjs(`${year}-${month}-01`).format("MMMM");
    const monthDays = getMonthDays(m);

    return (
      <div class={style.monthWrapper}>
        <div class={style.monthTitle}>
          {monthName} {year}
        </div>
        <div class={style.daysWrapper}>
          {monthDays.map((row) => getRowEl(row))}
        </div>
      </div>
    );
  };

  const getMonths = () => {
    return datePickerMonthRange.map((m) => getMonthEl(m));
  };

  return (
    <div class={style.datePickerWrapper}>
      <div class={style.labelsWrapper}>
        <div>Su</div>
        <div>Mo</div>
        <div>Tu</div>
        <div>We</div>
        <div>Th</div>
        <div>Fr</div>
        <div>Sa</div>
      </div>

      <div class={style.monthsWrapper} ref={monthsWrapperEl}>
        {getMonths()}

        {!hasOneTimeMinAndMax && (
          <div class={style.loadMoreBtnWrapper}>
            <Button buttonType="green" onClick={handleShowMoreDates}>
              Show more dates
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default DatePicker;
