import { FunctionalComponent, h } from "preact";
import { useCallback, useState } from "preact/hooks";
import AuthService from "../../../api/auth";
import OwnerService, {
  OwnerResponse,
  UpdateOwnerRequest,
} from "../../../api/owner";
import { ApiError } from "../../../api/provider";
import Errors from "../../../components/Errors/Errors";
import {
  FormErrors,
  getMe,
  hasValidationError,
  isValidEmail,
  ValidationRules,
} from "../../../util";
import { parseErrors } from "../../../util/error";
import { OwnerForm } from "./types";
import Button from "../../../components/Button/Button";

const validationRules: ValidationRules = {
  email: {
    required: true,
    rules: [{ rule: isValidEmail, message: "Must be a valid email address" }],
  },
  firstName: { required: true },
  lastName: { required: true },
};

const OwnerSettings: FunctionalComponent = () => {
  const me = getMe();

  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [isFormValid, setIsFormValid] = useState<boolean>(false);
  const [ownerForm, setOwnerForm] = useState<OwnerForm>({
    email: me.email,
    firstName: me.firstName,
    lastName: me.lastName,
  });
  const [errors, setErrors] = useState<FormErrors>({});

  const handleUpdateOwnerForm = useCallback(
    (field: keyof OwnerForm, value: string) => {
      if (errors) setErrors({});

      const nextOwnerForm = {
        ...ownerForm,
        [field]: value,
      };

      setOwnerForm(nextOwnerForm);

      const hasError = hasValidationError(nextOwnerForm, validationRules);

      if (hasError) return setIsFormValid(false);
      setIsFormValid(true);
    },
    [ownerForm, errors]
  );

  const onSuccess = useCallback((resp: OwnerResponse) => {
    AuthService().setMe(resp.data);
    alert("saved!");
  }, []);

  const onError = useCallback((err: ApiError) => {
    console.log(err);
    const errors = parseErrors(err);
    setErrors(errors);
  }, []);

  const onFinally = useCallback(() => {
    setIsSaving(false);
  }, []);

  const handleSubmit = useCallback(
    (e: MouseEvent) => {
      e.preventDefault();

      setIsSaving(true);

      const payload: UpdateOwnerRequest = { ...ownerForm, id: getMe().id };

      OwnerService()
        .update(payload)
        .then(onSuccess)
        .catch(onError)
        .finally(onFinally);
    },
    [ownerForm, onError, onFinally, onSuccess]
  );

  return (
    <div class="h-screen bg-white overflow-hidden flex">
      <div class="flex-1 flex flex-col">
        <main class="flex-1 overflow-y-auto focus:outline-none" tabIndex={0}>
          <div class="relative max-w-4xl mx-auto md:px-8 xl:px-0">
            <div class="pt-10 pb-16">
              <div class="px-4 sm:px-6 md:px-0">
                <h1 class="text-3xl font-extrabold text-gray-900">Settings</h1>
              </div>
              <div class="px-4 sm:px-6 md:px-0">
                <div class="py-6">
                  <div class="lg:hidden">
                    <label for="selected-tab" class="sr-only">
                      Select a tab
                    </label>
                    <select
                      id="selected-tab"
                      name="selected-tab"
                      class="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-purple-500 focus:border-purple-500 sm:text-sm rounded-md"
                    >
                      <option selected>Profile</option>
                    </select>
                  </div>
                  <div class="hidden lg:block">
                    <div class="border-b border-gray-200">
                      <nav class="-mb-px flex space-x-8">
                        <a
                          href="#"
                          class="border-purple-500 text-purple-600 whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm"
                        >
                          Profile
                        </a>
                      </nav>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="mt-6 grid gap-6 justify-center">
            <section aria-labelledby="payment_details_heading">
              <form action="#" method="POST">
                <div class="shadow sm:rounded-md sm:overflow-hidden">
                  <div class="bg-white py-6 px-4 sm:p-6">
                    <div>
                      <h2
                        id="payment_details_heading"
                        class="text-lg leading-6 font-medium text-gray-900"
                      >
                        Profile
                      </h2>
                    </div>

                    <div class="mt-6 grid gap-6">
                      <div class="col-span-4 sm:col-span-2">
                        <label
                          for="firstName"
                          class="block text-sm font-medium text-gray-700"
                        >
                          First name
                        </label>
                        <input
                          onInput={(e) =>
                            handleUpdateOwnerForm(
                              "firstName",
                              (e.target as HTMLInputElement).value
                            )
                          }
                          value={ownerForm.firstName}
                          type="text"
                          name="firstName"
                          id="firstName"
                          autocomplete="cc-given-name"
                          class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm"
                        />
                      </div>

                      <div class="col-span-4 sm:col-span-2">
                        <label
                          for="lastName"
                          class="block text-sm font-medium text-gray-700"
                        >
                          Last name
                        </label>
                        <input
                          onInput={(e) =>
                            handleUpdateOwnerForm(
                              "lastName",
                              (e.target as HTMLInputElement).value
                            )
                          }
                          value={ownerForm.lastName}
                          type="text"
                          name="lastName"
                          id="lastName"
                          autocomplete="cc-family-name"
                          class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm"
                        />
                      </div>

                      <div class="col-span-4 sm:col-span-4">
                        <label
                          for="email"
                          class="block text-sm font-medium text-gray-700"
                        >
                          Email address
                        </label>
                        <input
                          onInput={(e) =>
                            handleUpdateOwnerForm(
                              "email",
                              (e.target as HTMLInputElement).value
                            )
                          }
                          value={ownerForm.email}
                          type="text"
                          name="email"
                          id="email"
                          autocomplete="email"
                          class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm"
                        />
                      </div>
                    </div>
                  </div>

                  <Errors errors={errors.server} />

                  <div class="px-4 py-3 bg-gray-50 text-right sm:px-6">
                    <Button
                      onClick={handleSubmit}
                      buttonType={isFormValid ? "primary" : "disabled"}
                    >
                      {isSaving ? "Saving..." : "Save"}
                    </Button>
                  </div>
                </div>
              </form>
            </section>
          </div>
        </main>
      </div>
    </div>
  );
};

export default OwnerSettings;
