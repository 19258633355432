import { createContext, FunctionComponent, h } from "preact";
import { useEffect, useState, useMemo } from "preact/hooks";
// eslint-disable-next-line no-irregular-whitespace
import { Socket as PhxSocket } from "phoenix";
import { getAuthToken } from "../../util";
import { BASE_WEB_SOCKET_API_URL } from "../../config";

export const SocketContext = createContext<PhxSocket | null>(null);

interface SocketProps {
  children: JSX.Element;
}

const Socket: FunctionComponent<SocketProps> = ({ children }) => {
  const [socket, setSocket] = useState<PhxSocket | null>(null);
  const authToken = useMemo(() => getAuthToken(), []);

  const setupSocket = () => {
    if (socket) return;
    console.debug("WebSocket routes mounted, connect Socket");

    const newSocket = new PhxSocket(
      `${BASE_WEB_SOCKET_API_URL()}/owner_socket`,
      { params: { token: authToken } }
    );
    newSocket.connect();

    setSocket(newSocket);
  };

  const teardownSocket = () => {
    if (!socket) return;

    console.debug("WebSocket routes unmounted disconnect Socket", socket);

    socket.disconnect();
    setSocket(null);
  };

  useEffect(() => {
    setupSocket();

    return () => teardownSocket();
  }, [socket]);

  return (
    <SocketContext.Provider value={socket}>{children}</SocketContext.Provider>
  );
};

export default Socket;
