import { FunctionalComponent, h } from "preact";
import style from "./style.css";
import Icon from "../Icon/Icon";
import { useEffect, useRef, useState } from "preact/hooks";

export type MessageType = "success" | "info" | "warning" | "danger";
export interface MessageProps {
  message: string;
  type: MessageType;
  messageKey: string;
}

const Message: FunctionalComponent<MessageProps> = ({
  message,
  type,
  messageKey,
}: MessageProps) => {
  const messageEl = useRef<HTMLDivElement>(null);
  const [display, setDisplay] = useState<boolean>(!messageKey);

  useEffect(() => {
    if (!messageKey) return;
    const hidden = localStorage.getItem(messageKey);
    if (hidden !== "hidden") setDisplay(true);
  }, [messageKey]);

  const handleClose = () => {
    localStorage.setItem(messageKey, "hidden");
    messageEl.current.remove();
  };

  return display ? (
    <div class={style.messageWrapper} ref={messageEl}>
      <div class={`${style.message} ${style[type]}`}>
        <div style={{ marginRight: "24px" }}>{message}</div>
        <Icon
          icon="close"
          iconWrapperClass={style.closeIconWrapper}
          onClick={handleClose}
        />
      </div>
    </div>
  ) : null;
};

export default Message;
