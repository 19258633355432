import { FunctionalComponent, h } from "preact";
import { useListExchanges } from "../../../../hooks/exchange";
import Loader from "../../../../components/Loader/Loader";
import style from "./style.css";
import DataTable from "../../../../components/DataTable/DataTable";
import { useMemo } from "preact/hooks";
import { formatDate } from "../../../../util";
import { ExchangeStatus } from "../../../../models/exchange";
import Tag, { TagType } from "../../../../components/Tag/Tag";
import { TableColumn } from "../../../../components/DataTable/types";
import ExchangeStatusFilter from "./components/ExchangeStatusFilter/ExchangeStatusFilter";
import Icon from "../../../../components/Icon/Icon";
import Button from "../../../../components/Button/Button";

const ExchangesMobile: FunctionalComponent = () => {
  const {
    fetchingExchanges,
    exchanges,
    actions: { filterByStatus, sortByDate },
  } = useListExchanges({ date: "desc" });

  const getTagStatus = (status: ExchangeStatus): TagType => {
    switch (status) {
      case "accepted":
        return "green";
      case "declined":
        return "red";
      case "confirmed":
        return "disabled";
      case "pending":
        return "grey";
    }
  };

  const columns: TableColumn[] = [
    {
      title: "Date",
      dataIndex: "date",
      defaultSortOrder: "desc",
      onSort: (order) => sortByDate(order),
    },
    {
      title: "Requested living sanctuary",
      dataIndex: "requestedHome",
      render: (value) => {
        if (value.length === 1) {
          return <div>{value[0].name}</div>;
        }
        return <div>{value.length} living sancutaries</div>;
      },
    },
    {
      title: "Requested date range",
      dataIndex: "requestedDateRange",
    },
    {
      title: "Offered living sanctuary",
      dataIndex: "offeredHome",
      render: (value) => {
        return <div>{value.name}</div>;
      },
    },
    {
      title: "Offered date range",
      dataIndex: "offeredDateRange",
    },
    {
      title: "Duration",
      dataIndex: "duration",
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (value) => {
        return <Tag tagType={getTagStatus(value)}>{value}</Tag>;
      },
      filter: <ExchangeStatusFilter onChange={filterByStatus} />,
    },
    {
      key: "actions",
      render: (value) => {
        return (
          <div class={style.moreIconWrapper}>
            <Icon icon="more" />
          </div>
        );
      },
    },
  ];

  const data = useMemo(() => {
    let uniqueExchanges: any[] = [];

    exchanges.forEach((exchange) => {
      const isUniqueExchange = uniqueExchanges.some(
        (e) => e.groupId === exchange.groupId
      );

      const sanitizedExchange = {
        requestedHome: [exchange.requesteeHome],
        requestedDateRange: `${formatDate(
          exchange.requesteeStartDate,
          "MMM D, YYYY"
        )} - ${formatDate(exchange.requesteeEndDate, "MMM D, YYYY")}`,
        offeredHome: exchange.requesterHome,
        offeredDateRange: `${formatDate(
          exchange.requesterStartDate,
          "MMM D, YYYY"
        )} - ${formatDate(exchange.requesterEndDate, "MMM D, YYYY")}`,
        duration: `${exchange.duration} days`,
        status: exchange.status,
        groupId: exchange.groupId,
      };

      if (isUniqueExchange) {
        uniqueExchanges = uniqueExchanges.map((uniqueExchange) => {
          if (uniqueExchange.groupId === exchange.groupId) {
            return {
              ...uniqueExchange,
              requestedHome: [
                ...uniqueExchange.requesteeHome,
                exchange.requesteeHome,
              ],
              children: [...uniqueExchange.children, sanitizedExchange],
            };
          }
          return uniqueExchange;
        });

        return;
      }

      uniqueExchanges = [
        ...uniqueExchanges,
        {
          ...sanitizedExchange,
          date: formatDate(exchange.createdAt, "MMM D, YY"),
          children: [sanitizedExchange],
        },
      ];
    });

    return uniqueExchanges;
  }, [exchanges]);

  return (
    <div class={style.viewWrapper}>
      <div class={style.viewHeader}>
        <h4>Exchanges</h4>
      </div>
      <div class={style.tableWrapper}>
        {fetchingExchanges ? (
          <Loader />
        ) : (
          <DataTable columns={columns} dataSource={data} />
        )}
      </div>
      <div class={style.createExchangeBtnWrapper}>
        <Button
          buttonType="primary"
          widthStyle="100%"
          iconLeft="plus"
          buttonClass={style.toCreateExchangeRequestButton}
        >
          New Exchange
        </Button>
      </div>
    </div>
  );
};

export default ExchangesMobile;
