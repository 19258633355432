/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { FunctionalComponent, h } from "preact";
import style from "./style.css";
import { useState, useMemo } from "preact/hooks";
import TextArea from "../../../../../../components/TextArea/TextArea";
import { ExchangeRequestForm, OWNER_HOME_KEY } from "../../../types";
import Avatar from "../../../../../../components/Avatar/Avatar";
import ExchangeService, {
  CreateExchangeRequest,
} from "../../../../../../api/exchange";
import { onApiError } from "../../../../../../util/error";
import { formatDate, formatDateRange } from "../../../../../../util";
import { HomeModel } from "../../../../../../models/home";
import toastr, { NotificationDuration } from "../../../../../../libs/toastr";
import { MessageResponse } from "../../../../../../api/provider";
import Drawer from "../../../../../../components/Drawer/Drawer";
import Button from "../../../../../../components/Button/Button";

interface ConfirmRequestDrawerProps {
  form: ExchangeRequestForm;
  display: boolean;
  onClose: (e?: MouseEvent) => void;
  onUpdateMessage: (value: string) => void;
  offeredHome: HomeModel | null;
}

const ConfirmRequestDrawer: FunctionalComponent<ConfirmRequestDrawerProps> = ({
  form,
  display,
  onClose,
  onUpdateMessage,
}: ConfirmRequestDrawerProps) => {
  const [creatingRequest, setCreatingRequest] = useState<boolean>(false);
  const ownerHome: HomeModel | null = useMemo(() => {
    const home = localStorage.getItem(OWNER_HOME_KEY);
    if (!home) return;
    return JSON.parse(localStorage.getItem(OWNER_HOME_KEY)!);
  }, []);

  const getDurationValue = (): string => {
    const { duration, durationPeriod } = form;
    if (!duration || !durationPeriod) return "";
    return `${duration} ${
      duration > 1 ? `${durationPeriod}s` : durationPeriod
    }`;
  };

  const getDurationInDays = () => {
    const { duration, durationPeriod } = form;

    const period = {
      day: 1,
      week: 7,
      month: 30,
    }[durationPeriod!];

    const totalDays = duration! * period;

    return totalDays;
  };

  const onSuccess = (resp: MessageResponse) => {
    // TODO: Navigate to request list view
    toastr().success(resp.data.message, NotificationDuration.NEVER);
    onClose();
  };

  const getRequestees = () => {
    const { requesteeHomes } = form;
    return requesteeHomes.map((home) => home.groupId);
  };

  const createExchangeRequest = () => {
    const { requesterDateRange, requesteeDateRange, requesterHome } = form;
    setCreatingRequest(true);

    const payload: CreateExchangeRequest = {
      requesterHomeId: requesterHome!,
      requesterStartDate: formatDate(requesterDateRange?.start!),
      requesterEndDate: formatDate(requesterDateRange?.end!),
      requesteeStartDate: formatDate(requesteeDateRange?.start!),
      requesteeEndDate: formatDate(requesteeDateRange?.end!),
      duration: getDurationInDays(),
      homeGroupIds: getRequestees(),
    };

    ExchangeService()
      .create(payload)
      .then(onSuccess)
      .catch(onApiError)
      .finally(() => setCreatingRequest(false));
  };

  const getInitials = (firstName: string, lastName: string) => {
    return `${firstName[0]}${lastName[0]}`;
  };

  return (
    <Drawer
      title="Create a request"
      placement="bottom"
      display={display}
      onClose={onClose}
      heightStyle="90%"
      noHeaderBorder
    >
      <div class={style.confirmRequestDrawerWrapper}>
        <div class={style.drawerSection}>
          <div class={style.drawerSectionTitle}>
            Selected living{" "}
            {`${form.requesteeHomes.length > 1 ? "sanctuaries" : "sanctuary"}`}:{" "}
            {`${form.requesteeHomes.length}`}
          </div>
          {form.requesteeHomes.map((home) => {
            return (
              <div key={home.id} class={style.selectedHome}>
                <img class={style.selectedHomeImage} src={home.imageUrls[0]} />
                <div class={style.selectedHomeDetails}>
                  <div class={style.homeName}>{home.name}</div>
                  <div class={style.ownerNameWrapper}>
                    <Avatar
                      src={home.owner.avatarUrl}
                      fallback={getInitials(
                        home.owner.firstName,
                        home.owner.lastName
                      )}
                    />
                    <div class={style.ownerName}>
                      {home.owner.firstName} {home.owner.lastName}
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        <div class={`${style.drawerSection} ${style.noMarginBottom}`}>
          <div class={style.drawerSectionTitle}>Date ranges & duration</div>
          <div class={style.confirmRequestDetailsWrapper}>
            <div class={style.requestDetailWrapper}>
              <div class={style.requestDetailLabel}>Requested date range</div>
              <div class={style.requestDetail}>
                {formatDateRange(form.requesteeDateRange, "ddd, MMM DD, YYYY")}
              </div>
            </div>
            <div class={style.requestDetailWrapper}>
              <div class={style.requestDetailLabel}>Duration</div>
              <div class={style.requestDetail}>{getDurationValue()}</div>
            </div>
            <div class={style.requestDetailWrapper}>
              <div class={style.requestDetailLabel}>Offered date range</div>
              <div class={style.requestDetail}>
                {formatDateRange(form.requesterDateRange, "ddd, MMM DD, YYYY")}
              </div>
            </div>
            <div class={style.requestDetailWrapper}>
              <div class={style.requestDetailLabel}>
                Offered living sanctuary
              </div>
              <div class={style.requestDetail}>{ownerHome?.name}</div>
            </div>
          </div>
          <TextArea
            rows={3}
            label="Message"
            value={form.message}
            onInput={(e: InputEvent) =>
              onUpdateMessage((e.target as HTMLInputElement).value)
            }
          />
        </div>
        <div class={style.sendRequestButtonWrapper}>
          <Button
            loading={creatingRequest}
            onClick={createExchangeRequest}
            widthStyle="100%"
            buttonType="primary"
            iconRight="send"
          >
            Send request
          </Button>
        </div>
      </div>
    </Drawer>
  );
};

export default ConfirmRequestDrawer;
