import { useEffect, useState, useCallback } from "preact/hooks";
import ExchangeService, { ExchangeResponse } from "../../api/exchange";
import { ExchangeModel } from "../../models/exchange";
import toastr from "../../libs/toastr";
import { ApiError, DEFAULT_ERR_MSG } from "../../api/provider";

interface GetExchangeActions {
  fetchExchange: (id: string, load: boolean) => void;
}
interface GetExchangeState {
  fetchingExchange: boolean;
  exchange: ExchangeModel | null;
}

export const useGetExchange = (
  id: string
): [boolean, ExchangeModel | null, GetExchangeActions] => {
  const [exchange, setExchange] = useState<ExchangeModel | null>(null);
  const [fetchingExchange, setFetchingExchange] = useState<boolean>(true);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    fetchExchange(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSuccess = useCallback((resp: ExchangeResponse): void => {
    setExchange(resp.data);
  }, []);

  const onError = useCallback((err: ApiError): void => {
    toastr().danger(err?.message || DEFAULT_ERR_MSG);
  }, []);

  const onFinally = useCallback((): void => {
    setFetchingExchange(false);
  }, []);

  const fetchExchange = useCallback(
    (id: string, load = true): void => {
      if (!fetchingExchange && load) setFetchingExchange(true);

      ExchangeService()
        .get(id)
        .then(onSuccess)
        .catch(onError)
        .finally(onFinally);
    },
    [fetchingExchange, onError, onFinally, onSuccess]
  );

  return [fetchingExchange, exchange, { fetchExchange }];
};
