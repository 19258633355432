import { FunctionComponent, h } from "preact";
import { useRef } from "preact/hooks";
import { useState, useEffect } from "react";
import { isMobileView } from "../../../../util/viewport";
import Icon from "../../../Icon/Icon";
import Drawer from "../../../Drawer/Drawer";
import style from "./style.css";

interface FilterableColumnHeaderProps {
  title: string;
  filter: JSX.Element;
}

const FilterableColumnHeader: FunctionComponent<FilterableColumnHeaderProps> = ({
  title,
  filter,
}) => {
  const [showFilter, setShowFilter] = useState<boolean>(false);
  const filterWrapperEl = useRef<HTMLDivElement>(null);
  const filterOverlayEl = useRef<HTMLDivElement>(null);
  const isMobile = isMobileView();

  useEffect(() => {
    if (!filterOverlayEl.current || !showFilter || isMobile) return;

    filterOverlayEl.current.addEventListener("click", (e) => {
      if (e.target === filterOverlayEl.current) setShowFilter(false);
    });
  }, [isMobile, showFilter]);

  return (
    <th class={style.filterableHeaderCell}>
      {showFilter && <div class={style.overlay} ref={filterOverlayEl} />}
      <div
        class={`${style.titleWrapper} ${
          showFilter && !isMobile ? style.border : ""
        }`}
        onClick={() => setShowFilter(!showFilter)}
      >
        {title}
        <div class={style.whiteBorder} />
        <div class={style.filterableIconsWrapper}>
          <Icon icon="carrot-down" iconWrapperClass={style.carrotFaceUp} />
          <Icon icon="carrot-down" iconWrapperClass={style.carrotFaceDown} />
        </div>
      </div>
      {isMobile ? (
        <Drawer
          title={title}
          placement="bottom"
          display={showFilter}
          onClose={() => setShowFilter(false)}
          noHeaderBorder
        >
          {filter}
        </Drawer>
      ) : (
        <div
          class={`${style.filterWrapper} ${showFilter ? style.display : ""}`}
          ref={filterWrapperEl}
        >
          {filter}
        </div>
      )}
    </th>
  );
};

export default FilterableColumnHeader;
