import { FunctionComponent, h } from "preact";
import { ExchangeStatus } from "../../../../../../models/exchange";
import style from "./style.css";
import Button from "../../../../../../components/Button/Button";
import CheckBox from "../../../../../../components/CheckBox/CheckBox";
import { useState } from "preact/hooks";
import Tag, { TagType } from "../../../../../../components/Tag/Tag";

interface ExchangeStatusFilterProps {
  onChange: (status: ExchangeStatus[]) => void;
}

const ExchangeStatusFilter: FunctionComponent<ExchangeStatusFilterProps> = ({
  onChange,
}) => {
  const [checkedStatues, setCheckedStatuses] = useState<ExchangeStatus[]>([
    "accepted",
    "confirmed",
    "declined",
    "pending",
  ]);

  const handleOnChange = (value: boolean, status: ExchangeStatus) => {
    if (value) {
      const nextCheckedStatuses = [...checkedStatues, status];
      setCheckedStatuses(nextCheckedStatuses);
      return;
    }
    const nextCheckedStatuses = checkedStatues.filter((s) => s !== status);
    setCheckedStatuses(nextCheckedStatuses);
  };

  const handleOnFilter = () => {
    onChange(checkedStatues);
  };

  const getTagStatus = (status: ExchangeStatus): TagType => {
    switch (status) {
      case "accepted":
        return "green";
      case "declined":
        return "red";
      case "confirmed":
        return "disabled";
      case "pending":
        return "grey";
    }
  };

  const isChecked = (value: ExchangeStatus): boolean => {
    return checkedStatues.includes(value);
  };

  return (
    <div class={style.exchangeStatusFilterWrapper}>
      <div class={style.statusesWrapper}>
        <CheckBox
          checked={isChecked("accepted")}
          onChange={(value) => handleOnChange(value, "accepted")}
          label={<Tag tagType={getTagStatus("accepted")}>Accepted</Tag>}
        />
        <div style={{ height: "12px" }} />
        <CheckBox
          checked={isChecked("declined")}
          onChange={(value) => handleOnChange(value, "declined")}
          label={<Tag tagType={getTagStatus("declined")}>Declined</Tag>}
        />
        <div style={{ height: "12px" }} />
        <CheckBox
          checked={isChecked("confirmed")}
          onChange={(value) => handleOnChange(value, "confirmed")}
          label={<Tag tagType={getTagStatus("confirmed")}>Confirmed</Tag>}
        />
        <div style={{ height: "12px" }} />
        <CheckBox
          checked={isChecked("pending")}
          onChange={(value) => handleOnChange(value, "pending")}
          label={<Tag tagType={getTagStatus("pending")}>Pending</Tag>}
        />
      </div>
      <Button
        iconLeft="check-mark"
        buttonClass={style.filterButton}
        onClick={handleOnFilter}
        buttonType="green"
        widthStyle="115px"
      >
        Filter
      </Button>
    </div>
  );
};

export default ExchangeStatusFilter;
