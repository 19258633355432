import { FunctionalComponent, h } from "preact";
import style from "./style.css";

export type TagType = "green" | "red" | "grey" | "white" | "disabled";

interface TagProps {
  children: string | JSX.Element;
  tagType: TagType;
  hasNotification?: boolean;
}

const Tag: FunctionalComponent<TagProps> = ({
  children,
  tagType,
  hasNotification,
}) => {
  const getStatusClass = () => {
    switch (tagType) {
      case "green":
        return style.green;
      case "red":
        return style.red;
      case "disabled":
        return style.disabled;
      case "grey":
        return style.grey;
      case "white":
        return style.white;
    }
  };

  return (
    <div class={`${style.tagWrapper} ${getStatusClass()}`}>
      {hasNotification && (
        <div class={style.notificationWrapper}>
          <div class={style.notification} />
        </div>
      )}
      {children}
    </div>
  );
};

export default Tag;
