import { FunctionComponent, h } from "preact";
import { useEffect, useState, useRef } from "preact/hooks";
import style from "./style.css";

interface TooltipProps {
  children: JSX.Element;
  text: string;
}

const Tooltip: FunctionComponent<TooltipProps> = ({ children, text }) => {
  const [showTooltip, setShowTooltip] = useState<boolean>(false);
  const tooltipEl = useRef<HTMLDivElement>(null);
  const tooltipStyle = useRef<h.JSX.CSSProperties>({});

  useEffect(() => {
    if (!tooltipEl.current) return;

    const bound = tooltipEl.current.getBoundingClientRect();

    tooltipStyle.current = {
      top: `-${bound.height + 12}px`,
      left: `-${bound.width / 2 - 4}px`,
    };
  }, [showTooltip]);

  return (
    <div
      class={style.tooltipWrapper}
      onMouseEnter={() => setShowTooltip(true)}
      onMouseLeave={() => setShowTooltip(false)}
    >
      {children}
      <div
        ref={tooltipEl}
        class={`${style.tooltip} ${showTooltip ? style.display : ""}`}
        style={tooltipStyle.current}
      >
        {text}
      </div>
    </div>
  );
};

export default Tooltip;
