import { DatePickerValue } from "../../../components/DatePicker/types";
import { SelectOption } from "../../../components/Select/Select";
import { HomeModel } from "../../../models/home";
import { ValidationRules } from "../../../util";

export const initialExchangeRequestForm: ExchangeRequestForm = {
  requesteeHomes: [],
  requesterDateRange: null,
  requesteeDateRange: null,
  duration: 1,
  durationPeriod: "week",
  message: "",
  requesterHome: "",
};

export type DurationPeriod = "day" | "week" | "month";
export interface ExchangeRequestForm {
  requesteeHomes: HomeModel[];
  requesterDateRange: DatePickerValue | null;
  requesteeDateRange: DatePickerValue | null;
  duration: number;
  durationPeriod: DurationPeriod;
  message: string;
  requesterHome: string;
}

export const validationRules: ValidationRules = {
  requesterDateRange: { required: true },
  requesteeDateRange: { required: true },
  duration: { required: true },
  requesteeHomes: {
    required: true,
    rules: [{ rule: (homes) => !!homes.length }],
  },
};

export const durationPeriodOptions: SelectOption[] = [
  { value: "day", name: "day" },
  { value: "week", name: "week" },
  { value: "month", name: "month" },
];

export const OWNER_HOME_KEY = "OWNER_HOME";

export const initialOwnerHome = (): HomeModel | undefined => {
  const home = localStorage.getItem(OWNER_HOME_KEY);
  if (!home) return;
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return JSON.parse(localStorage.getItem(OWNER_HOME_KEY)!) as HomeModel;
};
