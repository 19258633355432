import { FunctionComponent, h } from "preact";
import { useEffect } from "preact/hooks";
import { useRef, useState } from "react";
import style from "./style.css";

interface DropdownProps {
  children: JSX.Element;
  overlay: JSX.Element;
}

const Dropdown: FunctionComponent<DropdownProps> = ({ children, overlay }) => {
  const [showMenu, setShowMenu] = useState<boolean>(false);
  const triggerWrapperEl = useRef<HTMLDivElement>(null);
  const menuWrapperEl = useRef<HTMLDivElement>(null);
  const menuWrapperStyle = useRef<h.JSX.CSSProperties>({});

  useEffect(() => {
    if (!triggerWrapperEl.current || !menuWrapperEl.current) return;

    const triggerBound = triggerWrapperEl.current.getBoundingClientRect();
    const menuBound = menuWrapperEl.current.getBoundingClientRect();

    const x = triggerBound.x;
    const y = triggerBound.y;

    menuWrapperStyle.current = {
      top: `${y + triggerBound.height + 18}px`,
      left: `${x - menuBound.width + triggerBound.width + 24}px`,
    };
  }, [showMenu]);

  return (
    <div class={style.dropdownWrapper}>
      <div
        class={style.triggerWrapper}
        onClick={() => setShowMenu(!showMenu)}
        ref={triggerWrapperEl}
      >
        {children}
      </div>
      <div
        class={`
      ${style.menuWrapper} 
      ${showMenu ? style.display : ""}
      `}
        style={menuWrapperStyle.current}
        ref={menuWrapperEl}
      >
        {overlay}
      </div>
      {showMenu && (
        <div class={style.overlayWrapper} onClick={() => setShowMenu(false)} />
      )}
    </div>
  );
};

export default Dropdown;
