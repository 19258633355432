export function convertFiltersToQueryStr<T extends { [key: string]: any }>(
  obj?: T
): string {
  if (!obj) return "";

  const isObjectKeyValueDefined = (key: string) =>
    (!!obj[key] || obj[key] === 0) && obj[key] !== "";
  const objectKeyToObjectKeyValue = (key: string) => key + "=" + obj[key];

  // generate the query params
  let queryParam = Object.keys(obj)
    .filter(isObjectKeyValueDefined)
    .map(objectKeyToObjectKeyValue)
    .join("&");
  // adds a `&` if queryParam is not empty
  queryParam = queryParam && queryParam !== "" ? `${queryParam}&` : queryParam;
  // removes a trailing `&` if its left over
  queryParam =
    queryParam[queryParam.length - 1] === "&"
      ? queryParam.substring(0, queryParam.length - 1)
      : queryParam;

  return "?" + queryParam;
}
