import { FunctionalComponent, h } from "preact";
import style from "./style.css";
interface ErrorsProps {
  errors?: string[];
}

const Errors: FunctionalComponent<ErrorsProps> = ({ errors }: ErrorsProps) => {
  return errors?.length! > 0 ? (
    <div class={style.errorsWrapper}>
      <div class="flex">
        <ul class={style.errorsList}>
          {errors?.map((err) => (
            <li class={style.error}>{err}</li>
          ))}
        </ul>
      </div>
    </div>
  ) : null;
};

export default Errors;
